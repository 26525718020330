import { useEffect, useState } from "react"
import { AuthState } from "../../../context/auth/authProvider"
import {
  useGetMembersByOrganisationLazyQuery,
  useGetAdminsListLazyQuery,
  useGetWbcListLazyQuery,
  useRevokeRoleMutation,
  useGetAllUsersQuery,
  useRemoveMemberMutation,
  useRemoveUserMutation,
  useGetUnapprovedMembersOfOrganisationLazyQuery,
  useApproveMemberMutation,
  useDenyApprovalToMemberMutation,
  useGenerateOrgAdminPromoteCodeMutation,
  useGenerateWbcPromoteCodeMutation,
  useGetOrgAdminPromoteCodesInOrganisationLazyQuery,
  useGetWbcPromoteCodesInOrganisationLazyQuery
} from "../../../graphql/graphql"
import "./styles.scss"
import { useModal } from "../../../components/Modals/Modal"
import { UnlinkUnassignModal } from "../../../components/Modals/UnlinkModal/UnlinkModal"
import { Loader, setFormattedData, CustomTable } from "../../../components"
import { ROLES } from "../../../utils/roles"
import { ConfirmationCodeModal } from "../../../components/Modals/ConfirmationCodeModal/ConfirmationCodeModal"

function ManagePeople() {
  const { selectedOrganisation, user } = AuthState()
  const [actionItem, setActionItem] = useState<any>(null)

  // Formatted Data States
  const [formattedDataMap, setFormattedDataMap] = useState<any>({})
  const [formattedDataMapUnapproved, setFormattedDataMapUnapproved] =
    useState<any>({})
  const [formattedDataMapAdmins, setFormattedDataMapAdmins] = useState<any>({})
  const [formattedDataMapWbc, setFormattedDataMapWbc] = useState<any>({})

  // Queries
  const [fetch, { data, loading }] = useGetMembersByOrganisationLazyQuery()
  const [fetchAdmins, { data: adminsData, loading: adminsLoading }] =
    useGetAdminsListLazyQuery()
  const [fetchWbcs, { data: wbcData, loading: wbcLoading }] =
    useGetWbcListLazyQuery()
  const [
    fetchUnapprovedPeople,
    { data: unapprovedData, loading: unapprovedLoading }
  ] = useGetUnapprovedMembersOfOrganisationLazyQuery()
  const {
    data: usersData,
    loading: usersLoading,
    refetch
  } = useGetAllUsersQuery({ skip: user?.role !== ROLES.ADMIN })

  const [fetchWbcCodes, { data: wbcCodesData, loading: wbcCodesDataLoading }] =
    useGetWbcPromoteCodesInOrganisationLazyQuery()

  const [
    fetchAdminCodes,
    { data: adminCodesData, loading: adminCodesDataLoading }
  ] = useGetOrgAdminPromoteCodesInOrganisationLazyQuery()

  // Mutations
  const [removeAdmin, { data: removeAdminData }] = useRevokeRoleMutation()
  const [removeMember, { data: removeMemberData }] = useRemoveMemberMutation()
  const [removeUser, { data: remomveUserData }] = useRemoveUserMutation()
  const [approveMember, { data: approveMemberData }] =
    useApproveMemberMutation()
  const [denyMember, { data: denyMemberData }] =
    useDenyApprovalToMemberMutation()
  const [
    generateWbcCode,
    { data: newWbcCodeData, loading: newWbcCodeLoading }
  ] = useGenerateWbcPromoteCodeMutation()
  const [
    generateAdminCode,
    { data: newAdminCodeData, loading: newAdminCodeLoading }
  ] = useGenerateOrgAdminPromoteCodeMutation()

  const [modalState, setModalState] = useState({
    unassignModal: false,
    unlinkModal: false,
    deleteModal: false,
    confirmModal: false
  })

  const { Modal, open, close } = useModal()

  useEffect(() => {
    if (selectedOrganisation && user?.role === ROLES.ORG_ADMIN) {
      fetchUnapprovedPeople({
        variables: {
          organisationId: selectedOrganisation.id
        }
      })
      fetch({
        variables: {
          getMembersByOrganisationId: selectedOrganisation.id
        }
      })
      fetchAdmins({
        variables: {
          getAdminsListId: selectedOrganisation.id
        }
      })
      fetchWbcs({
        variables: {
          getWbcListId: selectedOrganisation.id
        }
      })
      fetchWbcCodes({
        variables: {
          getWbcPromoteCodesInOrganisationId: selectedOrganisation.id
        }
      })
      fetchAdminCodes({
        variables: {
          getOrgAdminPromoteCodesInOrganisationId: selectedOrganisation.id
        }
      })
    }
  }, [selectedOrganisation, user])

  useEffect(() => {
    setFormattedData(
      unapprovedData?.getUnapprovedMembersOfOrganisation,
      setFormattedDataMapUnapproved,
      "member"
    )
    setActionItem(null)
  }, [unapprovedData?.getUnapprovedMembersOfOrganisation])

  useEffect(() => {
    if (
      denyMemberData?.denyApprovalToMember ||
      approveMemberData?.approveMember
    ) {
      fetch({
        variables: {
          getMembersByOrganisationId: selectedOrganisation.id
        }
      })
      fetchUnapprovedPeople({
        variables: {
          organisationId: selectedOrganisation.id
        }
      })
    }
  }, [denyMemberData?.denyApprovalToMember, approveMemberData?.approveMember])

  useEffect(() => {
    if (
      data?.getMembersByOrganisation.length ||
      (data?.getMembersByOrganisation && actionItem)
    ) {
      setFormattedData(
        data?.getMembersByOrganisation,
        setFormattedDataMap,
        "member"
      )
      setActionItem(null)
    }
  }, [data?.getMembersByOrganisation])

  useEffect(() => {
    if (
      adminCodesData?.getOrgAdminPromoteCodesInOrganisation.length ||
      adminsData?.getAdminsList.length ||
      (adminsData?.getAdminsList && actionItem)
    ) {
      setFormattedData(
        adminsData?.getAdminsList,
        setFormattedDataMapAdmins,
        "member",
        adminCodesData?.getOrgAdminPromoteCodesInOrganisation
      )
      setActionItem(null)
    }
  }, [
    adminsData?.getAdminsList,
    adminCodesData?.getOrgAdminPromoteCodesInOrganisation
  ])

  useEffect(() => {
    if (
      wbcCodesData?.getWbcPromoteCodesInOrganisation.length ||
      wbcData?.getWBCList.length ||
      (wbcData?.getWBCList && actionItem)
    ) {
      setFormattedData(
        wbcData?.getWBCList,
        setFormattedDataMapWbc,
        "member",
        wbcCodesData?.getWbcPromoteCodesInOrganisation
      )
      setActionItem(null)
    }
  }, [wbcData?.getWBCList, wbcCodesData?.getWbcPromoteCodesInOrganisation])

  useEffect(() => {
    if (usersData?.getUsers.length || (usersData?.getUsers && actionItem)) {
      setFormattedData(usersData?.getUsers, setFormattedDataMap, "user")
      setActionItem(null)
    }
  }, [usersData?.getUsers])

  useEffect(() => {
    if (removeAdminData?.revokeRoles) {
      fetchAdmins({
        variables: {
          getAdminsListId: selectedOrganisation.id
        }
      })
      fetchWbcs({
        variables: {
          getWbcListId: selectedOrganisation.id
        }
      })
    }
  }, [removeAdminData?.revokeRoles])

  useEffect(() => {
    if (removeMemberData?.deleteMember) {
      fetch({
        variables: {
          getMembersByOrganisationId: selectedOrganisation.id
        }
      })
      fetchAdmins({
        variables: {
          getAdminsListId: selectedOrganisation.id
        }
      })
      fetchWbcs({
        variables: {
          getWbcListId: selectedOrganisation.id
        }
      })
    }
  }, [removeMemberData?.deleteMember])

  useEffect(() => {
    if (newWbcCodeData?.generateWbcPromoteCode) {
      const codeObj = {
        id: newWbcCodeData.generateWbcPromoteCode.id,
        name: newWbcCodeData.generateWbcPromoteCode.code,
        isCode: true
      }

      setFormattedDataMapWbc((data: any) => {
        if (data.length) return [...data, codeObj]
        else return [codeObj]
      })
      openConfirmModal();
    }
  }, [newWbcCodeData?.generateWbcPromoteCode])

  useEffect(() => {
    if (newAdminCodeData?.generateOrgAdminPromoteCode) {
      const codeObj = {
        id: newAdminCodeData.generateOrgAdminPromoteCode.id,
        name: newAdminCodeData.generateOrgAdminPromoteCode.code,
        isCode: true
      }
      setFormattedDataMapAdmins((data: any) => {
        if (data.length) return [...data, codeObj]
        else return [codeObj]
      })
      openConfirmModal();
    }
  }, [newAdminCodeData?.generateOrgAdminPromoteCode])

  useEffect(() => {
    if (remomveUserData?.deleteUser) {
      refetch()
    }
  }, [remomveUserData?.deleteUser])

  const openUnlinkModal = (clickedItem: any) => {
    setModalState({
      unassignModal: false,
      unlinkModal: true,
      deleteModal: false,
      confirmModal: false
    })
    setActionItem(clickedItem)
  }

  const openUnassignModal = (clickedItem: any) => {
    setModalState({
      unassignModal: true,
      unlinkModal: false,
      deleteModal: false,
      confirmModal: false
    })
    setActionItem(clickedItem)
  }

  const openDeleteModal = (clickedItem: any) => {
    setModalState({
      unassignModal: false,
      unlinkModal: false,
      deleteModal: true,
      confirmModal: false
    })
    setActionItem(clickedItem)
  }

  const openConfirmModal = () => {
    setModalState({
      unassignModal: false,
      unlinkModal: false,
      deleteModal: false,
      confirmModal: true
    })
  }

  useEffect(() => {
    if (
      modalState?.deleteModal ||
      modalState?.unassignModal ||
      modalState?.unlinkModal ||
      modalState?.confirmModal
    ) {
      open()
    }
  }, [modalState])

  const handleUnassign = () => {
    removeAdmin({
      variables: {
        memberId: actionItem?.id
      }
    })
    close()
  }

  const handleUnlink = () => {
    removeMember({
      variables: {
        memberId: actionItem?.id
      }
    })
    close()
  }

  const handleDeleteUser = () => {
    removeUser({
      variables: {
        userId: actionItem?.id
      }
    })
    close()
  }

  const handleApproveMember = (user: any) => {
    approveMember({
      variables: {
        approveMemberId: user?.id
      }
    })
  }

  const handleDenyMember = (user: any) => {
    denyMember({
      variables: {
        denyApprovalToMemberId: user?.id
      }
    })
  }

  const handleGenerateWbcCode = () => {
    generateWbcCode({
      variables: {
        organisationToGenerateForId: selectedOrganisation.id
      }
    })
  }

  const handleGenerateAdminCode = () => {
    generateAdminCode({
      variables: {
        organisationToGenerateForId: selectedOrganisation.id
      }
    })
  }

  return (
    <div className="manage-people-main">
      {loading && !user && <Loader />}
      {user.role === ROLES.ORG_ADMIN && (
        <>
          <CustomTable
            dataIsLoading={unapprovedLoading}
            data={formattedDataMapUnapproved}
            tableName="Approve People"
            hasActions
            validAction={handleApproveMember}
            negativeAction={handleDenyMember}
          />
          <CustomTable
            dataIsLoading={
              newWbcCodeLoading || wbcCodesDataLoading || wbcLoading
            }
            data={formattedDataMapWbc}
            tableName="Wellbeing Champions"
            hasUnassign
            hasUnlink
            hasButton
            buttonText="Generate WBC Code"
            onClick={handleGenerateWbcCode}
            unlinkAction={openUnlinkModal}
            unassignAction={openUnassignModal}
          />
          <CustomTable
            dataIsLoading={
              newAdminCodeLoading || adminCodesDataLoading || adminsLoading
            }
            data={formattedDataMapAdmins}
            tableName="Admins"
            hasUnassign
            hasUnlink
            hasButton
            buttonText="Generate Admin Code"
            onClick={handleGenerateAdminCode}
            unlinkAction={openUnlinkModal}
            unassignAction={openUnassignModal}
          />
          <CustomTable
            dataIsLoading={loading}
            data={formattedDataMap}
            tableName="Staff"
            hasUnlink
            unlinkAction={openUnlinkModal}
          />
        </>
      )}
      {user.role === ROLES.ADMIN && (
        <CustomTable
          dataIsLoading={usersLoading}
          data={formattedDataMap}
          tableName="Staff"
          hasDelete
          deleteAction={openDeleteModal}
        ></CustomTable>
      )}
      <Modal>
        <div>
          {modalState?.unassignModal && (
            <UnlinkUnassignModal closeModal={close} callback={handleUnassign} />
          )}
          {modalState?.unlinkModal && (
            <UnlinkUnassignModal closeModal={close} callback={handleUnlink} />
          )}
          {modalState?.deleteModal && (
            <UnlinkUnassignModal
              closeModal={close}
              callback={handleDeleteUser}
            />
          )}
          {modalState?.confirmModal && (
            <ConfirmationCodeModal
              code={newWbcCodeData?.generateWbcPromoteCode.code || newAdminCodeData?.generateOrgAdminPromoteCode.code || ""}
              closeModal={close}/>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default ManagePeople
