import { useState, useEffect, useRef } from "react"
import { useHeader } from "./hooks"
import { useNavLinks } from "../NavLinks/hooks"
import UserNav from "./UserNav/UserNav"
import "./styles.scss"
import { useLocation } from "react-router-dom"

function Header() {
  const { user } = useHeader()
  const location = useLocation()
  const { links } = useNavLinks()
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [pageTitle, setPageTitle] = useState<string>("")
  const dropdownRef = useRef(null)

  useEffect(() => {
    for (const link of links) {
      if (link?.path === location.pathname) setPageTitle(link.text)
    }
  }, [location, links])

  return (
    <div className="header-wrapper">
      <div className="page-title">{pageTitle}</div>
      <nav className="user-nav">
        <div className="user-picture">
          {
            // Make sure property name matches whats coming from the DB
            user?.profilePicURL ? (
              <img src={user.profilePicURL} alt="Profile Pic" />
            ) : (
              `${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`
            )
          }
        </div>
        <div className="user-info">
          <div className="user-name">
            {user?.firstName} {user?.lastName}
          </div>
          <div className="user-role">{user.role}</div>
        </div>
        <div
          className={`menu-icon ${showMenu ? "active" : ""}`}
          ref={dropdownRef}
          onClick={() => setShowMenu(!showMenu)}
        >
          <img src="./assets/chevronIcon.svg" alt="" />
        </div>
        <UserNav
          parentRef={dropdownRef}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
      </nav>
    </div>
  )
}

export default Header
