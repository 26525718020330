import { useNavLinks } from "./hooks"
import { NavLink } from "react-router-dom"
import "./styles.scss"

const NavLinks = () => {
  const { links } = useNavLinks()

  return (
    <div className="nav-links">
      {links.map((link: any) => {
        const { path, id, icon, text } = link
        return (
          <NavLink
            key={id}
            to={path}
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            <span className="icon">{icon}</span>
            {text}
          </NavLink>
        )
      })}
    </div>
  )
}

export default NavLinks
