import { useEffect, useState } from "react"
import {
  useGetAllOrganisationsAndLocationsQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  LocationType,
  useDeleteLocationMutation
} from "../../../graphql/graphql"
import { SimpleInputsType } from "./types"

export const useLocationForm = (
  refetch: () => void,
  toast: (message: string) => void,
  isUpdateForm?: boolean,
  updateLocationId?: string,
  closeTab?: () => void
) => {
  //states
  const [simpleInputs, setSimpleInputs] = useState<SimpleInputsType>({
    name: "",
    displayName: "",
    addressLine1: "",
    addressLine2: "",
    lat: "",
    long: "",
    cqc: "",
    postCode: "",
    uniqueIdentifier: "",
    country: "",
    city: "",
    county: ""
  })
  const [selectedInputs, setSelectedInputs] = useState<{locationType: LocationType | null}>({
    locationType: null
  })
  const [organisationOptions, setOrganisationOptions] = useState<any>([])
  const [selectedOrganisations, setSelectedOrganisations] = useState<any>([])
  const [addressByPostcode, setAddressByPostcode] = useState<any>(null)

   //requests
   const [createOrganisation, { data, error: createError }] = useCreateLocationMutation()
   const [updateLocation, { data: updateData, error: updateError }] = useUpdateLocationMutation()
   const { data: organisationsData } = useGetAllOrganisationsAndLocationsQuery()
   const [deleteLocation, { data: deleteLocationData, error: deleteError}] = useDeleteLocationMutation();

  //options for select-fields
  const locationType = {
    name: "locationType",
    options: [
      { value: "GP_Surgery", label: "GP Surgery" },
      { value: "Office", label: "Office" },
      { value: "Community_Hospital_ward", label: "Community Hospital ward" },
      { value: "MIIU", label: "MIIU" },
      { value: "Shared_Clinical_Hub", label: "Shared Clinical Hub" },
      { value: "GP_Branch_Surgery ", label: "GP Branch Surgery" },
      { value: "Community_Pharmacy", label: "Community Pharmacy" },
      { value: "Residential_Care_Home", label: "Residential Care Home" },
      { value: "Nursing_Home", label: "Nursing Home" } 
    ]
  }

  //multi-select handlers
  const handleSelect = (selectedList: any) => {
    setSelectedOrganisations(selectedList)
  }
  const handleRemove = (selectedList: any) => {
    setSelectedOrganisations(selectedList)
  }

  //simple-inputs handlers
  const handleChange = (e: { target: { name: any; value: string } }) => {
    if (e.target.name === "postcode") {
      setSimpleInputs({
        ...simpleInputs,
        [e.target.name]: e.target.value?.replaceAll(" ", "")
      })
      return
    }

    setSimpleInputs({ ...simpleInputs, [e.target.name]: e.target.value })
  }

  //select-fields handlers
  const addSelectedOption = (name: string, option: any) => {
    setSelectedInputs({
      ...selectedInputs,
      [name]: option?.value
    })
  }

  const handleDelete = () => {
    if (updateLocationId) {
      deleteLocation({
        variables: {
          locationId: updateLocationId
        }
      })
    }
  }

  //get organisations to populate the organisations options state
  useEffect(() => {
    const mappedOrganisationOptions = organisationsData?.getOrganisations.map(
      (location) => {
        return { name: `${location.displayName}`, id: location.id }
      }
    )
    setOrganisationOptions(mappedOrganisationOptions)
  }, [organisationsData])

  // If it's an update form - populate the inputs state
  useEffect(() => {
    if (!updateLocationId || !isUpdateForm) return

    const locationToUpdateData =
      organisationsData?.getLocations &&
      organisationsData?.getLocations.find(
        (location) => location.id === updateLocationId
      )

    if (locationToUpdateData) {
      setSimpleInputs({
        name: locationToUpdateData.name,
        displayName: locationToUpdateData.displayName,
        cqc: locationToUpdateData?.cqcLocationId || "",
        uniqueIdentifier: locationToUpdateData.ethoUniqueId,
        addressLine1: locationToUpdateData.address?.addressLine?.split(";")[0] || "",
        addressLine2: locationToUpdateData.address?.addressLine?.split(";")[1] || "",
        postCode: locationToUpdateData.address?.postcode,
        lat: locationToUpdateData.address?.lat,
        long: locationToUpdateData.address?.long,
        country: locationToUpdateData.address?.country,
        city: locationToUpdateData.address?.city,
        county: locationToUpdateData.address?.county
      })

      setSelectedInputs({
        locationType: locationToUpdateData.locationType
      })

      setSelectedOrganisations(
        locationToUpdateData.organisations?.map((org: any) => ({
          id: org.id,
          name: org.displayName
        }))
      )
    }
  }, [isUpdateForm, updateLocationId, organisationsData?.getLocations])

  //after form is sent, reset state values
  useEffect(() => {
    if (data?.createLocation || updateData?.updateLocation) {
      setSimpleInputs({
        name: "",
        displayName: "",
        addressLine1: "",
        addressLine2: "",
        postCode: "",
        lat: "",
        long: "",
        cqc: "",
        uniqueIdentifier: "",
        country: "",
        city: "",
        county: ""
      })

      setSelectedInputs({
        locationType: null
      })
      refetch()
      toast(`${data?.createLocation ? "Create" : "Update"} successful!`)
    }

    if (updateData?.updateLocation && closeTab) {
      closeTab();
    }
  }, [data?.createLocation, updateData?.updateLocation])

  useEffect(() => {
    if (deleteLocationData?.deleteLocation && closeTab) {
      closeTab()
    }
  }, [deleteLocationData?.deleteLocation, closeTab])

  useEffect(() => {
    const errorMessage = createError?.message || updateError?.message || deleteError?.message
    if (errorMessage && errorMessage?.includes("400")) {
      toast("Please make sure to fill all required fields.")
    }
    if (errorMessage && errorMessage?.includes("401")) { 
      toast("Make sure you are logged in.")
    }
  }, [createError?.message, updateError?.message, deleteError?.message])

  useEffect(() => {
    const getAddressByPostcode = async () => {
      if (simpleInputs?.postCode?.length > 6) {
        try {
          const apiResponse = await fetch(
            `https://api.postcodes.io/postcodes/${simpleInputs.postCode}`
          )
          const addressResponse = await apiResponse.json()

          if (addressResponse.status === 200) {
            setAddressByPostcode(addressResponse)
          }
        } catch (error: any) {
          console.log("Get Addres by Postcode API error", error)
        }
      }
    }
    getAddressByPostcode()
  }, [simpleInputs?.postCode])

  useEffect(() => {
    setSimpleInputs({
      ...simpleInputs,
      country: addressByPostcode?.result?.country,
      county: addressByPostcode?.result?.admin_county,
      city: addressByPostcode?.result?.admin_district,
      lat: addressByPostcode?.result?.latitude,
      long: addressByPostcode?.result?.longitude
    })
  }, [addressByPostcode])

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (isUpdateForm && updateLocationId) {
      updateLocation({
        variables: {
          updateLocationId,
          name: simpleInputs.name,
          displayName: simpleInputs.displayName,
          addressLine: `${simpleInputs.addressLine1};${simpleInputs.addressLine2}`,
          cqcLocationId: simpleInputs.cqc,
          postcode: simpleInputs.postCode,
          lat: parseFloat(simpleInputs.lat),
          long: parseFloat(simpleInputs.long),
          city: simpleInputs.city,
          country: simpleInputs.country,
          county: simpleInputs.county,
          organisationIds: selectedOrganisations.map(
            (organisation: { id: string }) => organisation.id
          )
        }
      })
    } else {
      createOrganisation({
        variables: {
          name: simpleInputs.name,
          cqcLocationId: simpleInputs.cqc,
          displayName: simpleInputs.displayName,
          addressLine: `${simpleInputs.addressLine1};${simpleInputs.addressLine2}`,
          postcode: simpleInputs.postCode,
          lat: parseFloat(simpleInputs.lat),
          long: parseFloat(simpleInputs.long),
          locationType: selectedInputs.locationType as LocationType,
          city: simpleInputs.city,
          country: simpleInputs.country,
          county: simpleInputs.county,
          organisationIds: selectedOrganisations.map(
            (organisation: { id: string }) => organisation.id
          )
        }
      })
    }
  }
  return {
    simpleInputs,
    handleChange,
    addSelectedOption,
    locationType,
    handleSelect,
    handleRemove,
    handleDelete,
    organisationOptions,
    selectedOrganisations,
    submitForm,
    selectedInputs
  }
}
