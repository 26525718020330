import { useCallback, useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import Logo from "../../../assets/Logo"
import { useCheckInGuestMutation, useCheckOutGuestMutation, useGetLocationByUniqueIdLazyQuery } from "../../../graphql/graphql"
import { computeTimeDiff } from "../../../utils/formatters"
import GradientButton from "../../Buttons/GradientButton/GradientButton"
import Checkbox from "../../Checkbox/Checkbox"
import Field from "../../Field/Field"
import Loader from "../../Loader/Loader"
import "./styles.scss"

export const GuestSessionForm = () => {
  const intervalRef = useRef<any>();
  const [firstName, setFirstName] = useState<string | undefined>()
  const [lastName, setLastName] = useState<string | undefined>()
  const [company, setCompany] = useState<string | undefined>()
  const [ongoingSession, setOngoingSession] = useState<string | null>(null)
  const [elapsedTime, setElapsedTime] = useState<string>("")
  const [tAndC, setTAndC] = useState<boolean>(false)

  const [getLocationByUniqueCode, { data: locationData, loading: locationLoading }] = useGetLocationByUniqueIdLazyQuery();
  const [performCheckIn, { data: checkInData, loading: checkInLoading }] = useCheckInGuestMutation()
  const [performCheckOut, { data: checkOutData, loading: checkOutLoading }] = useCheckOutGuestMutation()
  const { uniqueId } = useParams()

  const onSubmit = useCallback((event: any) => {
    event.preventDefault()

    if (!tAndC) {
      return
    }

    const organisationUniqueId = uniqueId?.split("-")[0]
    const locationUniqueId = uniqueId?.split("-")[1]

    performCheckIn({
      variables: {
        organisationUniqueId: organisationUniqueId,
        locationUniqueId: locationUniqueId,
        firstName: firstName,
        lastName: lastName,
        company: company
      }
    })
  }, [firstName, lastName, tAndC])

  const resetElapsedTime = () => {
    setElapsedTime("")
  }

  const onCheckOut = useCallback((event: Event) => {
    event.preventDefault()

    performCheckOut({
      variables: {
        attendanceId: localStorage.getItem("guestSessionId") || ""
      }
    })
    removeInterval()
    resetElapsedTime()
  }, [])

  const handleElaplsedTime = useCallback((startTime: string) => {
    intervalRef.current = setInterval(() => {
      const time = computeTimeDiff(startTime)
      setElapsedTime(time)
    }, 1000)
  }, [intervalRef])

  const removeInterval = useCallback(() => {
    clearInterval(intervalRef.current)
  }, [])

  useEffect(() => {
    const ongoingSessionId = localStorage.getItem("guestSessionId")
    const ongoingSessionTimestamp = localStorage.getItem("guestSessionTimestamp") || ""
    const sessionIsValid = new Date().getDate() === new Date(ongoingSessionTimestamp).getDate()

    if (ongoingSessionId && ongoingSessionTimestamp && sessionIsValid) {
      setOngoingSession(ongoingSessionTimestamp)
      return
    }

    if (!sessionIsValid) {
      localStorage.removeItem("guestSessionId")
      localStorage.removeItem("guestSessionTimestamp")
    }

    setOngoingSession("")
    return () => {
      removeInterval()
    }
  }, [])

  useEffect(() => {
    if (ongoingSession && !elapsedTime) {
      handleElaplsedTime(ongoingSession)
    }
  }, [ongoingSession])

  useEffect(() => {
    if (uniqueId) {
      getLocationByUniqueCode({
        variables: {
          ethoUniqueId: uniqueId.split("-")[1]
        }
      })
    }
  }, [uniqueId])

  useEffect(() => {
    if (checkInData?.checkMyselfInGuest && !checkInLoading && !ongoingSession) {
      localStorage.setItem("guestSessionId", checkInData?.checkMyselfInGuest.id)
      localStorage.setItem("guestSessionTimestamp", checkInData?.checkMyselfInGuest.startTimestamp)
      setOngoingSession(checkInData?.checkMyselfInGuest.startTimestamp)
    }
  }, [checkInData])

  useEffect(() => {
    if (checkOutData?.checkMyselfOutGuest) {
      localStorage.removeItem("guestSessionId")
      localStorage.removeItem("guestSessionTimestamp")
      setOngoingSession("")
      removeInterval()
    }
  }, [checkOutData])

  return (!uniqueId || locationLoading || checkInLoading || checkOutLoading || ongoingSession === null)
    ? <Loader />
    : <div className="guest-login-form-wrapper">
      <div style={{ marginBottom: "10px" }}>
        <Logo width="166" height="64" />
      </div>

      {
        ongoingSession && <div className="checked-in">
          <div className="text">{`Checked in at ${locationData?.getLocationByUniqueId.displayName}`}</div>

          <div className="elapsed-time">{
            elapsedTime
          }</div>

          <GradientButton type="submit" onClick={onCheckOut}>
            Check out
          </GradientButton>
        </div>
      }
      {
        !ongoingSession && <>
          <div className="text">{`Checking in at ${locationData?.getLocationByUniqueId.displayName}`}</div>
          <form name="contact" className="contact-form" onSubmit={onSubmit}>
            <div className="fields">
              <Field
                handleChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                name="firstName"
                type="text"
                label="First Name"
              />

              <Field
                handleChange={(e) => setLastName(e.target.value)}
                value={lastName}
                name="lastName"
                type="text"
                label="Last Name"
              />

              <Field
                handleChange={(e) => setCompany(e.target.value)}
                value={company}
                name="company"
                type="text"
                label="Company"
              />
            </div>

            <div className="privacy-check">
              <Checkbox
                value={tAndC}
                onClick={() => setTAndC((prevValue) => {
                  return !prevValue
                })}
                label={<span>By continuing with checking in, you agree with our <a href="https://etho-image-bucket.s3.eu-west-2.amazonaws.com/Etho+EULA+August+2022.pdf" target="#">Terms & Conditions</a></span>} />
            </div>

            <GradientButton type="submit">
              Check in
            </GradientButton>
          </form>
        </>
      }
    </div>
}