const ReportsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 11.25C4.5 11.1119 4.61193 11 4.75 11H6V12.5H4.5V11.25ZM4.5 14H6V15.5H4.75C4.61193 15.5 4.5 15.3881 4.5 15.25V14ZM11.5 15.25V14H7.5V15.5H11.25C11.3881 15.5 11.5 15.3881 11.5 15.25ZM7.5 11V12.5H11.5V11.25C11.5 11.1119 11.3881 11 11.25 11H7.5ZM8 6V0H2C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H14C15.1046 20 16 19.1046 16 18V8H10C8.89543 8 8 7.10457 8 6ZM4.75 9.5H11.25C12.2165 9.5 13 10.2835 13 11.25V15.25C13 16.2165 12.2165 17 11.25 17H4.75C3.7835 17 3 16.2165 3 15.25V11.25C3 10.2835 3.7835 9.5 4.75 9.5ZM9.5 6V0.5L15.5 6.5H10C9.72386 6.5 9.5 6.27614 9.5 6Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ReportsIcon
