import { createContext, useContext, useEffect, useState } from "react"
import type { FC } from "react"
import type { AuthContext, AuthProps } from "./types"
import { useLocation } from "react-router-dom"
import { isTokenExpired } from "../../utils/jwtUtils"
import { useGetUserLazyQuery } from "../../graphql/graphql"
import { getUserIdFromToken } from "../../utils/jwtUtils"
import { ROLES } from "../../utils/roles"
import { browserClient } from "../../services/apollo-client"

const authContext = createContext<AuthContext>({
  user: null,
  setUser: () => null,
  selectedOrganisation: null,
  setSelectedOrganisation: () => null,
  handleAuthenticatedUser: () => null,
  logout: () => null,
  isInitialLoading: true
})

const isUserAuthenticated = () => {
  const accessToken =
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || "") || ""

  // console.log(accessToken)

  const refreshToken =
    localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN || "") || ""
  return (
    (accessToken?.length && !isTokenExpired(accessToken)) ||
    (refreshToken?.length && !isTokenExpired(refreshToken))
  )
}

const AuthProvider: FC<AuthProps> = ({ children }) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const [selectedOrganisation, setSelectedOrganisation] = useState({})
  const [user, setUser] = useState(null)

  const location = useLocation()

  const [getUser, { data: userData, refetch, called }] = useGetUserLazyQuery()

  const handleAuthenticatedUser = () => {
    const accessToken =
      localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN || "") || ""
    const authStatus = isUserAuthenticated()

    if (!accessToken) {
      setIsInitialLoading(false)
      return
    }
    const decodedTokenUserId = getUserIdFromToken(accessToken)

    if (authStatus && decodedTokenUserId) {
      !called &&
        getUser({
          variables: {
            getUserId: decodedTokenUserId
          },
          nextFetchPolicy: "cache-and-network",
          fetchPolicy: "cache-and-network"
        })
      called && refetch({ getUserId: decodedTokenUserId })
    } else {
      setIsInitialLoading(false)
    }
  }

  const logout = () => {
    localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN || "")
    localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN || "")
    browserClient.cache.reset().then(() => browserClient.resetStore());
    setUser(null)
  }

  //check for access token and use it to get the user
  useEffect(() => {
    handleAuthenticatedUser()
  }, [])

  //getUser response, populate user object in context when app is instantiated
  useEffect(() => {
    if (userData?.getUser) {
      setUser({
        ...userData?.getUser,
        role:
          userData?.getUser?.userAdministeredOrganisations?.length &&
            !userData?.getUser?.isEthoOwner
            ? ROLES.ORG_ADMIN
            : ROLES.ADMIN
      } as any)
      if (
        userData?.getUser?.userAdministeredOrganisations?.length &&
        !userData?.getUser?.isEthoOwner
      ) {
        setSelectedOrganisation(
          userData?.getUser?.userAdministeredOrganisations[0] || ""
        )
      }
    }
  }, [userData?.getUser.id])

  useEffect(() => {
    if (user && isInitialLoading) {
      setIsInitialLoading(false)
    }
  }, [user])

  // check local auth state on page change
  useEffect(() => {
    if (!isUserAuthenticated()) {
      setUser(null)
    }
  }, [location])

  return (
    <authContext.Provider
      value={{
        user,
        setUser,
        selectedOrganisation,
        setSelectedOrganisation,
        handleAuthenticatedUser,
        logout,
        isInitialLoading
      }}
    >
      {children}
    </authContext.Provider>
  )
}

export const AuthState = () => {
  return useContext(authContext)
}

export default AuthProvider
