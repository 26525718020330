import { setFormattedData } from "../../../components/Table/tableUtils"
import { Tabs, LocationForm, GradientButton, Loader } from "../../../components"
import { useGetAllOrganisationsAndLocationsQuery } from "../../../graphql/graphql"
import { useState, useEffect, useCallback } from "react"
import { CustomTable } from "../../../components/Table/Table"
import "./styles.scss"

function Locations() {
  const [formattedDataMap, setFormattedDataMap] = useState<any>([])
  const { data, loading, refetch } = useGetAllOrganisationsAndLocationsQuery()
  const [formScreen, setFormScreen] = useState<boolean>(false)
  const [selectedLocation, setSelectedLocation] = useState<any>(null)

  useEffect(() => {
    if (data?.getLocations.length) {
      setFormattedData(data.getLocations, setFormattedDataMap, "location")
      setFormScreen(true)
      return;
    }
    setFormattedData(
      [],
      setFormattedDataMap,
      "organisation"
    )
    setFormScreen(false);
  }, [data?.getLocations])

  const showForm = () => {
    setFormScreen(true)
  }

  const selectLocation = (org: any | null) => {
    setSelectedLocation(org)
    refetch()
  }

  const deselectLocation = useCallback(() => {
    setSelectedLocation(null);
  }, [])

  return (
    <div>
      {formScreen === false && formattedDataMap.length < 1 ? (
        <div className="add-location-wrapper">
          {loading ? (
            <Loader />
          ) : (
            <div>
              <div className="buttons-wrapper">
                <span>
                  There are no locations added. Click on the “Add location”
                  button to add one.
                </span>
                <GradientButton
                onClick={showForm}
                showIcon={true}
                iconColor={"white"}
                >
                  Add Location
                </GradientButton>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Tabs onClickCallback={deselectLocation}>
          <div title="Create new">
            <LocationForm refetch={refetch} />
          </div>
          <div title="All locations">
            <div className="locations-main">
              <CustomTable
                key={`TABLE${Math.floor(Math.random() * 10000)}`}
                onRowClick={selectLocation}
                dataIsLoading={loading}
                data={formattedDataMap}
                tableName="Wellbeing Champions"
              />
            </div>
          </div>
          {selectedLocation && (
            <div title={selectedLocation?.displayName}>
              <LocationForm
                refetch={refetch}
                isUpdateForm
                closeTab={() => selectLocation(null)}
                updateLocationId={selectedLocation?.id}
              />
            </div>
          )}
        </Tabs>
      )}
    </div>
  )
}

export default Locations
