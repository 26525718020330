import { SelectField, BarChart } from "../../../../../components"
import { useState, useEffect, useRef } from "react"

import {
  useGetActiveTeamCheckInByLocation
} from "../../../../../graphql/graphql"

const CheckinsLocation = ({
  organisation,
}: {
  organisation: {
    id: string,
    locations: {
      id: string,
      displayName: string
    }[]
  }
}) => {
  const [selectedLocation, setSelectedLocation] = useState<{
    value: string,
    label: string,
  }>()
  const [getActiveTeamCheckinByLocation,
    {
      data: activeTeamCheckInByLocation,
      refetch: refetchActiveTeamCheckInsByLocation
    }
  ] = useGetActiveTeamCheckInByLocation()
  const addSelectedOption = (name: string, option: any) => {
    setSelectedLocation(option)
  }

  const [locationDayLabels, setLocationDayLabels] = useState<string[]>([]);
  const locationCheckIns = useRef<number[]>([]);
  const locationCheckOuts = useRef<number[]>([]);

  const activeTeamDataLabelType: number[] = [];

  const activeTeamData = {
    labels: locationDayLabels,
    datasets: [
      {
        label: "Check-ins",
        data: locationCheckIns.current,
        backgroundColor: "#35C5AF"
      },
      {
        label: "Check-outs",
        data: locationCheckOuts.current,
        backgroundColor: "#CC576F"
      }
    ]
  }

  let locationOptionsType: { value: string, label: string }[] = []

  const locationType = useRef({
    name: "professionType",
    options: locationOptionsType
  })

  useEffect(() => {
    if (organisation.locations)
      organisation.locations.forEach(location => {
        locationType.current.options.push({ value: location.id, label: location.displayName })
      })
  }, [])

  useEffect(() => {
    if (selectedLocation)
      getActiveTeamCheckinByLocation({
        variables: {
          organisationId: organisation.id,
          locationId: selectedLocation.value
        }
      }).then(res => {
        setLocationDayLabels(
          Object.keys(res.data.getActiveTeamCheckInForLocationReportData)
        )
        Object.entries(res.data.getActiveTeamCheckInForLocationReportData).forEach((attendance: any, index: number) => {
          if (index) {
            const [position, attendances] = attendance;
            locationCheckIns.current[position - 1] = attendances.checkIns
            locationCheckOuts.current[position - 1] = attendances.checkOuts
          }
        })
      })
  }, [selectedLocation])

  return (
    <div>
      <div style={{ width: "25%", marginBottom: "20px" }}>
        <SelectField
          name={locationType.current.name}
          label="Locations"
          options={locationType.current.options}
          addSelectedOptions={addSelectedOption}
        />
      </div>
      <BarChart data={activeTeamData} chartType="checkins" />
    </div>
  )
}

export default CheckinsLocation
