import HomeIcon from "../../assets/HomeIcon"
import LocationIcon from "../../assets/LocationIcon"
import PeopleIcon from "../../assets/PeopleIcon"
import OrganizationIcon from "../../assets/OrganizationIcon"
import ReportsIcon from "../../assets/ReportsIcon"
import { AuthState } from "../../context/auth/authProvider"
import { ROLES } from "../../utils/roles"

export interface INavLink {
  id: number;
  text: string;
  path: string;
  icon: JSX.Element | SVGElement;
}

export const useNavLinks = () => {
  const { user } = AuthState()

  const links: Array<INavLink | null> = [
    {
      id: 1,
      text: "Home",
      path: "/",
      icon: <HomeIcon />
    },
    user?.role === ROLES.ADMIN
      ? {
        id: 2,
        text: "Locations",
        path: "/locations",
        icon: <LocationIcon />
      }
      : null,
    user?.role === ROLES.ADMIN
      ? {
        id: 4,
        text: "Organisations",
        path: "/organisations",
        icon: <OrganizationIcon />
      }
      : null,
    {
      id: 3,
      text: "Manage people",
      path: "/manage-people",
      icon: <PeopleIcon />
    },
    user?.role === ROLES.ORG_ADMIN
      ? {
        id: 5,
        text: "Reports",
        path: "/reports",
        icon: <ReportsIcon />
      }
      : null
  ].filter((element) => element)

  return {
    links,
    user
  }
}
