import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js"
import type { FC } from "react"
import { Bar } from "react-chartjs-2"
import { BarChartProps } from "./types"
import "./styles.scss"
import { daysWorkedConfig, checkinsConfig } from "./config"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const BarChart: FC<BarChartProps> = ({ data, chartType }) => {
  const defaultData = {
    labels: [
      "Mon",
      "Mon",
      "Mon",
      "Mon",
      "Mon",
      "Mon",
      "Mon",
      "Mon",
      "Mon",
      "Mon",
      "Mon",
      "Mon",
      "Mon",
      "Mon",
      "Mon"
    ],
    datasets: [
      {
        label: "Check-ins",
        data: [21, 18, 25, 21, 22, 7, 18, 21, 18, 25, 21, 22, 7, 18, 12],
        backgroundColor: "#35C5AF"
      },
      {
        label: "Check-outs",
        data: [25, 28, 14, 15, 15, 12, 14, 25, 28, 14, 15, 15, 12, 14, 12],
        backgroundColor: "#CC576F"
      }
    ]
  }

  return (
    <div className="bar-chart-wrapper">
      <Bar
        options={chartType === "daysWorked" ? daysWorkedConfig : checkinsConfig}
        data={data ? data : defaultData}
      />
    </div>
  )
}

export default BarChart
