import PlusIcon from "../../../assets/PlusIcon"
import type { FC } from "react"
import { OutlineButtonProps } from "./types"
import "./styles.scss"

const OutlineButton: FC<OutlineButtonProps> = ({
  children,
  onClick,
  type,
  width,
  showIcon
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      style={{ width: width }}
      className="outline-button"
    >
      {showIcon && (
        <div>
          <PlusIcon />
        </div>
      )}
      {children}
    </button>
  )
}

OutlineButton.defaultProps = {
  type: "button",
  showIcon: true
}
export default OutlineButton
