import jwt_decode from "jwt-decode"
import { JwtPayload } from "../components/Forms/LoginForm/types"

export const isTokenExpired = (token: string) =>  {
  const decodedToken: JwtPayload = jwt_decode(token)
  return decodedToken.exp < new Date().getTime()/1000
}

export const getUserIdFromToken = (accessToken: string): string => {
  let accessTokenDecoded

  try {
    const accessTokenDecodedObject = jwt_decode(accessToken) as {userId: string}
    accessTokenDecoded = accessTokenDecodedObject?.userId
  } catch (error: any) {
    accessTokenDecoded = ""
  }
  return accessTokenDecoded
}
