import { AvatarDataTypes } from "./types"

export const useAvatarBox = () => {
  const avatarBoxLeftData: AvatarDataTypes[] = [
    {
      id: "1",
      className: "avatar-box-left-first",
      img: "https://i.ibb.co/wY8D9WZ/serious-woman-portrait-blue-background-emotion-2021-08-31-16-39-34-utc-1.png",
      size: "94px",
    },

    {
      id: "2",
      className: "avatar-box-left-second",
      img: "https://i.ibb.co/TKVJh7V/excited-african-american-man-gesturing-yes-in-joy-2022-02-03-07-48-12-utc-1.png",
      size: "59px",
    },

    {
      id: "3",
      className: "avatar-box-left-third",
      img: "https://i.ibb.co/4WhYpgZ/depressed-hispanic-girl-with-sad-emotions-and-feel-2021-08-26-15-46-15-utc-1.png",
      size: "73px",
    },
  ]

  const avatarBoxRightData: AvatarDataTypes[] = [
    {
      id: "1",
      className: "avatar-box-right-first",
      img: "https://i.ibb.co/ZBj9mLy/fashion-girl-shows-her-emotions-in-a-photo-studio-2021-08-27-11-29-02-utc-1.png",
      size: "62px",
    },

    {
      id: "2",
      className: "avatar-box-right-second",
      img: "https://i.ibb.co/Rvmtj5p/emotions-2022-01-04-18-55-37-utc-1.png",
      size: "42px",
    },

    {
      id: "3",
      className: "avatar-box-right-third",
      img: "https://i.ibb.co/5RGPtcG/facial-expression-emotions-friendly-black-man-ya-2021-08-26-16-32-07-utc-1.png",
      size: "86px",
    },
  ]

  return {
    avatarBoxLeftData,
    avatarBoxRightData,
  }
}
