import { useRef, useState } from "react"
import { useEffect } from "react"
import {
  useCreateOrganisationMutation,
  useDeleteOrganisationMutation,
  useGetAllOrganisationsAndLocationsQuery,
  useGetNetworksQuery,
  useGetSubnetworksQuery,
  useGetVocationsQuery,
  useUpdateOrganisationMutation
} from "../../../graphql/graphql"
import { InsertedNetworkType, OrgFormInputs } from "./types"
// import { organisationTypeOptions } from "./OrganisationForm"

export const useOrganisationForm = (
  refetchOrganisations: () => void,
  toast: (message: string) => void,
  isUpdateForm?: boolean,
  updateOrgId?: string,
  closeTab?: () => void,
) => {
  const multiselectRef = useRef<any>();

  //states
  const [simpleInputs, setSimpleInputs] = useState<OrgFormInputs>({
    displayName: "",
    providerName: "",
    adminFirstName: "",
    adminLastName: "",
    adminEmail: "",
    adminPhone: "",
    odsCode: "",
    postCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    country: "",
    lat: "",
    long: "",
    cqc: "",
    uniqueIdentifier: ""
  })
  const [isSameAsProvider, setIsSameAsProvider] = useState(false)
  const [phoneValue, setPhoneValue] = useState("")
  //link location, selected networks, mapped networks for select-fields
  const [selectedLocations, setSelectedLocations] = useState<any>([])
  const [selectedType, setSelectedType] = useState<any>(null)
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [selectedVocation, setSelectedVocation] = useState<any>(null)
  const [selectedVocationSpeciality, setSelectedVocationSpeciality] =
    useState<any>(null)
  const [insertedNetworks, setInsertedNetworks] = useState<
    InsertedNetworkType[]
  >([])

  //options and data for modals forms
  const [networksData, setNetworksData] = useState<any>()
  const [locationOptions, setLocationOptions] = useState<any>()
  const [locationOptionsSingleChoice, setLocationOptionsSingleChoice] = useState<any>()
  const [organisationOptions, setOrganisationOptions] = useState<any>()
  const [vocationOptions, setVocationOptions] = useState<any>()
  const [vocationSpecialityOptions, setVocationSpecialityOptions] =
    useState<any>()
  const [networksOptions, setNetworksOptions] = useState<any>()
  const [subNetworksOptions, setSubNetworksOptions] = useState<any>()
  // apiProvidedAddress
  const [addressByPostcode, setAddressByPostcode] = useState<any>(null)


  //  ***** QUERIES HOOKS *****
  const { data: locationsData, refetch: refetchOrganisationsAndLocations } = useGetAllOrganisationsAndLocationsQuery()
  const { data: networksArray, refetch: refetchNetwoks } = useGetNetworksQuery()
  const { data: subnetworksArray, refetch: refetchSubnetworks } =
    useGetSubnetworksQuery()
  const { data: vocationData, refetch: refetchVocations } = useGetVocationsQuery()


  // ****** REQUESTS ******
  const [createOrganisation, { data, error: createError }] = useCreateOrganisationMutation()
  const [updateOrganisation, { data: updateOrgData, error: updateError }] =
    useUpdateOrganisationMutation()
  const [deleteOrganistation, {data: deleteOrganisationData, error: deleteError}] = useDeleteOrganisationMutation();


  // *****HANDLERS******

  //checkbox handler
  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSameAsProvider(e.target.checked)
  }

  //select-fields handler
  const addSelectedOption = (name: string, option: any) => {
    setInsertedNetworks((prevInsertedNetworks: any) => {
      if (!prevInsertedNetworks.length) return
      return prevInsertedNetworks.map((prevNetwork: any) => {
        if (prevNetwork.name === name) {
          return { ...prevNetwork, selectedSubnetwork: option.value }
        } else {
          return prevNetwork
        }
      })
    })
  }

  const handleSelectOrganisationType = (selectedItem: string) => {
    setSelectedType(selectedItem)
  }

  const handleSelectedAddress = (selectedItem: any) => {
    setSelectedAddress(selectedItem)
  }

  const handleDeleteOrganisation = () => {
    if (updateOrgId) {
      deleteOrganistation({
        variables: {
          organisationId: updateOrgId
        }
      })
    }
  }

  const handleVocation = (vocationId: string) => {
    setSelectedVocation(vocationId)
    setSelectedVocationSpeciality(null)
  }

  const handleVocationSpeciality = (vocationSpecialityId: string) => {
    setSelectedVocationSpeciality(vocationSpecialityId)
  }

  //multi-select handlers
  const handleSelect = (selectedList: any) => {
    setSelectedLocations(selectedList)
  }

  const handleRemove = (selectedList: any) => {
    setSelectedLocations(selectedList)
  }

  //simple-input handler
  const handleChange = (e: { target: { name: any; value: string } }) => {
    if (e.target.name === "postcode") {
      setSimpleInputs({
        ...simpleInputs,
        [e.target.name]: e.target.value?.replaceAll(" ", "")
      })
      return
    }

    setSimpleInputs({ ...simpleInputs, [e.target.name]: e.target.value })
  }

  //get subnetworks data and map options for the Create Network Modal
  useEffect(() => {
    const mappedSubnetworkOptions = subnetworksArray?.getSubnetworks.map(
      (subnetwork) => {
        return { name: `${subnetwork.name}`, id: subnetwork.id }
      }
    )
    setSubNetworksOptions(mappedSubnetworkOptions)
  }, [subnetworksArray?.getSubnetworks])

  //get networks data for the Insert networks modal and multi-selects
  useEffect(() => {
    setNetworksData(networksArray?.getNetworks)
    const mappedNetworksOptions = networksArray?.getNetworks.map((network) => {
      return { name: `${network.name}`, id: network.id }
    })
    setNetworksOptions(mappedNetworksOptions)
  }, [networksArray?.getNetworks])

  //get locations data and orgs data for multi-selects
  useEffect(() => {
    if (!locationsData?.getLocations && !locationsData?.getOrganisations) {
      return;
    }

    handleLocationDataSetup()
  }, [locationsData])

  // fetch vocation & vocation speciality options
  useEffect(() => {
    handleVocationSetup()
  }, [vocationData])

  const handleLocationDataSetup = () => {
    let mappedlocationOptions: any = locationsData?.getLocations.map(
      (location) => {
        return { name: `${location.name}`, id: location.id }
      }
    )
    setLocationOptions(mappedlocationOptions)
    
    mappedlocationOptions = locationsData?.getLocations.map(
      (location) => {
        return { label: `${location.name}`, id: location.address?.id || "" }
      }
    )
    setLocationOptionsSingleChoice(mappedlocationOptions)

    const mappedOrganisationOptions = locationsData?.getOrganisations.map(
      (organisation) => {
        return { name: `${organisation.displayName}`, id: organisation.id }
      }
    )
    setOrganisationOptions(mappedOrganisationOptions)
    console.log(mappedlocationOptions, selectedLocations);
  }

  const handleVocationSetup = () => {
    if (!vocationData) return
    setVocationOptions(
      vocationData?.getVocations.map((vocation) => {
        return {
          key: vocation.id,
          value: vocation.id,
          label: vocation.name
        }
      })
    )
  }

  useEffect(() => {
    const errorMessage = createError?.message || updateError?.message || deleteError?.message
    if (errorMessage && errorMessage?.includes("400")) {
      toast("Please make sure to fill all required fields.")
    }
    if (errorMessage && errorMessage?.includes("401")) { 
      toast("Make sure you are logged in.")
    }
  }, [createError?.message, updateError?.message, deleteError?.message])

  useEffect(() => {
    if (!selectedVocation || !vocationData?.getVocations.length) return
    setVocationSpecialityOptions(
      vocationData?.getVocations
        .find((vocation) => vocation.id === selectedVocation)
        ?.vocationSpecialities?.map((s) => ({ value: s.id, label: s.name }))
    )
  }, [selectedVocation, vocationData?.getVocations])

  useEffect(() => {
    if (isSameAsProvider === true) {
      setSimpleInputs((prevState) => {
        return {
          ...prevState,
          displayName: prevState.providerName
        }
      })
    }
  }, [isSameAsProvider])

  //postCodeApi
  useEffect(() => {
    const getAddressByPostcode = async () => {
      if (simpleInputs?.postCode?.length > 6) {
        try {
          const apiResponse = await fetch(
            `https://api.postcodes.io/postcodes/${simpleInputs.postCode}`
          )
          const addressResponse = await apiResponse.json()

          if (addressResponse.status === 200) {
            setAddressByPostcode(addressResponse)
          }
        } catch (error: any) {
          console.log("Get Address by Postcode API error", error)
        }
      }
    }
    getAddressByPostcode()
  }, [simpleInputs?.postCode])

  useEffect(() => {
    setSimpleInputs((s) => ({
      ...s,
      country: addressByPostcode?.result?.country,
      county: addressByPostcode?.result?.admin_county,
      city: addressByPostcode?.result?.admin_district,
      lat: addressByPostcode?.result?.latitude,
      long: addressByPostcode?.result?.longitude
    }))
  }, [addressByPostcode])

  // If it's an update form - populate the inputs state
  useEffect(() => {
    if (!updateOrgId || !isUpdateForm) return

    const orgToUpdateData =
      locationsData?.getOrganisations &&
      locationsData?.getOrganisations.find((org: any) => org.id === updateOrgId)

    let addressLine1 = "";
    let addressLine2 = "";

    if (orgToUpdateData?.mainAddress?.addressLine?.split(";").length > 1) {
      addressLine1 = orgToUpdateData?.mainAddress?.addressLine?.split(";")[0];
      addressLine2 = orgToUpdateData?.mainAddress?.addressLine?.split(";")[1]
    } else {
      addressLine1 = orgToUpdateData?.mainAddress?.addressLine;
    }

    if (orgToUpdateData) {
      setSimpleInputs({
        displayName: orgToUpdateData.displayName,
        providerName: orgToUpdateData.providerName,
        odsCode: orgToUpdateData?.odsCode || "",
        cqc: orgToUpdateData?.cqcLocationId || "",
        postCode: orgToUpdateData.mainAddress?.postcode,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        lat: String(orgToUpdateData.mainAddress?.lat),
        long: String(orgToUpdateData.mainAddress?.long),
        country: orgToUpdateData.mainAddress?.country,
        city: orgToUpdateData.mainAddress?.city,
        county: orgToUpdateData.mainAddress?.county,
        uniqueIdentifier: orgToUpdateData.uniqueInviteCode,
        adminFirstName: orgToUpdateData.primaryAdmin?.user?.firstName || "",
        adminLastName: orgToUpdateData.primaryAdmin?.user?.lastName || "",
        adminPhone: orgToUpdateData.primaryAdmin?.user?.phoneNumber || "",
        adminEmail: orgToUpdateData.primaryAdmin?.user?.email || ""
      })

      setSelectedLocations(
        orgToUpdateData.locations?.map((loc: any) => ({
          id: loc.id,
          name: loc.displayName
        }))
      )

      setSelectedVocation(orgToUpdateData.primaryAdmin?.user?.vocation?.id)
      setSelectedVocationSpeciality(orgToUpdateData.primaryAdmin?.vocationSpeciality?.id)
      setVocationSpecialityOptions(
        vocationData?.getVocations
          .find((vocation) => vocation.id === orgToUpdateData.primaryAdmin?.user?.vocation?.id)
          ?.vocationSpecialities?.map((s) => ({ value: s.id, label: s.name }))
      )
      setSelectedAddress(orgToUpdateData.mainAddress?.id)

      if(orgToUpdateData?.subnetworks?.length) {
        const networksToInsert = orgToUpdateData.subnetworks.reduce((networks: InsertedNetworkType[], subnetwork) => {
          if (subnetwork?.networks?.length) {
            for (let i = 0; i < subnetwork?.networks?.length; i++ ) {
              const subnetworks = subnetwork?.networks[i]?.subnetworks?.map((subnetwork: any) => {
                return {
                  key: subnetwork.id,
                  label: subnetwork.name,
                  value: subnetwork.id,
                }
              })
              networks.push({
                id: subnetwork?.networks[i].id,
                name: subnetwork?.networks[i].name,
                selectedSubnetwork: subnetwork.id,
                options: subnetworks || [],
              })
            }
          }
          return networks
        }, [])

        setInsertedNetworks(networksToInsert)
      }
      setSelectedType(orgToUpdateData.type)
    }
  }, [
    isUpdateForm,
    updateOrgId,
    locationsData?.getOrganisations,
    subnetworksArray?.getSubnetworks
  ])

  useEffect(() => {
    if (data?.createOrganisation || updateOrgData?.updateOrganisation) {
      setSimpleInputs({
        displayName: "",
        providerName: "",
        adminFirstName: "",
        adminLastName: "",
        addressLine1: "",
        addressLine2: "",
        adminEmail: "",
        adminPhone: "",
        odsCode: "",
        postCode: "",
        city: "",
        county: "",
        country: "",
        lat: "",
        long: "",
        cqc: "",
        uniqueIdentifier: ""
      })
      setInsertedNetworks([])
      setSelectedLocations([])
      setVocationSpecialityOptions([])

      setPhoneValue("")
      setSelectedVocation("")
      setSelectedVocationSpeciality("")
      setSelectedAddress("")
      setSelectedType("")
      refetchOrganisations()
      setIsSameAsProvider(false)
      
      refetchOrganisationsAndLocations()
      // refetchNetwoks()
      // refetchSubnetworks()
      // refetchVocations();
      handleLocationDataSetup()
      handleVocationSetup()

      multiselectRef?.current && (multiselectRef?.current as any)?.resetSelectedValues()

      toast(`${data?.createOrganisation ? "Create" : "Update"} successful!`)
    }

    if (updateOrgData?.updateOrganisation && closeTab) {
      closeTab();
    }
  }, [data?.createOrganisation, updateOrgData?.updateOrganisation])

  useEffect(() => {
    if (deleteOrganisationData?.deleteOrganisation && closeTab) {
      closeTab();
    }
  }, [deleteOrganisationData?.deleteOrganisation, closeTab])

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const subnetworkIds: string[] = [
      ...new Set(
        insertedNetworks.map(
          (network: InsertedNetworkType) => network.selectedSubnetwork!
        )
      )
    ]

    if (isUpdateForm && updateOrgId) {
      updateOrganisation({
        variables: {
          updateOrganisationId: updateOrgId,
          displayName: simpleInputs.displayName,
          providerName: simpleInputs.providerName,
          type: selectedType,
          odsCode: simpleInputs.odsCode,
          cqcLocationId: simpleInputs.cqc,
          locationIds: selectedLocations.map(
            (location: { id: string }) => location.id
          ),
          mainAddressId: selectedAddress as string,
          subnetworkIds,
          adminEmail: simpleInputs.adminEmail || null,
          adminFirstName: simpleInputs.adminFirstName || null,
          adminLastName: simpleInputs.adminLastName || null,
          adminPhone: phoneValue || null,
          adminContributeToPracticeScore: false,
          adminDataSharingConsent: false,
          adminShareMyScores: false,
          adminVocationId: selectedVocation,
          adminMemberVocationSpecialityId: selectedVocationSpeciality,
        }
      })
    } else {
      createOrganisation({
        variables: {
          displayName: simpleInputs.displayName,
          providerName: simpleInputs.providerName,
          type: selectedType,
          odsCode: simpleInputs.odsCode,
          cqcLocationId: simpleInputs.cqc,
          locationIds: selectedLocations.map(
            (location: { id: string }) => location.id
            ),
          subnetworkIds,
          mainAddressId: selectedAddress as string,
          adminEmail: simpleInputs.adminEmail,
          adminFirstName: simpleInputs.adminFirstName,
          adminLastName: simpleInputs.adminLastName,
          adminPhone: phoneValue,
          adminContributeToPracticeScore: false,
          adminDataSharingConsent: false,
          adminShareMyScores: false,
          adminVocationId: selectedVocation,
          adminMemberVocationSpecialityId: selectedVocationSpeciality
        }
      })
    }
  }

  return {
    simpleInputs,
    handleChange,
    submitForm,
    locationOptions,
    selectedLocations,
    selectedType,
    handleSelectOrganisationType,
    handleVocation,
    handleVocationSpeciality,
    handleDeleteOrganisation,
    handleSelect,
    handleRemove,
    handleCheckbox,
    isSameAsProvider,
    phoneValue,
    setPhoneValue,
    addSelectedOption,
    networksData,
    setInsertedNetworks,
    insertedNetworks,
    subNetworksOptions,
    refetchNetwoks,
    organisationOptions,
    vocationOptions,
    vocationSpecialityOptions,
    networksOptions,
    refetchSubnetworks,
    handleSelectedAddress,
    selectedAddress,
    locationOptionsSingleChoice,
    selectedVocation,
    selectedVocationSpeciality,
    multiselectRef
  }
}
