import {
  LocationType,
  Organization,
  AddressType,
  NetworkType,
  KPIType
} from "./types"
import { AuthState } from "../../../context/auth/authProvider"
import { useGetKpisQuery } from "../../../graphql/graphql"
import { useMemo } from "react"

export const useHomePage = () => {
  const { selectedOrganisation } = AuthState()
  const {data} = useGetKpisQuery()

  const organization: Organization = selectedOrganisation

  const locations: LocationType[] = selectedOrganisation?.locations

  const networksDataObject: any[] = selectedOrganisation?.subnetworks?.flatMap(
    (subnetwork: { id: string; networks: NetworkType[]; name:string }) => [
      {subnetworkName: subnetwork.name, networks:[...subnetwork.networks]}
    ]
  )

  const address: AddressType | undefined = organization?.mainAddress

  const keyPerformanceIndicators: KPIType[] = useMemo(() => [
    {
      label: "Total Users",
      data: data?.getStickyReportData?.totalUsers || 0
    },
    {
      label: "Total Organisations",
      data: data?.getStickyReportData?.totalOrganisations || 0
    },
    {
      label: "Active users",
      data: data?.getStickyReportData?.activeUsers || 0
    },
    {
      label: "Checked in",
      data: data?.getStickyReportData?.ongoingAttendances || 0
    },
    {
      label: "Work from home",
      data: data?.getStickyReportData?.wfhAttendances || 0
    },
    {
      label: "Work on site",
      data: data?.getStickyReportData?.onsiteAttendances || 0
    }
  ], [data])

  return {
    locations,
    organization,
    address,
    networksDataObject,
    keyPerformanceIndicators
  }
}
