export const stackedBarConfig = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      align: "end" as const,
      display: true,
      labels: {
        usePointStyle: true,
        color: "#000000",
        font: {
          size: 12,
          weight: "500",
          family: "Montserrat"
        }
      }
    }
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false
      },
      ticks: {
        font: {
          size: 12,
          family: "Montserrat",
          weight: "500"
        },
        color: "#000000"
      }
    },
    y: {
      ticks: {
        color: "#000000",
        font: {
          size: 12,
          family: "Montserrat",
          weight: "500"
        }
      },
      stacked: true,
      grid: {
        display: false
      }
    }
  }
}
