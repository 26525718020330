import {
  Tabs,
  OrganisationForm,
  CustomTable,
  setFormattedData,
  GradientButton,
  Loader
} from "../../../components"
import { useGetAllOrganisationsAndLocationsQuery } from "../../../graphql/graphql"
import { useState, useEffect, useCallback } from "react"
import "./styles.scss"

const Organisations = () => {
  const [formattedDataMap, setFormattedDataMap] = useState<any>([])
  const { data, loading, refetch } = useGetAllOrganisationsAndLocationsQuery()
  const [formScreen, setFormScreen] = useState<boolean>(false)
  const [selectedOrganisation, setSelectedOrganisation] = useState<any>(null)

  const refetchOrgs = () => {
    refetch()
  }

  useEffect(() => {
    if (data?.getOrganisations.length) {
      setFormattedData(
        data.getOrganisations,
        setFormattedDataMap,
        "organisation"
      )
      setFormScreen(true)
      return;
    }
    setFormattedData(
      [],
      setFormattedDataMap,
      "organisation"
    )
    setFormScreen(false);
  }, [data?.getOrganisations])

  const showForm = () => {
    setFormScreen(true)
  }

  const selectOrganisation = (org: any | null) => {
    setSelectedOrganisation(org)
    refetch()
  }

  const deselectOrganisation = useCallback(() => {
    setSelectedOrganisation(null);
  }, [])

  return (
    <div>
      {!formScreen && !formattedDataMap.length ? (
        <div className="add-organisation-wrapper">
          {loading ? (
            <Loader />
          ) : (
            <div>
              <div className="buttons-wrapper">
                <span>
                  There are no organisations added. Click on the “Add
                  organisation” button to add one.
                </span>
                <GradientButton
                  onClick={showForm}
                  showIcon={true}
                  iconColor={"white"}
                >
                  Add Organisation
                </GradientButton>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Tabs onClickCallback={deselectOrganisation}>
          <div title="Create new">
            <OrganisationForm refetchOrganisations={refetchOrgs} />
          </div>
          <div title="All organisations">
            <CustomTable
              key={`TABLE${Math.floor(Math.random() * 10000)}`}
              onRowClick={selectOrganisation}
              dataIsLoading={loading}
              data={formattedDataMap}
              tableName="All Organisations"
            />
          </div>
          {selectedOrganisation && (
            <div title={selectedOrganisation?.displayName}>
              <OrganisationForm
                refetchOrganisations={refetchOrgs}
                isUpdateForm
                closeTab={() => selectOrganisation(null)}
                updateOrgId={selectedOrganisation?.id}
              />
            </div>
          )}
        </Tabs>
      )}
    </div>
  )
}

export default Organisations
