import GradientButton from "../../Buttons/GradientButton/GradientButton"
import Card from "../../Card/Card"
import "./styles.scss"

export const UnlinkUnassignModal = ({
  callback,
  closeModal
}: {
  callback: () => void;
  closeModal: () => void;
}) => {
  return (
    <Card width="40vw">
      <div className="unlink-modal-main">
        Are you sure you want to perform this action?
        <div className="button-container">
          <GradientButton
            variant="green"
            styles={{ marginRight: "5px" }}
            width="100%"
            onClick={callback}
          >
            yes
          </GradientButton>
          <GradientButton
            styles={{ marginLeft: "5px" }}
            width="100%"
            onClick={closeModal}
          >
            no
          </GradientButton>
        </div>
      </div>
    </Card>
  )
}
