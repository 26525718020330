import React, { useRef } from "react"
import SelectField from "../../SelectFieldComponent/SelectField"
import { ROLES } from "../../../utils/roles"
import { useOnClickOutside } from "../../../hooks"
import "./styles.scss"
import { useHeader } from "../hooks"

interface UserNavProps {
  parentRef: React.MutableRefObject<HTMLDivElement | null>,
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const UserNav: React.FC<UserNavProps> = ({ parentRef, showMenu, setShowMenu }) => {
  const { user, logout, selectedOrganisation, organisationOptions, addSelectedOption } = useHeader()
  const navWrapper = useRef(null)
  useOnClickOutside(navWrapper, parentRef, () => setShowMenu(false))


  return (
    <>
      {
        showMenu ?
          <ul ref={navWrapper} className="user-nav-dropdown">
            {
              user.role === ROLES.ORG_ADMIN ?
                <>
                  <div className="org-info">
                    <span className="org-title">Etho Organisation Code</span>
                    <span className="org-code">
                      {selectedOrganisation.uniqueInviteCode}
                      <img title="Copy to clipboard" src="./assets/copyIcon.svg" alt="" className="copy-icon" onClick={() => {
                        // Copy org id to clipboard
                        navigator.clipboard.writeText(selectedOrganisation.uniqueInviteCode)
                      }} />
                    </span>
                  </div>
                  <div className="divider"></div>
                  {organisationOptions?.length > 1 && <SelectField 
                    name="Change organisation" 
                    label="Change organisation" 
                    size="small"
                    options={organisationOptions} 
                    addSelectedOptions={addSelectedOption} />}
                  {/* <li className="user-nav-link">
                    <img src="./assets/profileIcon.svg" alt="Profile Icon" />
                    Profile settings
                  </li> */}
                </>
                : null
            }
            <li className="user-nav-link" onClick={logout}>
              <img src="./assets/logoutIcon.svg" alt="Logout Icon" />
              Log out
            </li>
          </ul>
          : null
      }
    </>
  )
}

export default UserNav