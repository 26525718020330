import { useState } from "react"
import { useEffect } from "react"
import { AuthState } from "../../../context/auth/authProvider"
import { useNavigate } from "react-router-dom"
import {
  useVerifyLoginLazyQuery,
  useAttemptLoginMutation,
} from "../../../graphql/graphql"

const FORM_STEPS = {
  PHONE: "phone",
  SMS: "sms"
}

export const useLoginForm = () => {
  const { handleAuthenticatedUser, user } = AuthState()
  const [phoneValue, setPhoneValue] = useState("")
  const [smsCodeValue, setSmsCodeValue] = useState("")
  const [screen, setScreen] = useState(FORM_STEPS.PHONE)
  const [triggerTransition, setTriggerTransition] = useState(false)
  const navigate = useNavigate()

  const [attemptLogin, { data, error }] = useAttemptLoginMutation()
  const [verifyLogin, { data: verifyData, error: verifyDataError }] =
    useVerifyLoginLazyQuery()

  useEffect(() => {
    if (data) {
      setTriggerTransition(true)
      setTimeout(() => {
        setScreen(FORM_STEPS.SMS)
        setTriggerTransition(false)
      }, 1000)
    }
  }, [data])

  useEffect(() => {
    if (verifyData?.verifyLogin) {
      localStorage.setItem(
        process.env.REACT_APP_ACCESS_TOKEN || "",
        verifyData?.verifyLogin?.accessToken || ""
      )

      localStorage.setItem(
        process.env.REACT_APP_REFRESH_TOKEN || "",
        verifyData?.verifyLogin?.refreshToken || ""
      )

      handleAuthenticatedUser()
    }
  }, [verifyData?.verifyLogin])

  useEffect(() => {
    user?.id && navigate("/")
  }, [user?.id])

  const submitPhoneNumber = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!phoneValue) {
      return
    } else {
      attemptLogin({
        variables: {
          phoneNumber: `+${phoneValue}`,
          userAgent: "web"
        },
      })
    }
  }

  const verifySMS = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!smsCodeValue) {
      return
    } else {
      verifyLogin({
        variables: {
          otp: smsCodeValue,
        },
      })
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (screen === FORM_STEPS.PHONE) {
      submitPhoneNumber(event)
    } else {
      verifySMS(event)
    }
  }

  return {
    onSubmit,
    screen,
    triggerTransition,
    verifyDataError,
    error,
    phoneValue,
    setPhoneValue,
    smsCodeValue,
    setSmsCodeValue,
  }
}
