export const multiAxisConfig = {
  responsive: true,
  interaction: {
    mode: "index" as const,
    intersect: false
  },
  stacked: false,
  plugins: {
    legend: {
      display: true,
      position: "top" as const,
      align: "end" as const,
      labels: {
        usePointStyle: true,
        color: "#000000",
        font: {
          size: 12,
          weight: "500",
          family: "Montserrat"
        }
      }
    }
  },
  scales: {
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
      grid: {
        display: false
      },
      ticks: {
        color: "#000000",
        maxTicksLimit: 5,
        stepSize: 50,
        font: {
          size: 12,
          family: "Montserrat",
          weight: "500"
        }
      }
    },
    x: {
      ticks: {
        color: "#000000",
        font: {
          size: 12,
          family: "Montserrat",
          weight: "500"
        }
      }
    },
    y1: {
      type: "linear" as const,
      display: false,
      position: "right" as const,
      grid: {
        drawOnChartArea: false
      }
    }
  }
}
