import { Navigate } from "react-router-dom"
import { ProtectedRouteProps } from "./types"
import { FC } from "react"
import { AuthState } from "../../context/auth/authProvider"
import { Loader } from "../../components"

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const { isInitialLoading, user } = AuthState()

  if (isInitialLoading && !user) {
    return <Loader />
  }

  return user ? children : <Navigate to="/login"></Navigate>
}

export default ProtectedRoute
