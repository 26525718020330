import SearchIcon from "../../assets/SearchIcon"
import "./styles.scss"
import { FC } from "react"

interface SearchInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchInput: FC<SearchInputProps> = ({ onChange }) => {
  return (
    <div className="search-input-container">
      <div className="box">
        <SearchIcon />
        <input placeholder="Search" type="text" name="" onChange={onChange} />
      </div>
    </div>
  )
}

export default SearchInput
