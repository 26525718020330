import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js"
import { Bar } from "react-chartjs-2"
import type { FC } from "react"
import "./styles.scss"
import { StackedBarChartProps } from "./types"
import { stackedBarConfig } from "./config"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const StackedBarChart: FC<StackedBarChartProps> = ({ data }) => {
  const defaultData = {
    labels: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
    datasets: [
      {
        label: "Skipped",
        data: [10, 20, 10, 20, 30, 40, 50, 60, 80, 30, 90, 20, 21, 30, 50, 60],
        backgroundColor: "#DFDFDF"
      },
      {
        label: "Terrible",
        data: [
          10, 20, 10, 20, 30, 40, 50, 60, 80, 30, 90, 20, 21, 30, 50, 60, 60
        ],
        backgroundColor: "#DC5A6D"
      },
      {
        label: "Struggling",
        data: [
          10, 20, 10, 20, 30, 40, 50, 60, 80, 30, 90, 20, 21, 30, 50, 60, 60
        ],
        backgroundColor: "#FF8383"
      },
      {
        label: "Coping",
        data: [
          10, 20, 10, 20, 30, 40, 50, 60, 80, 30, 90, 20, 21, 30, 50, 60, 60
        ],
        backgroundColor: "#6D90B9"
      },
      {
        label: "Good",
        data: [
          10, 20, 10, 20, 30, 40, 50, 60, 80, 30, 90, 20, 21, 30, 50, 60, 60
        ],
        backgroundColor: "#32C4C0"
      },
      {
        label: "Great",
        data: [
          10, 20, 10, 20, 30, 40, 50, 60, 80, 30, 90, 20, 21, 30, 50, 60, 60
        ],
        backgroundColor: "#30C67C"
      }
    ]
  }

  return (
    <div className="stacked-chart-wrapper">
      <Bar options={stackedBarConfig} data={data ? data : defaultData} />
    </div>
  )
}

export default StackedBarChart
