import "./styles.scss"
import { FC} from "react"
import { SwichButtonProps } from "./types"

const SwitchButton: FC<SwichButtonProps> = ({ handleChange, isChecked}) => {

  return (
    <div className="switch-button">
      <label className="switch">
        <input type="checkbox" checked={isChecked} onChange={handleChange}/>
        <span className="slider round"></span>
      </label>
    </div>
  )
}

export default SwitchButton
