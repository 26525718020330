import "./styles.scss"
import Logo from "../../../assets/Logo"
import { Field } from "../../index"
import { useLoginForm } from "./hooks"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/material.css"

const LoginForm = () => {
  const {
    onSubmit,
    screen,
    triggerTransition,
    error,
    verifyDataError,
    phoneValue,
    setPhoneValue,
    smsCodeValue,
    setSmsCodeValue,
  } = useLoginForm()

  return (
    <div
      className={
        triggerTransition ? "login-form-wrapper exit" : "login-form-wrapper "
      }
    >
      <div>
        <Logo width="166" height="64" />
      </div>

      <div className="text">Nurture your team</div>

      <form name="contact" className="contact-form" onSubmit={onSubmit}>
        <div className="fields">
          {screen === "phone" ? (
            <PhoneInput
              country={"gb"}
              specialLabel="Phone Number"
              onlyCountries={["gb"]}
              showDropdown={false}
              disableDropdown={true}
              value={phoneValue}
              onChange={(phone) => {setPhoneValue(phone)}}
            />
          ) : (
            <Field
              handleChange={(e) => {setSmsCodeValue(e.target.value)}}
              value={smsCodeValue}
              name="sms"
              type="number"
              label="SMS code"
            />
          )}

          {error || verifyDataError ? (
            <div className="error-msg">
              {error?.toString() && "This mobile phone number does not have admin access to etho, please check and try again"}
              {verifyDataError?.toString() && "Invalid verification code. Please check and try again or perform login again."}
            </div>
          ) : (
            <div className="confirmation-msg">
              {screen === "phone"
                ? "You will receive  an (SMS) verification code on your phone"
                : "Please enter the (SMS) verification code received on your phone"}
            </div>
          )}
        </div>
        <button className="login-button" type="submit">
          {screen === "phone" ? "Login" : "Verify SMS"}
        </button>
      </form>
    </div>
  )
}

export default LoginForm
