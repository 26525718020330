export const setFormattedData = (
  data: any,
  callback: (x: any) => void,
  type: "member" | "location" | "organisation" | "user",
  codesList?: any
) => {
  let temp

  switch (type) {
  case "user":
    temp = formatDataForUser(data)
    break
  case "member":
    temp = formatDataForMember(data, codesList)
    break
  case "location":
    temp = formatDataForLocation(data)
    break
  case "organisation":
    temp = formatDataForOrganisation(data)
  }

  callback([...temp])
}

const formatDataForMember = (data: any, codesList?: any) => {
  const dataToUse =
    data?.length && codesList?.length
      ? [...data, ...codesList]
      : data?.length
        ? [...data]
        : codesList?.length
          ? [...codesList]
          : []

  return dataToUse?.length
    ? dataToUse.map((data: any) => {
      if (!Object.keys(data).includes("code"))
        return {
          id: data.id,
          name: `${data.user?.firstName} ${data.user?.lastName}`,
          role: data?.user?.vocation?.name
        }
      else
        return {
          id: data.id,
          name: data.code,
          isCode: true
        }
    })
    : []
}

const formatDataForUser = (data: any) => {
  return data.map((user: any) => ({
    id: user.id,
    name: `${user?.firstName} ${user?.lastName}`,
    role: user?.vocation?.name
  }))
}

const formatDataForLocation = (data: any) => {
  return data.map((location: any) => ({
    id: location.id,
    name: location.name,
    displayName: location.displayName,
    address: location.address.addressLine
  }))
}

const formatDataForOrganisation = (data: any) => {
  return data.map((org: any) => ({
    id: org.id,
    displayName: org.providerName,
    address: org.locations
      .map((location: any) => location?.address?.addressLine)
      .join(", ")
  }))
}
