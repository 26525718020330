
import "./styles.scss"
import { AvatarBox } from "../../components/index"
import { GuestSessionForm } from "../../components/Forms/GuestSessionForm/GuestSessionForm"

export const GuestSession = () => {
  return (
    <div className="login-page-wrapper">
      <div className="login-page-content">
        <AvatarBox isLeft />
        <GuestSessionForm />
        <AvatarBox />
      </div>
    </div>
  )
}
