import { FC } from "react";
import { MultiAxisChart } from "../../../../../components"
import { GetOrgAdminReportsQuery } from "../../../../../graphql/graphql";

interface TeamMoraleProps {
  data: GetOrgAdminReportsQuery
}

const TeamMorale: FC<TeamMoraleProps> = ({ data }) => {
  return <MultiAxisChart data={{
    datasets: [{
      data: [data?.getCurrentOrganisationTeamMoraleReportData.prevPrevMonth[1], data?.getCurrentOrganisationTeamMoraleReportData.prevMonth[1], data?.getCurrentOrganisationTeamMoraleReportData.currentMonth[1]],
      label: "Terrible",
      backgroundColor: "#DC5A6D",
      borderColor: "#DC5A6D"
    },
    {
      data: [data?.getCurrentOrganisationTeamMoraleReportData.prevPrevMonth[2], data?.getCurrentOrganisationTeamMoraleReportData.prevMonth[2], data?.getCurrentOrganisationTeamMoraleReportData.currentMonth[2]],
      label: "Struggling",
      backgroundColor: "#FF8383",
      borderColor: "#FF8383",
    },{
      data: [data?.getCurrentOrganisationTeamMoraleReportData.prevPrevMonth[3], data?.getCurrentOrganisationTeamMoraleReportData.prevMonth[3], data?.getCurrentOrganisationTeamMoraleReportData.currentMonth[3]],
      label: "Coping",
      backgroundColor: "#6D90B9",
      borderColor: "#6D90B9",
    },{
      data: [data?.getCurrentOrganisationTeamMoraleReportData.prevPrevMonth[4], data?.getCurrentOrganisationTeamMoraleReportData.prevMonth[4], data?.getCurrentOrganisationTeamMoraleReportData.currentMonth[4]],
      label: "Good",
      backgroundColor: "#32C4C0",
      borderColor: "#32C4C0",
    },{
      data: [data?.getCurrentOrganisationTeamMoraleReportData.prevPrevMonth[5], data?.getCurrentOrganisationTeamMoraleReportData.prevMonth[5], data?.getCurrentOrganisationTeamMoraleReportData.currentMonth[5]],
      label: "Great",
      backgroundColor: "#30C67C",
      borderColor: "#30C67C",
    }
  ],
    labels: [
      new Intl.DateTimeFormat('en-US', {month: "long"}).format(new Date(new Date().getFullYear(), new Date().getMonth() - 2)),
      new Intl.DateTimeFormat('en-US', {month: "long"}).format(new Date(new Date().getFullYear(), new Date().getMonth() - 1)),
      new Intl.DateTimeFormat('en-US', {month: "long"}).format(new Date(new Date().getFullYear(), new Date().getMonth()))
    ]
  }}/>
}

export default TeamMorale
