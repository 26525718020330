import { useEffect, useState } from "react"
import { useCreateNetworkMutation } from "../../../graphql/graphql"

export const useCreateNetworkModal = (
  subnetworks: any,
  refetchNetworks: any,
  toast: (message: string) => void
) => {
  const [newNetwork, setNewNetwork] = useState({
    networkName: "",
  })

  const [createNetwork, { data, error }] = useCreateNetworkMutation()

  const [selectedSubnetworks, setSelectedSubnetworks] = useState([])


  //simpleInputs Handler
  const handleChange = (e: { target: { name: any; value: any } }) => {
    setNewNetwork({ ...newNetwork, [e.target.name]: e.target.value })
  }

  //multiSelect Handlers
  const handleSelect = (selectedList: any) => {
    setSelectedSubnetworks(selectedList)
  }

  const handleRemove = (selectedList: any) => {
    setSelectedSubnetworks(selectedList)
  }

  useEffect(() => {
    if (data?.createNetwork) {
      setNewNetwork({
        networkName: ""
      })

      setSelectedSubnetworks([])
      refetchNetworks()
      toast("Create successful!")
    }
  }, [data?.createNetwork])

  useEffect(() => {
    const errorMessage = error?.message;
    if (errorMessage && errorMessage?.includes("400")) {
      toast("Please make sure to fill all required fields.")
    }
    if (errorMessage && errorMessage?.includes("401")) { 
      toast("Make sure you are logged in.")
    }
  }, [error])

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    createNetwork({
      variables: {
        name: newNetwork.networkName,
        subnetworkIds: selectedSubnetworks.map(
          (subnetwork: { id: string }) => subnetwork.id
        ),
      },
    })
  }

  return {
    newNetwork,
    handleChange,
    handleSelect,
    handleRemove,
    selectedSubnetworks,
    submitForm,
  }
}
