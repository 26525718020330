import { SelectField, BarChart } from "../../../../../components"
import { useState, useEffect, useRef } from "react"

import {
  useGetVocationsQuery,
  useGetActiveTeamCheckInByRole
} from "../../../../../graphql/graphql"

const CheckinsRole = ({
  organisationId,
}: {
  organisationId: string
}) => {
  const [selectedProfession, setSelectedProfession] = useState<{
    id: string,
    name: string,
    label: string,
    value: string,
    vocationGroup: {
      id: string,
      name: string,
    },
    vocationSpecialities: {
      id: string,
      name: string
    }
  }>()

  const { data: vocationData, refetch: refetchVocations } = useGetVocationsQuery()
  const [getActiveTeamCheckInByRole, { data: activeTeamCheckInData, refetch: refetchActiveTeamCheckIn }] = useGetActiveTeamCheckInByRole()

  const [attendancesDayLabels, setAttendancesDayLabels] = useState<string[]>([]);
  const vocationCheckIns = useRef<number[]>([])
  const vocationCheckOuts = useRef<number[]>([])

  let professionTypeOptions: { value: string, label: string }[] = []

  const professionType = useRef({
    name: "professionType",
    options: professionTypeOptions
  })

  const activeTeamData = {
    labels: attendancesDayLabels,
    datasets: [
      {
        label: "Check-ins",
        data: vocationCheckIns.current,
        backgroundColor: "#35C5AF"
      },
      {
        label: "Check-outs",
        data: vocationCheckOuts.current,
        backgroundColor: "#CC576F"
      }
    ]
  }

  useEffect(() => {
    if (vocationData?.getVocations) {
      const vocationGroupIdsObject: any = {}
      vocationData?.getVocations.map((vocation: any) => {
        if (vocationGroupIdsObject[vocation.vocationGroup.id]) {
          return;
        } else {
          professionType?.current.options.push({ value: vocation.vocationGroup.id, label: vocation.vocationGroup.name, ...vocation })
          vocationGroupIdsObject[vocation.vocationGroup.id] = true;
        }
      })
    }
  }, [vocationData])

  useEffect(() => {
    if (selectedProfession) {
      getActiveTeamCheckInByRole({
        variables: {
          organisationId: organisationId,
          vocationGroupId: selectedProfession.vocationGroup.id
        }
      }).then(res => {
        setAttendancesDayLabels(Object.keys(res.data.getActiveTeamCheckInForVocationGroupReportData))
        Object.entries(res.data.getActiveTeamCheckInForVocationGroupReportData).forEach((attendanceObject: any, index: number) => {
          if (index) {
            const [position, attendances] = attendanceObject;
            vocationCheckIns.current[position] = attendances.checkIns;
            vocationCheckOuts.current[position] = attendances.checkOuts;
          }
        })
      })
    }
  }, [selectedProfession])

  const addSelectedOption = (name: string, option: any) => {
    setSelectedProfession(option)
  }



  return (
    <div>
      <div style={{ width: "25%", marginBottom: "20px" }}>
        <SelectField
          name={professionType.current.name}
          label="Profession"
          options={professionType.current.options}
          addSelectedOptions={addSelectedOption}
        />
      </div>
      <BarChart data={activeTeamData} chartType="checkins" />
    </div>
  )
}

export default CheckinsRole
