import { BrowserRouter, Routes, Route } from "react-router-dom"
import {
  Login,
  Home,
  Locations,
  ManagePeople,
  Organisations,
  ProtectedRoute,
  Reports
} from "./pages"
import SharedLayout from "./components/SharedLayout/SharedLayout"
import { browserClient } from "./services/apollo-client"
import { ApolloProvider } from "@apollo/client"
import AuthProvider from "./context/auth/authProvider"
import { RegisteredRoute } from "./pages/ProtectedRoute/RegisteredRoute"
import { ROLES } from "./utils/roles"
import { GuestSession } from "./pages/GuestSession/GuestSession"

function App() {
  return (
    <div id="app">
      <ApolloProvider client={browserClient}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <SharedLayout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<Home />} />
                <Route path="manage-people" element={<ManagePeople />} />
                <Route
                  path="locations"
                  element={
                    <RegisteredRoute restrictedRole={ROLES.ADMIN}>
                      <Locations />
                    </RegisteredRoute>
                  }
                />
                <Route
                  path="reports"
                  element={
                    <RegisteredRoute restrictedRole={ROLES.ORG_ADMIN}>
                      <Reports />
                    </RegisteredRoute>
                  }
                />
                <Route
                  path="organisations"
                  element={
                    <RegisteredRoute restrictedRole={ROLES.ADMIN}>
                      <Organisations />
                    </RegisteredRoute>
                  }
                />
              </Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/guest/:uniqueId" element={<GuestSession />}></Route>
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </ApolloProvider>
    </div>
  )
}

export default App
