import ReactDom from "react-dom"
import React, { forwardRef, useState, useImperativeHandle, useRef, useCallback } from "react"
import "./styles.scss"

type PartialModal = {
  open: () => void;
  close: () => void;
};

const Modal = forwardRef(function ModalBody({ children, customClose }: { children: React.ReactNode, customClose?: () => void | null }, ref) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])

  useImperativeHandle(ref, () => ({
    close,
    open
  }))

  if (!isOpen) return null

  return ReactDom.createPortal(
    <div className={`modal-container ${isOpen ? "reveal" : "out"}`} id="modal-container" onClick={(event) => {
      if (!((event.target as any)?.id !== "modal-container"))
        customClose ? customClose() : close()
    }}>
      <div className="modal">
        {children}
      </div>
    </div>,
    document.getElementById("root") as Element
  )
})

export const useModal = () => {
  const ref = useRef<PartialModal>(null)
  const RenderModal = (props: {children: React.ReactNode, customClose?: () => void}) => <Modal {...props} ref={ref}>{props.children}</Modal>

  return {
    open: () => ref.current?.open(),
    close: () => ref.current?.close(),
    Modal: RenderModal,
  }
}