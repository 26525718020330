import { useEffect, useId, useState } from "react"
import { SortIcon } from "../../assets/Sort"
import { DeleteIcon } from "../../assets/DeleteIcon"
import { UnlinkIcon } from "../../assets/UnlinkIcon"
import { UnassignIcon } from "../../assets/UnassignIcon"
import { AcceptIcon } from "../../assets/ActionsAcceptIcon"
import { RejectIcon } from "../../ActionsRejectIcon"
import { OutlineButton, SearchInput } from "../../components"
import Loader from "../Loader/Loader"
import "./styles.scss"

export const CustomTable = ({
  tableName,
  data,
  dataIsLoading,
  hasDelete,
  hasUnlink,
  hasUnassign,
  hasActions,
  hasButton,
  buttonText,
  onClick,
  onRowClick,
  unlinkAction,
  unassignAction,
  deleteAction,
  validAction,
  negativeAction
}: {
  tableName: string
  data: any
  dataIsLoading: boolean
  hasDelete?: boolean
  hasUnlink?: boolean
  hasUnassign?: boolean
  hasActions?: boolean
  hasButton?: boolean
  buttonText?: string
  onClick?: () => any
  onRowClick?: (x: any) => any
  unlinkAction?: (x: any) => any
  unassignAction?: (x: any) => any
  deleteAction?: (x: any) => any
  validAction?: (x: any) => any
  negativeAction?: (x: any) => any
}) => {
  const id = useId()
  const [filteredData, setFilteredData] = useState<any>([])

  useEffect(() => {
    if(!data) return
    // On mount, filtered data = data so it shows everything if its not filtered
    setFilteredData(data)
  }, [data])

  const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!data) return

    const lowerCaseValue = e.currentTarget.value.toLowerCase()

    setFilteredData(
      data?.filter(
        (obj: any) =>
          obj?.displayName?.toLowerCase().includes(lowerCaseValue) ||
          obj?.name?.toLowerCase().includes(lowerCaseValue)
      )
    )
  }

  const handleRowClick = (row: any) => {
    if (onRowClick) {
      onRowClick(row)
    }
  }

  return (
    <div className="table-main">
      <div className="table-header">
        <span>{tableName}</span>
        <div className="actions">
          {hasButton && buttonText && (
            <OutlineButton width="250px" onClick={onClick}>
              {buttonText}
            </OutlineButton>
          )}
          <SearchInput onChange={handleSearchQuery} />
        </div>
      </div>
      {dataIsLoading ? (
        <Loader />
      ) : (
        <div className="table-rows-container">
          {!data?.length && (
            <div className="table-no-data">No data to display</div>
          )}
          {data?.length ? (
            <div className={`table-subheader ${hasDelete && "delete"}`}>
              {Object.keys(data[0]).map((key: string, index: number) => {
                if (key === "id") return null
                if (key === "isCode") key = "Role"
                return (
                  <div key={`${id}-${index}`} className="table-subheader-cell">
                    {key}
                    <span>
                      <SortIcon />
                    </span>
                  </div>
                )
              })}
              {hasDelete && (
                <div className="table-subheader-cell action">Delete</div>
              )}
              {hasUnassign && filteredData?.length > 1 && filteredData[1]?.name && (
                <div className="table-subheader-cell action">Unassign</div>
              )}
              {hasUnlink && ((filteredData?.length > 1 && filteredData[1]?.name) || tableName === "Wellbeing Champions") && (
                <div className="table-subheader-cell action">Unlink</div>
              )}
              {hasActions && (
                <div className="table-subheader-cell action">Actions</div>
              )}
            </div>
          ) : null}
          
          {filteredData?.length
            ? filteredData.map((row: any, index: number) => (
              <div
                key={`${id}-${index}`}
                onClick={() => handleRowClick(row)}
                className={`table-row ${hasDelete && "delete"} ${
                  onRowClick && "selectable"
                }`}
              >
                {Object.keys(row).map((key: string, index: number) => {
                  if (key === "id") return null
                  return (
                    <div key={`${id}-${index}`} className="table-row-cell">
                      {row[key]}
                    </div>
                  )
                })}
                {!row?.isCode ? (
                  <>
                    {hasDelete && (
                      <div
                        className="action delete"
                        onClick={() => deleteAction && deleteAction(row)}
                      >
                        <DeleteIcon />
                      </div>
                    )}
                    {hasUnassign && filteredData?.length > 1 && filteredData[1]?.name && (
                      <div
                        className="action"
                        onClick={() => unassignAction && unassignAction(row)}
                      >
                        <UnassignIcon />
                      </div>
                    )}
                    {hasUnlink && ((filteredData?.length > 1 && filteredData[1]?.name)) && (
                      <div
                        className="action"
                        onClick={() => unlinkAction && unlinkAction(row)}
                      >
                        <UnlinkIcon />
                      </div>
                    )}
                    {hasActions && (
                      <div className="action padded two-icons">
                        <AcceptIcon
                          onClick={() => validAction && validAction(row)}
                        />
                        <RejectIcon
                          onClick={() =>
                            negativeAction && negativeAction(row)
                          }
                        />
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            ))
            : null}
        </div>
      )}
    </div>
  )
}
