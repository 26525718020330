import Logo from "../../assets/Logo"
import NavLinks from "../NavLinks/NavLinks"
import "./styles.scss"

function Sidebar() {
  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-container">
        <div className="content">
          <div style={{ marginLeft: "8px" }}>
            <Logo />
          </div>
          <NavLinks />
        </div>
      </div>
    </div>
  )
}

export default Sidebar
