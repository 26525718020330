import type { FC } from "react"
import { GradientButtonProps } from "./types"
import PlusIcon from "../../../assets/PlusIcon"
import "./styles.scss"

const GradientButton: FC<GradientButtonProps> = ({
  children,
  width,
  variant,
  onClick,
  styles,
  type,
  disabled,
  showIcon,
  iconColor
}) => {
  return (
    <button
      onClick={onClick}
      style={{ ...styles, width: width }}
      className={`gradient-button ${variant ? variant : ""}`}
      type={type}
      disabled={disabled}
    >
      {showIcon && (
        <div>
          <PlusIcon color={iconColor} />
        </div>
      )}
      {children}
    </button>
  )
}

GradientButton.defaultProps = {
  variant: "purple",
  type: "button",
  showIcon: false
}

export default GradientButton
