import { useAvatarBox } from "./hooks"
import Avatar from "../Avatar/Avatar"
import type { FC } from "react"
import { avatarBoxProps } from "./types"
import "./styles.scss"

const AvatarBox : FC<avatarBoxProps> = ({isLeft}) => {

  const {avatarBoxLeftData, avatarBoxRightData} = useAvatarBox()

  const direction = isLeft ? avatarBoxLeftData : avatarBoxRightData

  return (
    <div className="avatar-box">
      {direction.map((avatar) => {
        return <div key={avatar.id} className={avatar.className}>
          <Avatar size={avatar.size} src={avatar.img}/>
        </div>
      })}
    </div>
  )
}

export default AvatarBox
