import { FC, useMemo } from "react"
import { PieChart } from "../../../../../components"
import { GetOrgAdminReportsQuery } from "../../../../../graphql/graphql"
import "./styles.scss"

interface LocationUsageProps {
  data: GetOrgAdminReportsQuery
}

const LocationUsage: FC<LocationUsageProps> = ({ data }) => {
  const computedValues = useMemo(() => {
    if (data?.getLocationUsageReportData) {
      return [
        {
          fancyLabel: new Intl.DateTimeFormat('en-US', { month: "long" }).format(new Date(new Date().getFullYear(), new Date().getMonth() - 2)).toString(),
          data: [...Object.entries(data?.getLocationUsageReportData).map((pair: any) => {
            return pair[1].prevPrevMonth
          })],
          labels: Object.entries(data?.getLocationUsageReportData).map((pair: any) => {
            return pair[0] === "WFH" ? "Homeworking" : pair[1].locationName
          }),
          backgroundColor: Object.entries(data?.getLocationUsageReportData).map((pair: any) => {
            if (pair[0] === "WFH")
              return "#35c5af"

            return "#f030c1"
          })
        },
        {
          fancyLabel: new Intl.DateTimeFormat('en-US', { month: "long" }).format(new Date(new Date().getFullYear(), new Date().getMonth() - 1)).toString(),
          data: [...Object.entries(data?.getLocationUsageReportData).map((pair: any) => {
            return pair[1].prevMonth
          })],
          labels: Object.entries(data?.getLocationUsageReportData).map((pair: any) => {
            return pair[0] === "WFH" ? "Homeworking" : pair[1].locationName
          }),
          backgroundColor: Object.entries(data?.getLocationUsageReportData).map((pair: any) => {
            if (pair[0] === "WFH")
              return "#35c5af"

            return "#f030c1"
          })
        },
        {
          fancyLabel: new Intl.DateTimeFormat('en-US', { month: "long" }).format(new Date(new Date().getFullYear(), new Date().getMonth())).toString(),
          data: [...Object.entries(data?.getLocationUsageReportData).map((pair: any) => {
            return pair[1].currentMonth
          })],
          labels: Object.entries(data?.getLocationUsageReportData).map((pair: any) => {
            return pair[0] === "WFH" ? "Homeworking" : pair[1].locationName
          }),
          backgroundColor: Object.entries(data?.getLocationUsageReportData).map((pair: any) => {
            if (pair[0] === "WFH")
              return "#35c5af"

            return "#f030c1"
          })
        },
      ]
    }

    return []
  }, [data?.getLocationUsageReportData])

  return (
    <div className="location-usage-wrapper">
      <div className="pie-bullets">
        <div className="bullet">
          <div className="purple"></div>
          <span>Locations</span>
        </div>
        <div className="bullet">
          <div className="green"></div>
          <span>Home working</span>
        </div>
      </div>
      <div className="pies-group">
        <PieChart
          month={computedValues[0]?.fancyLabel || ""}
          data={{
            datasets: computedValues[0] ? [computedValues[0]] : [],
            labels: computedValues[0]?.labels,
          }} />
        <PieChart
          month={computedValues[1]?.fancyLabel || ""}
          data={{
            datasets: computedValues[1] ? [computedValues[1]] : [],
            labels: computedValues[1]?.labels,
          }} />
        <PieChart
          month={computedValues[2]?.fancyLabel || ""}
          data={{
            datasets: computedValues[2] ? [computedValues[2]] : [],
            labels: computedValues[2]?.labels,
          }} />
      </div>
    </div>
  )
}

export default LocationUsage
