export const checkinsConfig = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "top" as const,
      align: "end" as const,
      labels: {
        usePointStyle: true,
        color: "#000000",
        font: {
          size: 12,
          weight: "500",
          family: "Montserrat"
        }
      }
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        font: {
          size: 12,
          family: "Montserrat",
          weight: "500"
        },
        color: "#000000"
      }
    },
    y: {
      grid: {
        display: false
      },
      ticks: {
        maxTicksLimit: 5,
        stepSize: 20,
        color: "#000000",
        font: {
          size: 12,
          family: "Montserrat",
          weight: "500"
        }
      }
    }
  }
}

export const daysWorkedConfig = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      align: "end" as const,
      textAlign: "left",
      pointStyle: "circle",
      labels: {
        usePointStyle: true,
        padding: 10,
        boxWidth: 10,
        color: "#000000",
        font: {
          size: 12,
          weight: "500",
          family: "Montserrat"
        }
      }
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        font: {
          size: 12,
          family: "Montserrat",
          weight: "500"
        },
        color: "#000000"
      }
    },
    y: {
      grid: {
        display: false
      },
      ticks: {
        maxTicksLimit: 5,
        stepSize: 40,
        color: "#000000",
        font: {
          size: 12,
          family: "Montserrat",
          weight: "500"
        }
      }
    }
  }
}
