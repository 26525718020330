import { AuthState } from "../../context/auth/authProvider"

export const useHeader = () => {
  const { user, logout, selectedOrganisation, setSelectedOrganisation } = AuthState()

  const organisationOptions = user?.userAdministeredOrganisations?.length && 
    user?.userAdministeredOrganisations.map(
      (organisation: any) => (
        {
          label: organisation.displayName,
          value: organisation
        }
      ))

  const addSelectedOption = (name: string, newValue: {value: unknown}) => {
    setSelectedOrganisation(newValue?.value)
  } 

  return {
    user,
    logout,
    organisationOptions,
    selectedOrganisation,
    addSelectedOption
  }
}
