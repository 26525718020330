import "./styles.scss"
import type { FC } from "react"
import { CardProps } from "./types"

const Card: FC<CardProps> = ({
  children,
  width,
  height,
  minHeight,
  isSmall,
  isFlex,
}) => {
  return (
    <div
      style={{ width: width, height: height ? height : "auto", minHeight }}
      className={`card-wrapper ${isSmall ? "small-padding" : ""} ${
        isFlex ? "flex" : ""
      }`}
    >
      {children}
    </div>
  )
}

export default Card
