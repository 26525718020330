import { FC, useEffect, useState } from "react"
import { FieldProps } from "./types"
import "./styles.scss"

const Field: FC<FieldProps> = ({ name, type, label, value, handleChange, required = true }) => {
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    setInputValue(value || "")
  }, [value])

  return (
    <div className="form-control">
      <input
        type={type}
        name={name}
        value={inputValue}
        onChange={handleChange}
        className="form-input"
        placeholder="none"
        required={required}
      />
      <label htmlFor={name} className="form-label">
        {label}
      </label>
    </div>
  )
}

export default Field
