
import "./styles.scss"
import { AvatarBox, LoginForm } from "../../components/index"

function Login() {
  return (
    <div className="login-page-wrapper">
      <div className="login-page-content">
        <AvatarBox isLeft />
        <LoginForm />
        <AvatarBox />
      </div>
      <span className="tooltip">* You need to sign up via the Etho mobile app and be assigned as an admin at an organisation to use the web version of Etho</span>
      {/* <div className="help-text">Need help? Call +00 000 000 000.</div> */}
    </div>
  )
}

export default Login
