import "./styles.scss"
import { Pie } from "react-chartjs-2"
import { FC, useMemo } from "react"
import { Chart, ArcElement, Tooltip } from "chart.js"
import { pieChartConfig } from "./config"
import { PieChartProps } from "./types"
Chart.register(ArcElement, Tooltip)

const PieChart: FC<PieChartProps> = ({ data, month }) => {
  const shouldDisplayData = useMemo(() => {
    return !!data?.datasets?.[0]?.data?.filter(point => point).length
  }, [data])

  return (
    <div className="pie-wrapper">
      {shouldDisplayData && data ? <Pie data={data} options={pieChartConfig} /> : "No data to display"}
      <div className="month">{month}</div>
    </div>
  )
}

export default PieChart
