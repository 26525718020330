import Select from "react-select"
import { FC, useState, useEffect } from "react"
import { SelectFieldComponentsProps } from "./types"
import "./styles.scss"

const SelectField: FC<SelectFieldComponentsProps> = ({
  name,
  options,
  size,
  selectedValue,
  addSelectedOptions,
  addSelectedOption,
  label
}) => {
  const [selectedOption, setSelectedOption] = useState<any>()

  const handleChange = (newValue: any) => {
    addSelectedOptions && addSelectedOptions(name, newValue)
    addSelectedOption && addSelectedOption(newValue.id || newValue.value)
    setSelectedOption(newValue)
  }

  useEffect(() => {
    if (!options?.length) setSelectedOption("")
  }, [options])

  useEffect(() => {
    if (typeof selectedValue === "string" && !selectedValue) {
      setSelectedOption(null)
    }

    if (!selectedValue || !options) return
    setSelectedOption(
      options.find(
        (op: any) =>
          op.label === selectedValue ||
          op.value === selectedValue ||
          op.id === selectedValue
      )
    )
  }, [selectedValue, options])

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      height: "56px",
      zIndex: 1,
      border: state.isFocused ? "1px solid #1976D2" : "1px solid #daced4",
      borderRadius: "8px",
      "&:hover": {
        borderColor: "auto"
      }
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: "20"
    }),
    placeholder: (provided: any) => ({
      ...provided,
      paddingLeft: "5px",
      paddingRight: "5px"
    })
  }

  return (
    <div className={size === "small" ? "select-div small" : "select-div"}>
      <label className="label">{label}</label>
      <Select
        components={{
          IndicatorSeparator: () => null
        }}
        name={name}
        styles={customStyles}
        options={options}
        value={selectedOption}
        onChange={handleChange}
      />
    </div>
  )
}

export default SelectField
