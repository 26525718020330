import type { FC } from "react"
import { AvatarProps } from "./types"

const Avatar: FC<AvatarProps> = ({ size, src }) => {
  return (
    <div>
      <img
        alt="image"
        src={src}
        style={{ width: size, height: size, borderRadius: "100%" }}
      >
      </img>
    </div>
  )
}

Avatar.defaultProps = {
  size: "52px"
}

export default Avatar


