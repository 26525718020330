import GradientButton from "../../Buttons/GradientButton/GradientButton"
import SelectField from "../../SelectFieldComponent/SelectField"
import "../../Table/styles.scss"
import { useFormFields } from "./hooks"
import { useCallback, useEffect, useState, useRef } from "react"
import "./styles.scss"
import { useGetEthoOwnerReportDataLazyQuery } from "../../../graphql/graphql"
import { nextTick } from "process"

const GenerateReportForm = () => {
  // const [getReport, {data}] =
  const [selectedValue, setSelectedValue] = useState<any>();
  const [reportInQueue, setReportInQueue] = useState<boolean>(false);
  const [getReport, {data: reportData, loading: reportLoading, refetch}] = useGetEthoOwnerReportDataLazyQuery();
  const { primarySelectOptions } = useFormFields()
  const disabled = true

  const allOrganisationAreSelected = !!(selectedValue && selectedValue?.label === "All")
  var csvContentForAllOrganisations = useRef("")

  const handleChange = useCallback((inputName: string, selectedValue: any) => {
    setSelectedValue(selectedValue);
  }, [])

  const handleGenerateReport = () => {
    getReport({
      variables: {
        reportType: selectedValue.type,
        id: selectedValue.value
      }
    })
  }

  const handleClearSelection = () => {
    setTimeout(() => {
      setSelectedValue(null)
      setReportInQueue(false)
    }, 1000);
  }

  useEffect(() =>{
    if (reportData?.getEthoOwnerReportData) {
      setReportInQueue(true)
    }
  }, [reportData])

  const recursiveObjectParsing = (possibleObject:any) => {
      var valueString = '';
      if(typeof possibleObject !== 'object') {
        return `${
          (typeof possibleObject === 'number')
            && !Number.isInteger(possibleObject)
              ? possibleObject.toFixed(2)
              : possibleObject
        }\n`;
      }
      else {
        for (const [key,value] of Object.entries(possibleObject)){
          if(Array.isArray(possibleObject)) {
            return; // ignoring allResources due to high payload
            // possibleObject.forEach((resource) => {
            //   valueString += `Link : ${resource?.link}\n`
            //   valueString += `Time Displayed:${resource?.timesDisplayed}\n`
            //   valueString += `Click Through:${resource?.clickThroughs}\n`
            // })
          }
          else if(value !== undefined && !possibleObject.length){
            if(value === null) valueString += `${key.replace(',','')}:0\n`
            else if(typeof value === 'object') valueString += `${key.replace(',','')}:\n${recursiveObjectParsing(value)}`
            else valueString += `${key[0].toUpperCase() + key.substring(1).replace(/([a-z])([A-Z])/g, '$1 $2').replace(',','')}: ${recursiveObjectParsing(value)}`
          }
          else return valueString += "\n"
        }
        return valueString;
      }
  }

  const generateCsvString = (reportObject:any) => {
    let csvContent = ""
    if(!!reportObject)
    for (const [key, value] of Object.entries(reportObject?.getEthoOwnerReportData)) {
      csvContent += `${key[0].toUpperCase() + key.substring(1).replace(/([a-z])([A-Z])/g, '$1 $2')}\n` // title of the cell
      if(value !== (null && undefined)) csvContent += `${recursiveObjectParsing(value)}` // parsing values in case it is object
      else csvContent += `null\n`
      csvContent += `\n`
    }
    return csvContent
  }

  const handleCsvDownload = () => {
    var csvContentForOneOrganisation = "data:text/csv;charset=utf-8,"
    csvContentForOneOrganisation += generateCsvString(reportData)
    window.open(encodeURI(csvContentForOneOrganisation))
    handleClearSelection();
  }

  const handleGenerateAllReports = () => {
    console.log("ALL REPORTS TRIGGERED")
    csvContentForAllOrganisations.current += "data:text/csv;charset=utf-8,";

    getReport({
      variables: {
        reportType: primarySelectOptions[0].options[0].type,
        id: primarySelectOptions[0].options[0].value
      }
    }).then(res => {
      csvContentForAllOrganisations.current += `${primarySelectOptions[0]?.options[0]?.label}\n`
      csvContentForAllOrganisations.current += generateCsvString(res?.data)
      csvContentForAllOrganisations.current += `\n`
    })

    console.time("generate all reports")

    for(let i=1; i<primarySelectOptions[0].options.length; i++){
      if(primarySelectOptions[0].options[i].label!=="All"){
        refetch({
          reportType: primarySelectOptions[0].options[i].type,
          id: primarySelectOptions[0].options[i].value
        }).then(res => {
          console.log("ORGANISATION:", primarySelectOptions[0].options[i].label)
          csvContentForAllOrganisations.current += `${primarySelectOptions[0]?.options[i]?.label}\n`
          csvContentForAllOrganisations.current += generateCsvString(res.data)
          csvContentForAllOrganisations.current += `\n`
        })
      }
    }
    console.timeEnd("generate all reports")
  }

  const handleAllOrganisationsCsvDownload = () => {
    window.open(encodeURI(csvContentForAllOrganisations.current))
    handleClearSelection();
  }

  return (
    <div className="table-main generate-report-table">
      <div className="table-header">
        <span>Generate report</span>
        <GradientButton
        styles={{position: "relative"}}
        disabled={!selectedValue && disabled}
        onClick={
          (!reportInQueue &&
            (allOrganisationAreSelected
              ? handleGenerateAllReports
              : handleGenerateReport))
            ||
          ( allOrganisationAreSelected
            ? handleAllOrganisationsCsvDownload
            : handleCsvDownload )
        }>
          {
          reportLoading ? "Processing..." : reportInQueue ? "Download" : "Generate"
          }
        </GradientButton>
      </div>
      <div className="table-rows-container">
        <div className="table-row">
          <div className="table-row-cell">
            <SelectField
              selectedValue={selectedValue?.value || ""}
              name="Organisations, networks, or locations"
              label="Organisations, networks, or locations"
              options={primarySelectOptions}
              addSelectedOptions={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenerateReportForm
