import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js"
import type { FC } from "react"
import { Line } from "react-chartjs-2"
import "./styles.scss"
import { MultiAxisChartProps } from "./types"
import { multiAxisConfig } from "./config"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export const defaultData = {
  labels: ["Mar", "Apr", "May"],
  datasets: [
    {
      label: "Terrible",
      data: [171, -936, -390, 985, -272, 324, -165],
      borderColor: "#DC5A6D",
      backgroundColor: "#DC5A6D",
      yAxisID: "y"
    },
    {
      label: "Struggling",
      data: [427, -812, -560, 576, 713, 907, 323],
      borderColor: "#FF8383",
      backgroundColor: "#FF8383",
      yAxisID: "y1"
    },
    {
      label: "Coping",
      data: [686, 511, 360, -795, 889, -217, 1],
      borderColor: "#6D90B9",
      backgroundColor: "#6D90B9",
      yAxisID: "y1"
    },
    {
      label: "Good",
      data: [-830, -914, 593, 349, 689, 243, -16],
      borderColor: "#32C4C0",
      backgroundColor: "#32C4C0",
      yAxisID: "y1"
    },
    {
      label: "Great",
      data: [423, -159, -722, 80, 677, 727, -888],
      borderColor: "#30C67C",
      backgroundColor: "#30C67C",
      yAxisID: "y1"
    }
  ]
}

const MultiAxisChart: FC<MultiAxisChartProps> = ({ data }) => {
  return (
    <div className="multi-axis-wrapper">
      <Line options={multiAxisConfig} data={data ? data : defaultData} />
    </div>
  )
}

export default MultiAxisChart
