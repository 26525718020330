import {
  Organization,
  NetworkType,
  LocationType
} from "../../../pages/dashboard/Home/types"
import {
  useGetAllOrganisationsAndLocationsQuery,
  useGetNetworksQuery
} from "../../../graphql/graphql"

enum reportTypes {
  ORG = "ORGANISATION",
 NET = "NETWORK",
 LOC = "LOCATION"
}

export const useFormFields = () => {
  const { data } = useGetAllOrganisationsAndLocationsQuery()
  const { data: networksData } = useGetNetworksQuery()

  const allOption = {
    label : 'All',
    value : 'noid',
    type: reportTypes.ORG
  }

  const organisationOptions = data?.getOrganisations?.map(
    (org: Pick<Organization, "displayName" | "id">) => (
      {
      label: org?.displayName,
      value: org?.id,
      type: reportTypes.ORG
      }
    )
  )

  organisationOptions?.push(allOption)

  const locationOptions = data?.getLocations?.map(
    (location: Pick<LocationType, "displayName" | "id">) => ({
      label: location.displayName,
      value: location.id,
      type: reportTypes.LOC
    })
  )

  const networkOptions = networksData?.getNetworks?.map(
    (network: Pick<NetworkType, "id" | "name">) => ({
      label: network.name,
      value: network.id,
      type: reportTypes.NET
    })
  )

  const primarySelectOptions: any = [
    {
      label: "Organisations",
      options: organisationOptions
    },
    {
      label: "Networks",
      options: networkOptions
    },
    {
      label: "Locations",
      options: locationOptions
    }
  ]

  return { primarySelectOptions }
}
