import { Card, GradientButton, OutlineButton, Field } from "../../index"
import { useCreateNetworkModal } from "./hooks"
import Multiselect from "multiselect-react-dropdown"
import type { FC } from "react"
import { CreateNetworkModalProps } from "./types"
import "./styles.scss"
import { toast } from "react-toast"

const CreateNetworkModal: FC<CreateNetworkModalProps> = ({
  subnetworks,
  refetchNetworks,
  close
}) => {
  const {
    handleChange,
    newNetwork,
    selectedSubnetworks,
    handleRemove,
    handleSelect,
    submitForm,
  } = useCreateNetworkModal(subnetworks, refetchNetworks, toast)

  return (
    <div>
      <Card width="40vw">
        <div className="create-network">
          <div className="modal-title">Create Network Type</div>
          <div className="field-container">
            <Field
              handleChange={handleChange}
              name="networkName"
              value={newNetwork.networkName}
              type="text"
              label="Network Type Name"
            />
          </div>
          <div className="field-container">
            <div
              className="multi-select-container">
              <Multiselect
                placeholder="Add Network"
                options={subnetworks}
                selectedValues={selectedSubnetworks}
                onSelect={handleSelect}
                onRemove={handleRemove}
                displayValue="name"
              />
            </div>
          </div>
          <div className="buttons-group">
            <OutlineButton onClick={close} showIcon={false} width="50%">
              Cancel
            </OutlineButton>
            <GradientButton
              onClick={submitForm}
              type="submit"
              variant="green"
              width="50%"
            >
              Create Network Type
            </GradientButton>
          </div>
        </div>
         
      </Card>
    </div>
  )
}

export default CreateNetworkModal
