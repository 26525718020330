import { Card, GradientButton, Field, SelectField, OutlineButton } from "../../index"
import "./styles.scss"
import { useLocationForm } from "./hooks"
import Multiselect from "multiselect-react-dropdown"
import type { FC } from "react"
import { LocationFormProps } from "./types"
import { ToastContainer, toast } from "react-toast"

const LocationForm: FC<LocationFormProps> = ({
  refetch,
  isUpdateForm,
  updateLocationId,
  closeTab
}) => {
  const {
    simpleInputs,
    handleChange,
    addSelectedOption,
    locationType,
    organisationOptions,
    selectedOrganisations,
    handleSelect,
    handleRemove,
    submitForm,
    selectedInputs,
    handleDelete
  } = useLocationForm(refetch, toast, isUpdateForm, updateLocationId, closeTab)

  return (
    <div className="location-form-wrapper">
      <form>
        <Card width="100%">
          <div className="grid">
            <div>
              <div className="category-title">General information</div>
              <div className="inputs-wrapper">
                <Field
                  name="name"
                  value={simpleInputs.name}
                  handleChange={handleChange}
                  label={"Location name"}
                  type="text"
                />
              </div>
              <div className="inputs-wrapper">
                <Field
                  name="displayName"
                  value={simpleInputs.displayName}
                  handleChange={handleChange}
                  label={"Display name"}
                  type="text"
                />
              </div>

              <div className="multi-select-wrapper">
                <Multiselect
                  placeholder="Link organisation"
                  options={organisationOptions}
                  selectedValues={selectedOrganisations}
                  onSelect={handleSelect}
                  onRemove={handleRemove}
                  displayValue="name"
                />
              </div>

              <div>
                <SelectField
                  name={locationType.name}
                  options={locationType.options}
                  selectedValue={selectedInputs.locationType as string}
                  addSelectedOptions={addSelectedOption}
                  label="Location type"
                />
              </div>

              <div className="other-information">
                <div className="category-title">Other information</div>

                <div className="inputs-wrapper">
                  <Field
                    name="cqc"
                    value={simpleInputs.cqc}
                    handleChange={handleChange}
                    label={"CQC location ID"}
                    type="text"
                    required={false}
                  />
                </div>
                {isUpdateForm && <div className="inputs-wrapper">
                  <Field
                    name="uniqueIdentifier"
                    value={simpleInputs.uniqueIdentifier}
                    label={"Etho unique ID"}
                    type="text"
                  />
                </div>}
              </div>
            </div>
            <div>
              <div className="category-title">Geolocation information</div>
              <div className="inputs-wrapper">
                <Field
                  name="addressLine1"
                  value={simpleInputs.addressLine1}
                  handleChange={handleChange}
                  label={"Address line 1"}
                  type="text"
                />
              </div>
              <div className="inputs-wrapper">
                <Field
                  name="addressLine2"
                  value={simpleInputs.addressLine2}
                  handleChange={handleChange}
                  label={"Address line 2"}
                  type="text"
                />
              </div>
              <div className="inputs-wrapper">
                <Field
                  name="postCode"
                  value={simpleInputs.postCode}
                  handleChange={handleChange}
                  label={"Post code"}
                  type="text"
                />
              </div>

              <div className="inputs-wrapper">
                <Field
                  name="lat"
                  value={simpleInputs.lat}
                  handleChange={handleChange}
                  label={"Latitude"}
                  type="text"
                />
              </div>
              <div className="inputs-wrapper">
                <Field
                  name="long"
                  value={simpleInputs.long}
                  handleChange={handleChange}
                  label={"Longitude"}
                  type="text"
                />
              </div>
              <div className="inputs-wrapper">
                <Field
                  name="country"
                  value={simpleInputs.country}
                  handleChange={handleChange}
                  label={"Country"}
                  type="text"
                />
              </div>
              <div className="inputs-wrapper">
                <Field
                  name="county"
                  value={simpleInputs.county}
                  handleChange={handleChange}
                  label={"County"}
                  type="text"
                />
              </div>
              <div className="inputs-wrapper">
                <Field
                  name="city"
                  value={simpleInputs.city}
                  handleChange={handleChange}
                  label={"City"}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="create-location-button">
            {!isUpdateForm && <GradientButton onClick={submitForm} type="submit" width="100%">
              Create location
            </GradientButton>}

            {isUpdateForm && <div className="update">
              <GradientButton onClick={submitForm} type="submit" width="49%">
                Update location
              </GradientButton>
              <OutlineButton onClick={handleDelete} width="49%" showIcon={false}>
                Delete location
              </OutlineButton>
            </div>}
          </div>
        </Card>
      </form>
      <ToastContainer position="bottom-right" />
    </div>
  )
}

export default LocationForm
