import { useEffect, useState } from "react"
import { useCreateSubnetworkMutation } from "../../../graphql/graphql"

export const useCreatSubnetworkModal = (
  insertedNetworks: any,
  setInsertedNetworks: any,
  refetchSubNetworks: any,
  refetchNetworks: any,
  toast: (message: string) => void,
  close: () => void
) => {
  const [subnetwork, setSubnetwork] = useState<string>()
  const [subnetworkDescription, setSubnetworkDescription] = useState<string>()
  const [subnetworkOdsCode, setSubnetworkOdsCode] = useState<string>()

  // const [selectedOrganisations, setSelectedOrganisations] = useState([])
  const [selectedNetworks, setSelectedNetworks] = useState([])

  const [createSubnetwork, { data, error }] = useCreateSubnetworkMutation()

  const handleSubnetworkDescription = (e: { target: { name: any; value: any } }) => [
    setSubnetworkDescription(e.target.value)
  ]

  const handleSubnetworkOdsCode = (e: { target: { name: any; value: any } }) => [
    setSubnetworkOdsCode(e.target.value)
  ]

  const selectNetwork = (selectedList: any) => {    
    setSelectedNetworks([selectedList[selectedList.length - 1]] as any)
  }

  const removeNetwork = (selectedList: any) => {
    setSelectedNetworks(selectedList)
  }

  // const selectOrganisation = (selectedList: any) => {
  //   setSelectedOrganisations(selectedList)
  // }

  // const removeOrganisation = (selectedList: any) => {
  //   setSelectedOrganisations(selectedList)
  // }

  useEffect(() => {
    if (data?.createSubnetwork) {
      //get networks ids from the CreatedSubnetwork
      //get subnetworkname and id to populate extra option in the Network select field
      const networks = data?.createSubnetwork?.networks
      const subNetworkId = data?.createSubnetwork.id
      const subNetworkName = data?.createSubnetwork.name
      const matchingInsertedNetworks = networks?.filter((network) => {
        const foundMatches = insertedNetworks.filter((match: { id: string }) => {
          return network.id === match.id
        })

        return foundMatches.length > 0
      })

      //for each existent Network Select Field in the UI, add the subnetwork extra option with the created subnetwork
      matchingInsertedNetworks?.forEach((matchedNetwork) => {
        setInsertedNetworks((prevState: any) => {
          const option = {
            value: subNetworkId,
            label: subNetworkName,
            key: subNetworkId,
          }
          return prevState.map((item: any) => {
            if (item.id === matchedNetwork.id) {
              return {
                id: matchedNetwork.id,
                name: matchedNetwork.name,
                options: [...item.options, option],
              }
            } else {
              return item
            }
          })
        })
      })
      refetchSubNetworks()
      refetchNetworks()
      setSubnetwork("")
      setSelectedNetworks([])
      close()
      toast("Create successful!")
    }
    // setSelectedOrganisations([])
  }, [data?.createSubnetwork])

  useEffect(() => {
    const errorMessage = error?.message;
    if (errorMessage && errorMessage?.includes("400")) {
      toast("Please make sure to fill all required fields.")
    }
    if (errorMessage && errorMessage?.includes("401")) { 
      toast("Make sure you are logged in.")
    }
  }, [error])

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    createSubnetwork({
      variables: {
        name: subnetwork,
        networkIds: selectedNetworks.map(
          (network: { id: string }) => network.id
        ),
        description: subnetworkDescription,
        odsCode: subnetworkOdsCode,
        // organisationIds: selectedOrganisations.map(
        //   (organisation: { id: string }) => organisation.id
        // ),
      },
    })
  }

  return {
    subnetwork,
    setSubnetwork,
    selectNetwork,
    removeNetwork,
    handleSubnetworkDescription,
    handleSubnetworkOdsCode,
    subnetworkDescription,
    subnetworkOdsCode,
    // selectOrganisation,
    // removeOrganisation,
    // selectedOrganisations,
    selectedNetworks,
    submitForm,
  }
}
