export const computeTimeDiff = (startTime: string) => {
  const startDate = new Date(startTime)
  const endDate = new Date()
  let diff = endDate.getTime() - startDate.getTime()
  let hours = Math.floor(diff / 1000 / 60 / 60)
  diff -= hours * 1000 * 60 * 60
  const minutes = Math.floor(diff / 1000 / 60)
  const seconds = Math.floor(diff / 1000) -  (60 * minutes)
  
  // If using time pickers with 24 hours format, add the below line get exact hours
  if (hours < 0)  {
    hours = hours + 24
  }    
  return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes + ":" + (seconds <= 9 ? "0" : "") + seconds
}