import { FC, useMemo } from "react"
import { StackedBarChart } from "../../../../../components"
import { GetOrgAdminReportsQuery } from "../../../../../graphql/graphql"

interface MoodScoresProps {
  data: GetOrgAdminReportsQuery
}


const MoodScores: FC<MoodScoresProps> = ({ data }) => {
  const chartData = useMemo(() => {
    if (data) console.log(data?.getDailyBreakdownReportData?.map((entry: any) => entry))
    if (data?.getDailyBreakdownReportData) {
      return ({
        labels: new Array(data?.getDailyBreakdownReportData.length - 1).fill(0).map((v, index) => index.toString()),
        datasets: [
          {
            label: "Skipped",
            data: data?.getDailyBreakdownReportData?.map((entry: any) => {
              if (entry) {
                let thresholdSum: number = Object.values(entry).reduce((partialSum: number, a: any) => partialSum + a, 0)
                if (thresholdSum >= 6) return entry.skipped
              }
            }) || [],
            backgroundColor: "#DFDFDF"
          },
          {
            label: "Terrible",
            data: data?.getDailyBreakdownReportData?.map((entry: any) => {
              if (entry) {
                let thresholdSum: number = Object.values(entry).reduce((partialSum: number, a: any) => partialSum + a, 0)
                if (thresholdSum >= 6) return entry.terrible
              }
            }) || [],
            backgroundColor: "#DC5A6D"
          },
          {
            label: "Struggling",
            data: data?.getDailyBreakdownReportData?.map((entry: any) => {
              if (entry) {
                let thresholdSum: number = Object.values(entry).reduce((partialSum: number, a: any) => partialSum + a, 0)
                if (thresholdSum >= 6) return entry.struggling
              }
            }) || [],
            backgroundColor: "#FF8383"
          },
          {
            label: "Coping",
            data: data?.getDailyBreakdownReportData?.map((entry: any) => {
              if (entry) {
                let thresholdSum: number = Object.values(entry).reduce((partialSum: number, a: any) => partialSum + a, 0)
                if (thresholdSum >= 6) return entry.coping
              }
            }) || [],
            backgroundColor: "#6D90B9"
          },
          {
            label: "Good",
            data: data?.getDailyBreakdownReportData?.map((entry: any) => {
              if (entry) {
                let thresholdSum: number = Object.values(entry).reduce((partialSum: number, a: any) => partialSum + a, 0)
                if (thresholdSum >= 6) return entry.good
              }
            }) || [],
            backgroundColor: "#32C4C0"
          },
          {
            label: "Great",
            data: data?.getDailyBreakdownReportData?.map((entry: any) => {
              if (entry) {
                let thresholdSum: number = Object.values(entry).reduce((partialSum: number, a: any) => partialSum + a, 0)
                if (thresholdSum >= 6) return entry.great
              }
            }) || [],
            backgroundColor: "#30C67C"
          }
        ]
      })
    } else return undefined
  }, [data?.getDailyBreakdownReportData])

  return <StackedBarChart data={chartData} />
}

export default MoodScores
