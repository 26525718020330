import { useHomePage } from "./hooks"
import { Card, GenerateReportForm, Loader } from "../../../components/"
import NetworksIcon from "../../../assets/NetworksIcon"
import AddressIcon from "../../../assets/AddressIcon"

const OrganisationAdminHome = ({ user }: { user: any }) => {
  const { locations, organization, address, networksDataObject } = useHomePage()
  return (
    <div className="home-page-wrapper">
      {user && user?.userAdministeredOrganisations ? (
        <>
          <div className="card-category">
            <div className="category-title">General Info</div>
            <Card minHeight="4.875em">
              <div className="row">
                <div className="row-item">
                  <div className="field-label">Display Name</div>
                  <div className="field-name">{organization?.displayName}</div>
                </div>
                <div className="row-item">
                  <div className="field-label ">Provider Name</div>
                  <div className="field-name">{organization?.providerName}</div>
                </div>
                <div className="row-item">
                  <div className="field-label ">Organisation Type</div>
                  <div className="field-name">{organization?.type?.replaceAll("_", " ")}</div>
                </div>
                <div className="row-item">
                  <div className="field-label ">ODS Code</div>
                  <div className="field-name">{organization?.odsCode}</div>
                </div>
              </div>
            </Card>
          </div>

          <div className="card-category">
            <div className="category-title">Address</div>
            <Card minHeight="4.875em">
              <div className="row">
                <div className="row-item">
                  <div className="field-label">Address Line 1</div>
                  <div className="field-name">{address?.addressLine.split(";")[0]}</div>
                </div>
                {
                  address?.addressLine.split(";")[1] && <div className="row-item">
                    <div className="field-label">Address Line 2</div>
                    <div className="field-name">{address?.addressLine.split(";")[1]}</div>
                  </div>
                }
                <div className="row-item">
                  <div className="field-label">City</div>
                  <div className="field-name">{address?.city}</div>
                </div>
                <div className="row-item">
                  <div className="field-label">Postcode</div>
                  <div className="field-name">{address?.postcode}</div>
                </div>
                <div className="row-item">
                  <div className="field-label">County</div>
                  <div className="field-name">{address?.county}</div>
                </div>
              </div>
            </Card>
          </div>

          <div className="card-category">
            <div className="category-title">Locations</div>

            <Card minHeight="4.875em">
              <div className="grid-card">
                {
                  locations?.length ?
                    (
                      locations?.map((location, index) =>
                        <div
                          className="icon-group"
                          key={String(location.id) + index}>
                          <AddressIcon />

                          <div className="row-item">
                            <div className="field-name">
                              {location.displayName}
                            </div>

                            <div className="field-label ">
                              {location.address.addressLine}
                            </div>
                          </div>
                        </div>)
                    )
                    : <div className="card-no-data">No data to display</div>}
              </div>
            </Card>
          </div>

          <div className="card-category">
            <div className="category-title">Networks</div>
            <Card minHeight="4.875em">
              <div className="grid-card">
                {networksDataObject?.length ? (
                  networksDataObject?.map((subnetworkObject, index) =>
                    subnetworkObject.networks.map((network: any) =>
                      <div
                        className="icon-group"
                        key={String(network.id) + index}
                      >
                        <NetworksIcon />
                        <div className="row-item">
                          <div className="field-name">{network.name}</div>
                          <div className="field-label ">
                            {subnetworkObject.subnetworkName}
                          </div>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div className="card-no-data">No data to display</div>
                )}
              </div>
            </Card>
          </div>
          {/* <GenerateReportForm/> */}
          <div className="help-text">
            Contact Etho at admin@etho.co.uk if you wish to edit your
            organisation.
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default OrganisationAdminHome
