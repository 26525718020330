import "./styles.scss"
import NetworksIcon from "../../assets/NetworksIcon"
import type { FC } from "react"
import { NetworkProps } from "./types"

const Network: FC<NetworkProps> = ({name, onClick}) => {
  return (
    <div className="network-wrapper" onClick={onClick}>
      <NetworksIcon width={"17"} height={"17"}/>
      <div>{name}</div>
    </div>
  )
}

export default Network