import { Outlet } from "react-router-dom"
import { Sidebar, Header } from "../"
import "./styles.scss"

function SharedLayout() {

  return (
    <div className="shared-layout-wrapper">
      <main className="dashboard">
        <Sidebar />
        <div>
          <Header />
          <div className="dashboard-page">
            <Outlet />
          </div>
        </div>
      </main>

    </div>
  )
}

export default SharedLayout
