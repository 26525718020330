import { Card, GradientButton, OutlineButton, Field } from "../../index"
import { useCreatSubnetworkModal } from "./hooks"
import type { FC } from "react"
import { CreateSubNetworkModalProps } from "./types"
import Multiselect from "multiselect-react-dropdown"
import "./styles.scss"
import { toast } from "react-toast"

const CreateSubnetworkModal: FC<CreateSubNetworkModalProps> = ({
  networkOptions,
  // organisationOptions,
  insertedNetworks,
  setInsertedNetworks,
  refetchSubNetworks,
  refetchNetworks,
  close
}) => {
  const {
    setSubnetwork,
    subnetwork,
    selectNetwork,
    removeNetwork,
    handleSubnetworkDescription,
    handleSubnetworkOdsCode,
    subnetworkDescription,
    subnetworkOdsCode,
    selectedNetworks,
    submitForm,
  } = useCreatSubnetworkModal(insertedNetworks, setInsertedNetworks, refetchSubNetworks, refetchNetworks, toast, close)

  return (
    <div>
      <Card width="40vw">
        <div className="create-subnetwork">
          <div className="modal-title">Create Network</div>
          <div className="field-container">
            <Field
              handleChange={(e) => setSubnetwork(e.target.value)}
              name="networkName"
              value={subnetwork}
              type="text"
              label="Name"
            />
          </div>

          <div className="field-container">
            <Field
              name="description"
              type="text"
              handleChange={handleSubnetworkDescription}
              value={subnetworkDescription}
              label="Description"
            />
          </div>
          <div className="field-container">
            <Field
                name="odsCode"
                type="text"
                handleChange={handleSubnetworkOdsCode}
                value={subnetworkOdsCode}
                label="ODS Code"
            />
          </div>

          <div style={{ marginTop: "40px" }}>
            <div className="multi-select-container">
              <Multiselect
                placeholder="Network Type"
                options={networkOptions}
                selectedValues={selectedNetworks}
                onSelect={selectNetwork}
                onRemove={removeNetwork}
                displayValue="name"
              />
            </div>
          </div>

          <div className="buttons-group">
            <OutlineButton onClick={close} showIcon={false} width="50%">
                Cancel
            </OutlineButton>
            <GradientButton
              type="submit"
              onClick={submitForm}
              variant="green"
              width="50%"
            >
                Create Network
            </GradientButton>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default CreateSubnetworkModal
