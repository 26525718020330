import React from "react"

function LocationIcon() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1568 14.8822L13.9698 16.0561C13.0949 16.9148 11.9598 18.0187 10.5639 19.3683C9.69169 20.2117 8.30787 20.2115 7.43576 19.3681L3.94466 15.9722C3.5059 15.5414 3.13863 15.1781 2.84279 14.8822C-0.557622 11.4818 -0.557622 5.96866 2.84279 2.56825C6.2432 -0.832158 11.7564 -0.832158 15.1568 2.56825C18.5572 5.96866 18.5572 11.4818 15.1568 14.8822ZM11.5 8.99994C11.5 7.61912 10.3806 6.49974 8.99978 6.49974C7.61895 6.49974 6.49958 7.61912 6.49958 8.99994C6.49958 10.3808 7.61895 11.5001 8.99978 11.5001C10.3806 11.5001 11.5 10.3808 11.5 8.99994Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default LocationIcon
