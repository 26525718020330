import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Literally any */
  AnyGraphQLType: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** Non empty string */
  NonEmptyString: any;
};

export type AccessTokens = {
  __typename?: 'AccessTokens';
  accessToken: Scalars['NonEmptyString'];
  refreshToken: Scalars['NonEmptyString'];
};

export type Address = {
  __typename?: 'Address';
  addressLine: Scalars['NonEmptyString'];
  city: Scalars['NonEmptyString'];
  country: Scalars['NonEmptyString'];
  county: Scalars['NonEmptyString'];
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lat: Scalars['Float'];
  locations?: Maybe<Array<Location>>;
  long: Scalars['Float'];
  postcode: Scalars['NonEmptyString'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type AttemptLoginResponse = {
  __typename?: 'AttemptLoginResponse';
  message: Scalars['NonEmptyString'];
};

export type Attendance = {
  __typename?: 'Attendance';
  created_at?: Maybe<Scalars['DateTime']>;
  endTimestamp?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  location?: Maybe<Location>;
  member?: Maybe<Member>;
  organisation?: Maybe<Organisation>;
  score?: Maybe<Score>;
  startTimestamp: Scalars['DateTime'];
  status: AttendanceStatus;
  type: AttendanceType;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum AttendanceStatus {
  ClockedOut = 'CLOCKED_OUT',
  InProgress = 'IN_PROGRESS'
}

export enum AttendanceType {
  NotWorking = 'NOT_WORKING',
  OnSite = 'ON_SITE',
  Wfh = 'WFH'
}

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  message: Scalars['NonEmptyString'];
};

export type DisplayVocation = {
  __typename?: 'DisplayVocation';
  displayVocation: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Address>;
  cqcLocationId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['NonEmptyString'];
  ethoUniqueId?: Maybe<Scalars['NonEmptyString']>;
  id: Scalars['ID'];
  locationType: Scalars['NonEmptyString'];
  name: Scalars['NonEmptyString'];
  ongoingAttendances?: Maybe<Array<Attendance>>;
  organisations?: Maybe<Array<Organisation>>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum LocationType {
  CommunityHospitalWard = 'Community_Hospital_ward',
  CommunityPharmacy = 'Community_Pharmacy',
  GpBranchSurgery = 'GP_Branch_Surgery',
  GpSurgery = 'GP_Surgery',
  Miiu = 'MIIU',
  NursingHome = 'Nursing_Home',
  Office = 'Office',
  ResidentialCareHome = 'Residential_Care_Home',
  SharedClinicalHub = 'Shared_Clinical_Hub'
}

export type LogoutUserResponse = {
  __typename?: 'LogoutUserResponse';
  message: Scalars['NonEmptyString'];
};

export type Member = {
  __typename?: 'Member';
  approved?: Maybe<Scalars['Boolean']>;
  attendances?: Maybe<Array<Attendance>>;
  contributeToPracticeScore: Scalars['Boolean'];
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  notificationsAboutMe?: Maybe<Array<Notification>>;
  notificationsWhereImWbc?: Maybe<Array<Notification>>;
  organisation?: Maybe<Organisation>;
  primaryRole: PrimaryRole;
  scores?: Maybe<Array<Score>>;
  secondaryRole: SecondaryRole;
  shareMyScores: Scalars['Boolean'];
  updated_at?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  vocationSpeciality?: Maybe<VocationSpeciality>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AnswerAcknowledgeChangeNotification: Notification;
  AnswerUserRecievesSupportNotification: Notification;
  AnswerWbcOffersSupportNotification: Notification;
  approveMember: Member;
  attemptLogin: AttemptLoginResponse;
  attemptPhoneNumberChange: CreateUserResponse;
  checkMyselfIn: Attendance;
  checkMyselfInGuest: Attendance;
  checkMyselfOut: Attendance;
  checkMyselfOutGuest: Attendance;
  createLocation: Location;
  createMember: Member;
  createNetwork: Network;
  createOrganisation: Organisation;
  createResource: Resource;
  createSubnetwork: Subnetwork;
  createUser: CreateUserResponse;
  deleteLocation: Location;
  deleteMember: Member;
  deleteOrganisation: Organisation;
  deleteSubnetwork: Subnetwork;
  deleteUser: User;
  denyApprovalToMember: Member;
  generateOrgAdminPromoteCode: OrganisationInviteCode;
  generateWbcPromoteCode: OrganisationInviteCode;
  getRefreshToken: AccessTokens;
  increaseClickThroughOnResource: Resource;
  logoutUser: LogoutUserResponse;
  promoteSelfMemberViaCode: Member;
  revokeRoles: Member;
  updateLocation: Location;
  updateMember: Member;
  updateNetwork: Network;
  updateOrganisation: Organisation;
  updateSelfMember: Member;
  updateSelfUser: User;
  updateSubnetwork: Subnetwork;
  updateUserById: User;
  verifyPhoneNumberChangeAttempt: User;
};


export type MutationAnswerAcknowledgeChangeNotificationArgs = {
  notificationAnswer: NotificationResponseVariant;
  notificationId: Scalars['ID'];
};


export type MutationAnswerUserRecievesSupportNotificationArgs = {
  notificationAnswer: NotificationResponseVariant;
  notificationId: Scalars['ID'];
};


export type MutationAnswerWbcOffersSupportNotificationArgs = {
  notificationAnswer: NotificationResponseVariant;
  notificationId: Scalars['ID'];
};


export type MutationApproveMemberArgs = {
  id: Scalars['ID'];
};


export type MutationAttemptLoginArgs = {
  phoneNumber: Scalars['NonEmptyString'];
  userAgent?: InputMaybe<Scalars['String']>;
};


export type MutationAttemptPhoneNumberChangeArgs = {
  phoneNumber: Scalars['NonEmptyString'];
};


export type MutationCheckMyselfInArgs = {
  checkInScore?: InputMaybe<Scalars['Float']>;
  locationId?: InputMaybe<Scalars['ID']>;
  organisationId: Scalars['ID'];
  type: AttendanceType;
};


export type MutationCheckMyselfInGuestArgs = {
  company: Scalars['NonEmptyString'];
  firstName: Scalars['NonEmptyString'];
  lastName: Scalars['NonEmptyString'];
  locationUniqueId: Scalars['NonEmptyString'];
  organisationUniqueId: Scalars['NonEmptyString'];
};


export type MutationCheckMyselfOutArgs = {
  checkOutPhysicalScore?: InputMaybe<Scalars['Float']>;
  checkOutScore?: InputMaybe<Scalars['Float']>;
};


export type MutationCheckMyselfOutGuestArgs = {
  attendanceId: Scalars['ID'];
};


export type MutationCreateLocationArgs = {
  addressLine: Scalars['NonEmptyString'];
  city: Scalars['NonEmptyString'];
  country: Scalars['NonEmptyString'];
  county: Scalars['NonEmptyString'];
  cqcLocationId?: InputMaybe<Scalars['String']>;
  displayName: Scalars['NonEmptyString'];
  lat: Scalars['Float'];
  locationType: LocationType;
  long: Scalars['Float'];
  name: Scalars['NonEmptyString'];
  organisationIds?: InputMaybe<Array<Scalars['ID']>>;
  postcode: Scalars['NonEmptyString'];
};


export type MutationCreateMemberArgs = {
  contributeToPracticeScore?: InputMaybe<Scalars['Boolean']>;
  organisationUniqueInviteCode: Scalars['ID'];
  shareMyScores?: InputMaybe<Scalars['Boolean']>;
  vocationSpecialityId: Scalars['ID'];
};


export type MutationCreateNetworkArgs = {
  name: Scalars['NonEmptyString'];
  subnetworkIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationCreateOrganisationArgs = {
  adminContributeToPracticeScore: Scalars['Boolean'];
  adminDataSharingConsent: Scalars['Boolean'];
  adminEmail: Scalars['NonEmptyString'];
  adminFirstName: Scalars['NonEmptyString'];
  adminLastName: Scalars['NonEmptyString'];
  adminMemberVocationSpecialityId: Scalars['ID'];
  adminPhone: Scalars['NonEmptyString'];
  adminShareMyScores: Scalars['Boolean'];
  adminVocationId: Scalars['ID'];
  cqcLocationId?: InputMaybe<Scalars['String']>;
  displayName: Scalars['NonEmptyString'];
  locationIds?: InputMaybe<Array<Scalars['ID']>>;
  mainAddressId?: InputMaybe<Scalars['ID']>;
  odsCode?: InputMaybe<Scalars['String']>;
  providerName: Scalars['NonEmptyString'];
  subnetworkIds?: InputMaybe<Array<Scalars['ID']>>;
  type: OrganisationType;
};


export type MutationCreateResourceArgs = {
  description: Scalars['NonEmptyString'];
  link: Scalars['NonEmptyString'];
  s3PicLink: Scalars['NonEmptyString'];
  vocationGroupRelevanceScores: Scalars['AnyGraphQLType'];
};


export type MutationCreateSubnetworkArgs = {
  description: Scalars['NonEmptyString'];
  name: Scalars['NonEmptyString'];
  networkIds: Array<Scalars['ID']>;
  odsCode?: InputMaybe<Scalars['String']>;
  organisationIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserArgs = {
  dataSharingConsent?: InputMaybe<Scalars['Boolean']>;
  firstName: Scalars['NonEmptyString'];
  lastName: Scalars['NonEmptyString'];
  phoneNumber: Scalars['NonEmptyString'];
  vocationId: Scalars['ID'];
};


export type MutationDeleteLocationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMemberArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganisationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSubnetworkArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDenyApprovalToMemberArgs = {
  id: Scalars['ID'];
};


export type MutationGenerateOrgAdminPromoteCodeArgs = {
  organisationToGenerateForId: Scalars['ID'];
};


export type MutationGenerateWbcPromoteCodeArgs = {
  organisationToGenerateForId: Scalars['ID'];
};


export type MutationGetRefreshTokenArgs = {
  refreshToken: Scalars['NonEmptyString'];
};


export type MutationIncreaseClickThroughOnResourceArgs = {
  id: Scalars['ID'];
};


export type MutationPromoteSelfMemberViaCodeArgs = {
  code: Scalars['NonEmptyString'];
};


export type MutationRevokeRolesArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateLocationArgs = {
  addressLine?: InputMaybe<Scalars['NonEmptyString']>;
  city?: InputMaybe<Scalars['NonEmptyString']>;
  country?: InputMaybe<Scalars['NonEmptyString']>;
  county?: InputMaybe<Scalars['NonEmptyString']>;
  cqcLocationId?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['NonEmptyString']>;
  id: Scalars['ID'];
  lat?: InputMaybe<Scalars['Float']>;
  locationType?: InputMaybe<LocationType>;
  long?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['NonEmptyString']>;
  organisationIds?: InputMaybe<Array<Scalars['ID']>>;
  postcode?: InputMaybe<Scalars['NonEmptyString']>;
};


export type MutationUpdateMemberArgs = {
  contributeToPracticeScore?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  shareMyScores?: InputMaybe<Scalars['Boolean']>;
  vocationSpecialityId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateNetworkArgs = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['NonEmptyString']>;
  subnetworkIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationUpdateOrganisationArgs = {
  adminContributeToPracticeScore?: InputMaybe<Scalars['Boolean']>;
  adminDataSharingConsent?: InputMaybe<Scalars['Boolean']>;
  adminEmail?: InputMaybe<Scalars['NonEmptyString']>;
  adminFirstName?: InputMaybe<Scalars['NonEmptyString']>;
  adminLastName?: InputMaybe<Scalars['NonEmptyString']>;
  adminMemberVocationSpecialityId?: InputMaybe<Scalars['ID']>;
  adminPhone?: InputMaybe<Scalars['NonEmptyString']>;
  adminShareMyScores?: InputMaybe<Scalars['Boolean']>;
  adminVocationId?: InputMaybe<Scalars['ID']>;
  cqcLocationId?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['NonEmptyString']>;
  id: Scalars['ID'];
  locationIds?: InputMaybe<Array<Scalars['ID']>>;
  mainAddressId?: InputMaybe<Scalars['ID']>;
  odsCode?: InputMaybe<Scalars['String']>;
  providerName?: InputMaybe<Scalars['NonEmptyString']>;
  subnetworkIds?: InputMaybe<Array<Scalars['ID']>>;
  type?: InputMaybe<OrganisationType>;
};


export type MutationUpdateSelfMemberArgs = {
  contributeToPracticeScore?: InputMaybe<Scalars['Boolean']>;
  organisationId: Scalars['ID'];
  primaryRole?: InputMaybe<PrimaryRole>;
  secondaryRole?: InputMaybe<SecondaryRole>;
  shareMyScores?: InputMaybe<Scalars['Boolean']>;
  vocationSpecialityId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateSelfUserArgs = {
  dataSharingConsent?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['NonEmptyString']>;
  firstName?: InputMaybe<Scalars['NonEmptyString']>;
  lastName?: InputMaybe<Scalars['NonEmptyString']>;
  phoneNumber?: InputMaybe<Scalars['NonEmptyString']>;
  profilePicURL?: InputMaybe<Scalars['NonEmptyString']>;
  vocationId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateSubnetworkArgs = {
  description: Scalars['NonEmptyString'];
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['NonEmptyString']>;
  networkIds?: InputMaybe<Array<Scalars['ID']>>;
  odsCode?: InputMaybe<Scalars['String']>;
  organisationIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationUpdateUserByIdArgs = {
  dataSharingConsent?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['NonEmptyString']>;
  firstName?: InputMaybe<Scalars['NonEmptyString']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['NonEmptyString']>;
  phoneNumber?: InputMaybe<Scalars['NonEmptyString']>;
  profilePicURL?: InputMaybe<Scalars['NonEmptyString']>;
  vocationId?: InputMaybe<Scalars['ID']>;
};


export type MutationVerifyPhoneNumberChangeAttemptArgs = {
  otp: Scalars['NonEmptyString'];
};

export type Network = {
  __typename?: 'Network';
  id: Scalars['ID'];
  name: Scalars['NonEmptyString'];
  subnetworks?: Maybe<Array<Subnetwork>>;
};

export type Notification = {
  __typename?: 'Notification';
  concluded: Scalars['Boolean'];
  contextMember?: Maybe<Member>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  organisationId: Scalars['String'];
  responseVariantsChosen: Array<NotificationResponseVariant>;
  shouldBeDisplayed?: Maybe<Scalars['Boolean']>;
  shouldRefire: Scalars['Boolean'];
  targetMember?: Maybe<Member>;
  type: NotificationType;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum NotificationResponseVariant {
  AcknowledgeChange = 'ACKNOWLEDGE_CHANGE',
  Close = 'CLOSE',
  MarkAsResolved = 'MARK_AS_RESOLVED',
  NotYet = 'NOT_YET',
  Yes = 'YES'
}

export enum NotificationType {
  AcknowledgeChange = 'ACKNOWLEDGE_CHANGE',
  UserRecievesSupport = 'USER_RECIEVES_SUPPORT',
  WbcOffersSupport = 'WBC_OFFERS_SUPPORT'
}

export type Organisation = {
  __typename?: 'Organisation';
  admins?: Maybe<Array<Member>>;
  attendances?: Maybe<Array<Attendance>>;
  cqcLocationId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  inviteCodes?: Maybe<Array<OrganisationInviteCode>>;
  locations?: Maybe<Array<Location>>;
  mainAddress?: Maybe<Address>;
  members?: Maybe<Array<Member>>;
  odsCode?: Maybe<Scalars['String']>;
  primaryAdmin?: Maybe<Member>;
  providerName: Scalars['String'];
  subnetworks?: Maybe<Array<Subnetwork>>;
  type: OrganisationType;
  uniqueInviteCode: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  wbcs?: Maybe<Array<Member>>;
};

export type OrganisationInviteCode = {
  __typename?: 'OrganisationInviteCode';
  code: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  organisation?: Maybe<Organisation>;
  targetRole: OrganisationInviteCodeTargetRole;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum OrganisationInviteCodeTargetRole {
  OrgAdmin = 'ORG_ADMIN',
  Wbc = 'WBC'
}

export enum OrganisationType {
  CommunityCareProvider = 'Community_Care_Provider',
  CountyCouncil = 'County_Council',
  GpPractice = 'GP_Practice',
  HospitalDepartment = 'Hospital_Department',
  IntegratedCareBoard = 'Integrated_Care_Board',
  LocalMedicalCommittee = 'Local_Medical_Committee',
  LtdCompany = 'Ltd_Company',
  NhsFoundationTrust = 'NHS_Foundation_Trust',
  NationalNhsBody = 'National_NHS_body',
  PeerSupportGroup = 'Peer_Support_Group',
  PrimaryCareNetwork = 'Primary_Care_Network',
  ResidentialCareProvider = 'Residential_Care_Provider'
}

export enum PrimaryRole {
  OrgAdmin = 'ORG_ADMIN',
  User = 'USER'
}

export type Query = {
  __typename?: 'Query';
  getActiveTeamCheckInForLocationReportData: Scalars['AnyGraphQLType'];
  getActiveTeamCheckInForVocationGroupReportData: Scalars['AnyGraphQLType'];
  getAdminsList: Array<Member>;
  getCurrentOrganisationTeamMoraleReportData: TeamMoraleReportData;
  getCurrentTotalDaysWorkedReportData: Scalars['AnyGraphQLType'];
  getDailyBreakdownReportData: Scalars['AnyGraphQLType'];
  getEthoOwnerReportData: Scalars['AnyGraphQLType'];
  getLastUpdatedAttendanceToday?: Maybe<Attendance>;
  getLocation: Location;
  getLocationByUniqueId: Location;
  getLocationUsageReportData: Scalars['AnyGraphQLType'];
  getLocations: Array<Location>;
  getMember: Member;
  getMemberDisplayVocation: Array<DisplayVocation>;
  getMembers: Array<Member>;
  getMembersByOrganisation: Array<Member>;
  getMyMembers: Array<Member>;
  getMyOngoingAttendance?: Maybe<Attendance>;
  getNetwork: Network;
  getNetworks: Array<Network>;
  getOneMonthOfAttendances: Array<Attendance>;
  getOngoingOnSiteAttendancesAtLocationFromOrganisation: Array<Attendance>;
  getOrgAdminPromoteCodesInOrganisation: Array<OrganisationInviteCode>;
  getOrganisation: Organisation;
  getOrganisationByUniqueInviteCode: Organisation;
  getOrganisations: Array<Organisation>;
  getOrganisationsWhereImOrgAdmin: Array<Organisation>;
  getResourcesOrderedByRelevanceToVocationGroup: Array<Resource>;
  getStickyReportData: Scalars['AnyGraphQLType'];
  getSubnetwork: Subnetwork;
  getSubnetworks: Array<Subnetwork>;
  getUnapprovedMembersOfOrganisation: Array<Member>;
  getUser: User;
  getUsers: Array<User>;
  getVocationGroups: Array<VocationGroup>;
  getVocations: Array<Vocation>;
  getWBCList: Array<Member>;
  getWbcPromoteCodesInOrganisation: Array<OrganisationInviteCode>;
  verifyLogin: AccessTokens;
  verifyRegistration: UserAndAccessTokens;
};


export type QueryGetActiveTeamCheckInForLocationReportDataArgs = {
  locationId: Scalars['ID'];
  organisationId: Scalars['ID'];
};


export type QueryGetActiveTeamCheckInForVocationGroupReportDataArgs = {
  organisationId: Scalars['ID'];
  vocationGroupId: Scalars['ID'];
};


export type QueryGetAdminsListArgs = {
  id: Scalars['ID'];
};


export type QueryGetCurrentOrganisationTeamMoraleReportDataArgs = {
  id: Scalars['ID'];
};


export type QueryGetCurrentTotalDaysWorkedReportDataArgs = {
  id: Scalars['ID'];
};


export type QueryGetDailyBreakdownReportDataArgs = {
  id: Scalars['ID'];
};


export type QueryGetEthoOwnerReportDataArgs = {
  id: Scalars['ID'];
  type: Scalars['NonEmptyString'];
};


export type QueryGetLocationArgs = {
  id: Scalars['ID'];
};


export type QueryGetLocationByUniqueIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetLocationUsageReportDataArgs = {
  id: Scalars['ID'];
};


export type QueryGetMemberArgs = {
  id: Scalars['ID'];
};


export type QueryGetMemberDisplayVocationArgs = {
  id: Scalars['ID'];
};


export type QueryGetMembersByOrganisationArgs = {
  id: Scalars['ID'];
};


export type QueryGetNetworkArgs = {
  id: Scalars['ID'];
};


export type QueryGetOneMonthOfAttendancesArgs = {
  monthsBack: Scalars['Int'];
};


export type QueryGetOngoingOnSiteAttendancesAtLocationFromOrganisationArgs = {
  locationId: Scalars['ID'];
  organisationId: Scalars['ID'];
};


export type QueryGetOrgAdminPromoteCodesInOrganisationArgs = {
  id: Scalars['ID'];
};


export type QueryGetOrganisationArgs = {
  id: Scalars['ID'];
};


export type QueryGetOrganisationByUniqueInviteCodeArgs = {
  uniqueInviteCode: Scalars['NonEmptyString'];
};


export type QueryGetResourcesOrderedByRelevanceToVocationGroupArgs = {
  vocationGroupId: Scalars['NonEmptyString'];
};


export type QueryGetSubnetworkArgs = {
  id: Scalars['ID'];
};


export type QueryGetUnapprovedMembersOfOrganisationArgs = {
  organisationId: Scalars['ID'];
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryGetWbcListArgs = {
  id: Scalars['ID'];
};


export type QueryGetWbcPromoteCodesInOrganisationArgs = {
  id: Scalars['ID'];
};


export type QueryVerifyLoginArgs = {
  otp: Scalars['NonEmptyString'];
};


export type QueryVerifyRegistrationArgs = {
  otp: Scalars['NonEmptyString'];
};

export type RelevanceScore = {
  __typename?: 'RelevanceScore';
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  relevanceScore: Scalars['Int'];
  resource: Resource;
  updated_at?: Maybe<Scalars['DateTime']>;
  vocationGroup?: Maybe<VocationGroup>;
};

export type Resource = {
  __typename?: 'Resource';
  clickThroughs?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  link: Scalars['String'];
  relevanceScores?: Maybe<Array<RelevanceScore>>;
  s3PicLink: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type Score = {
  __typename?: 'Score';
  attendance?: Maybe<Attendance>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  member?: Maybe<Member>;
  mentalCheckscoreEnd?: Maybe<Scalars['Float']>;
  mentalCheckscoreStart?: Maybe<Scalars['Float']>;
  physicalCheckscore?: Maybe<Scalars['Float']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum SecondaryRole {
  Unassigned = 'UNASSIGNED',
  Wbc = 'WBC'
}

export type Subnetwork = {
  __typename?: 'Subnetwork';
  description: Scalars['NonEmptyString'];
  id: Scalars['ID'];
  name: Scalars['NonEmptyString'];
  networks?: Maybe<Array<Network>>;
  odsCode?: Maybe<Scalars['String']>;
  organisations?: Maybe<Array<Organisation>>;
};

export type TeamMoraleReportData = {
  __typename?: 'TeamMoraleReportData';
  currentMonth: Array<Scalars['Float']>;
  prevMonth: Array<Scalars['Float']>;
  prevPrevMonth: Array<Scalars['Float']>;
};

export type User = {
  __typename?: 'User';
  created_at?: Maybe<Scalars['DateTime']>;
  dataSharingConsent: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isEthoOwner: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  members?: Maybe<Array<Member>>;
  phoneNumber: Scalars['String'];
  profilePicURL?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  userAdministeredOrganisations?: Maybe<Array<Organisation>>;
  vocation?: Maybe<Vocation>;
};

export type UserAndAccessTokens = {
  __typename?: 'UserAndAccessTokens';
  accessToken: Scalars['NonEmptyString'];
  createdUser: User;
  refreshToken: Scalars['NonEmptyString'];
};

export type Vocation = {
  __typename?: 'Vocation';
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  users?: Maybe<Array<User>>;
  vocationGroup?: Maybe<VocationGroup>;
  vocationSpecialities?: Maybe<Array<VocationSpeciality>>;
};

export type VocationGroup = {
  __typename?: 'VocationGroup';
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  vocations?: Maybe<Array<Vocation>>;
};

export type VocationSpeciality = {
  __typename?: 'VocationSpeciality';
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  members?: Maybe<Array<Member>>;
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  vocation?: Maybe<Vocation>;
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', getUsers: Array<{ __typename?: 'User', id: string, firstName: string, lastName: string, profilePicURL?: string | null, vocation?: { __typename?: 'Vocation', id: string, name: string } | null }> };

export type GetOrganisationQueryVariables = Exact<{
  uniqueInviteCode: Scalars['NonEmptyString'];
}>;


export type GetOrganisationQuery = { __typename?: 'Query', getOrganisationByUniqueInviteCode: { __typename?: 'Organisation', displayName: string } };

export type GetUserQueryVariables = Exact<{
  getUserId: Scalars['ID'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'User', id: string, firstName: string, lastName: string, phoneNumber: string, profilePicURL?: string | null, isEthoOwner: boolean, dataSharingConsent: boolean, vocation?: { __typename?: 'Vocation', id: string, name: string } | null, members?: Array<{ __typename?: 'Member', id: string, primaryRole: PrimaryRole, secondaryRole: SecondaryRole }> | null, userAdministeredOrganisations?: Array<{ __typename?: 'Organisation', id: string, displayName: string, providerName: string, odsCode?: string | null, type: OrganisationType, uniqueInviteCode: string, locations?: Array<{ __typename?: 'Location', displayName: any, address?: { __typename?: 'Address', addressLine: any, county: any, postcode: any, city: any } | null }> | null, mainAddress?: { __typename?: 'Address', addressLine: any, county: any, city: any, postcode: any } | null, subnetworks?: Array<{ __typename?: 'Subnetwork', id: string, odsCode?: string | null, description: any, networks?: Array<{ __typename?: 'Network', id: string, name: any }> | null }> | null }> | null } };

export type VerifyLoginQueryVariables = Exact<{
  otp: Scalars['NonEmptyString'];
}>;


export type VerifyLoginQuery = { __typename?: 'Query', verifyLogin: { __typename?: 'AccessTokens', accessToken: any, refreshToken: any } };

export type GetMembersByOrganisationQueryVariables = Exact<{
  getMembersByOrganisationId: Scalars['ID'];
}>;


export type GetMembersByOrganisationQuery = { __typename?: 'Query', getMembersByOrganisation: Array<{ __typename?: 'Member', id: string, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, vocation?: { __typename?: 'Vocation', id: string, name: string } | null } | null }> };

export type GetAdminsListQueryVariables = Exact<{
  getAdminsListId: Scalars['ID'];
}>;


export type GetAdminsListQuery = { __typename?: 'Query', getAdminsList: Array<{ __typename?: 'Member', id: string, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, vocation?: { __typename?: 'Vocation', id: string, name: string } | null } | null }> };

export type GetWbcListQueryVariables = Exact<{
  getWbcListId: Scalars['ID'];
}>;


export type GetWbcListQuery = { __typename?: 'Query', getWBCList: Array<{ __typename?: 'Member', id: string, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, vocation?: { __typename?: 'Vocation', id: string, name: string } | null } | null }> };

export type GetAllOrganisationsAndLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllOrganisationsAndLocationsQuery = { __typename?: 'Query', getOrganisations: Array<{ __typename?: 'Organisation', id: string, displayName: string, providerName: string, cqcLocationId?: string | null, odsCode?: string | null, type: OrganisationType, uniqueInviteCode: string, primaryAdmin?: { __typename?: 'Member', user?: { __typename?: 'User', firstName: string, lastName: string, phoneNumber: string, email?: string | null, vocation?: { __typename?: 'Vocation', id: string, name: string, vocationSpecialities?: Array<{ __typename?: 'VocationSpeciality', id: string, name: string }> | null } | null } | null, vocationSpeciality?: { __typename?: 'VocationSpeciality', id: string, name: string } | null } | null, mainAddress?: { __typename?: 'Address', id: string, addressLine: any, postcode: any, city: any, country: any, county: any, lat: number, long: number } | null, subnetworks?: Array<{ __typename?: 'Subnetwork', id: string, name: any, networks?: Array<{ __typename?: 'Network', id: string, name: any, subnetworks?: Array<{ __typename?: 'Subnetwork', id: string, name: any }> | null }> | null }> | null, locations?: Array<{ __typename?: 'Location', id: string, displayName: any, address?: { __typename?: 'Address', addressLine: any } | null }> | null }>, getLocations: Array<{ __typename?: 'Location', id: string, name: any, displayName: any, cqcLocationId?: string | null, ethoUniqueId?: any | null, locationType: any, address?: { __typename?: 'Address', id: string, addressLine: any, postcode: any, city: any, country: any, county: any, lat: number, long: number } | null, organisations?: Array<{ __typename?: 'Organisation', id: string, displayName: string }> | null }> };

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { __typename?: 'Query', getUsers: Array<{ __typename?: 'User', id: string, firstName: string, lastName: string, vocation?: { __typename?: 'Vocation', id: string, name: string } | null }> };

export type RevokeRoleMutationVariables = Exact<{
  memberId: Scalars['ID'];
}>;


export type RevokeRoleMutation = { __typename?: 'Mutation', revokeRoles: { __typename?: 'Member', id: string } };

export type RemoveMemberMutationVariables = Exact<{
  memberId: Scalars['ID'];
}>;


export type RemoveMemberMutation = { __typename?: 'Mutation', deleteMember: { __typename?: 'Member', id: string } };

export type RemoveUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type RemoveUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: string } };

export type GetRefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['NonEmptyString'];
}>;


export type GetRefreshTokenMutation = { __typename?: 'Mutation', getRefreshToken: { __typename?: 'AccessTokens', accessToken: any, refreshToken: any } };

export type AttemptLoginMutationVariables = Exact<{
  phoneNumber: Scalars['NonEmptyString'];
  userAgent?: InputMaybe<Scalars['String']>;
}>;


export type AttemptLoginMutation = { __typename?: 'Mutation', attemptLogin: { __typename?: 'AttemptLoginResponse', message: any } };

export type CreateOrganisationMutationVariables = Exact<{
  type: OrganisationType;
  displayName: Scalars['NonEmptyString'];
  providerName: Scalars['NonEmptyString'];
  odsCode?: InputMaybe<Scalars['String']>;
  cqcLocationId?: InputMaybe<Scalars['String']>;
  adminFirstName: Scalars['NonEmptyString'];
  adminLastName: Scalars['NonEmptyString'];
  adminPhone: Scalars['NonEmptyString'];
  adminDataSharingConsent: Scalars['Boolean'];
  adminShareMyScores: Scalars['Boolean'];
  adminContributeToPracticeScore: Scalars['Boolean'];
  adminVocationId: Scalars['ID'];
  adminMemberVocationSpecialityId: Scalars['ID'];
  adminEmail: Scalars['NonEmptyString'];
  mainAddressId: Scalars['ID'];
  locationIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  subnetworkIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreateOrganisationMutation = { __typename?: 'Mutation', createOrganisation: { __typename?: 'Organisation', id: string, type: OrganisationType, uniqueInviteCode: string, displayName: string, providerName: string, odsCode?: string | null, cqcLocationId?: string | null, locations?: Array<{ __typename?: 'Location', id: string }> | null, subnetworks?: Array<{ __typename?: 'Subnetwork', id: string }> | null } };

export type CreateLocationMutationVariables = Exact<{
  name: Scalars['NonEmptyString'];
  displayName: Scalars['NonEmptyString'];
  addressLine: Scalars['NonEmptyString'];
  country: Scalars['NonEmptyString'];
  county: Scalars['NonEmptyString'];
  city: Scalars['NonEmptyString'];
  postcode: Scalars['NonEmptyString'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
  locationType: LocationType;
  cqcLocationId?: InputMaybe<Scalars['String']>;
  organisationIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation: { __typename?: 'Location', id: string, name: any, displayName: any, cqcLocationId?: string | null, ethoUniqueId?: any | null, locationType: any, organisations?: Array<{ __typename?: 'Organisation', id: string }> | null, ongoingAttendances?: Array<{ __typename?: 'Attendance', id: string }> | null, address?: { __typename?: 'Address', addressLine: any, city: any, postcode: any, lat: number, long: number, county: any } | null } };

export type GetNetworksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNetworksQuery = { __typename?: 'Query', getNetworks: Array<{ __typename?: 'Network', id: string, name: any, subnetworks?: Array<{ __typename?: 'Subnetwork', id: string, name: any, odsCode?: string | null, description: any }> | null }> };

export type GetSubnetworksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubnetworksQuery = { __typename?: 'Query', getSubnetworks: Array<{ __typename?: 'Subnetwork', name: any, id: string, networks?: Array<{ __typename?: 'Network', id: string, name: any, subnetworks?: Array<{ __typename?: 'Subnetwork', id: string, name: any }> | null }> | null, organisations?: Array<{ __typename?: 'Organisation', id: string }> | null }> };

export type GetSubnetworkQueryVariables = Exact<{
  getSubnetworkId: Scalars['ID'];
}>;


export type GetSubnetworkQuery = { __typename?: 'Query', getSubnetwork: { __typename?: 'Subnetwork', id: string, name: any, networks?: Array<{ __typename?: 'Network', id: string, name: any }> | null, organisations?: Array<{ __typename?: 'Organisation', id: string }> | null } };

export type CreateNetworkMutationVariables = Exact<{
  name: Scalars['NonEmptyString'];
  subnetworkIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreateNetworkMutation = { __typename?: 'Mutation', createNetwork: { __typename?: 'Network', id: string, name: any, subnetworks?: Array<{ __typename?: 'Subnetwork', id: string, odsCode?: string | null, description: any }> | null } };

export type CreateSubnetworkMutationVariables = Exact<{
  name: Scalars['NonEmptyString'];
  networkIds: Array<Scalars['ID']> | Scalars['ID'];
  description: Scalars['NonEmptyString'];
  odsCode?: InputMaybe<Scalars['String']>;
  organisationIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreateSubnetworkMutation = { __typename?: 'Mutation', createSubnetwork: { __typename?: 'Subnetwork', id: string, name: any, description: any, odsCode?: string | null, networks?: Array<{ __typename?: 'Network', id: string, name: any }> | null, organisations?: Array<{ __typename?: 'Organisation', id: string }> | null } };

export type GetUnapprovedMembersOfOrganisationQueryVariables = Exact<{
  organisationId: Scalars['ID'];
}>;


export type GetUnapprovedMembersOfOrganisationQuery = { __typename?: 'Query', getUnapprovedMembersOfOrganisation: Array<{ __typename?: 'Member', id: string, approved?: boolean | null, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, vocation?: { __typename?: 'Vocation', id: string, name: string } | null } | null, organisation?: { __typename?: 'Organisation', id: string, members?: Array<{ __typename?: 'Member', id: string, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, vocation?: { __typename?: 'Vocation', id: string, name: string } | null } | null }> | null } | null }> };

export type ApproveMemberMutationVariables = Exact<{
  approveMemberId: Scalars['ID'];
}>;


export type ApproveMemberMutation = { __typename?: 'Mutation', approveMember: { __typename?: 'Member', id: string, approved?: boolean | null } };

export type DenyApprovalToMemberMutationVariables = Exact<{
  denyApprovalToMemberId: Scalars['ID'];
}>;


export type DenyApprovalToMemberMutation = { __typename?: 'Mutation', denyApprovalToMember: { __typename?: 'Member', id: string, approved?: boolean | null } };

export type GenerateOrgAdminPromoteCodeMutationVariables = Exact<{
  organisationToGenerateForId: Scalars['ID'];
}>;


export type GenerateOrgAdminPromoteCodeMutation = { __typename?: 'Mutation', generateOrgAdminPromoteCode: { __typename?: 'OrganisationInviteCode', id: string, code: string } };

export type GenerateWbcPromoteCodeMutationVariables = Exact<{
  organisationToGenerateForId: Scalars['ID'];
}>;


export type GenerateWbcPromoteCodeMutation = { __typename?: 'Mutation', generateWbcPromoteCode: { __typename?: 'OrganisationInviteCode', id: string, code: string } };

export type GetOrgAdminPromoteCodesInOrganisationQueryVariables = Exact<{
  getOrgAdminPromoteCodesInOrganisationId: Scalars['ID'];
}>;


export type GetOrgAdminPromoteCodesInOrganisationQuery = { __typename?: 'Query', getOrgAdminPromoteCodesInOrganisation: Array<{ __typename?: 'OrganisationInviteCode', id: string, code: string }> };

export type GetWbcPromoteCodesInOrganisationQueryVariables = Exact<{
  getWbcPromoteCodesInOrganisationId: Scalars['ID'];
}>;


export type GetWbcPromoteCodesInOrganisationQuery = { __typename?: 'Query', getWbcPromoteCodesInOrganisation: Array<{ __typename?: 'OrganisationInviteCode', id: string, code: string }> };

export type GetVocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVocationsQuery = { __typename?: 'Query', getVocations: Array<{ __typename?: 'Vocation', id: string, name: string, vocationSpecialities?: Array<{ __typename?: 'VocationSpeciality', name: string, id: string }> | null }> };

export type GetLocationByUniqueIdQueryVariables = Exact<{
  ethoUniqueId: Scalars['ID'];
}>;


export type GetLocationByUniqueIdQuery = { __typename?: 'Query', getLocationByUniqueId: { __typename?: 'Location', id: string, displayName: any } };

export type GetKpisQueryVariables = Exact<{ [key: string]: never; }>;


export type GetKpisQuery = { __typename?: 'Query', getStickyReportData: any };

export type GetEthoOwnerReportDataQueryVariables = Exact<{
  reportType: Scalars['NonEmptyString'];
  id: Scalars['ID'];
}>;


export type GetEthoOwnerReportDataQuery = { __typename?: 'Query', getEthoOwnerReportData: any };

export type GetOrgAdminReportsQueryVariables = Exact<{
  organisationId: Scalars['ID'];
}>;

export type GetActiveTeamCheckInByRoleVariables = Exact<{
  organisationId: Scalars['ID'];
  vocationGroupId: Scalars['ID'];
}>

export type GetActiveTeamCheckInByLocationVariables = Exact<{
  organisationId: Scalars['ID'];
  locationId: Scalars['ID'];
}>

export type GetOrgAdminReportsQuery = { __typename?: 'Query', getCurrentTotalDaysWorkedReportData: any, getLocationUsageReportData: any, getDailyBreakdownReportData: any, getCurrentOrganisationTeamMoraleReportData: { __typename?: 'TeamMoraleReportData', prevPrevMonth: Array<number>, prevMonth: Array<number>, currentMonth: Array<number> } };

export type DeleteOrganisationMutationVariables = Exact<{
  organisationId: Scalars['ID'];
}>;


export type DeleteOrganisationMutation = { __typename?: 'Mutation', deleteOrganisation: { __typename?: 'Organisation', id: string } };

export type DeleteLocationMutationVariables = Exact<{
  locationId: Scalars['ID'];
}>;


export type DeleteLocationMutation = { __typename?: 'Mutation', deleteLocation: { __typename?: 'Location', id: string } };

export type UpdateLocationMutationVariables = Exact<{
  updateLocationId: Scalars['ID'];
  name?: InputMaybe<Scalars['NonEmptyString']>;
  displayName?: InputMaybe<Scalars['NonEmptyString']>;
  addressLine?: InputMaybe<Scalars['NonEmptyString']>;
  country?: InputMaybe<Scalars['NonEmptyString']>;
  county?: InputMaybe<Scalars['NonEmptyString']>;
  city?: InputMaybe<Scalars['NonEmptyString']>;
  postcode?: InputMaybe<Scalars['NonEmptyString']>;
  cqcLocationId?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['Float']>;
  long?: InputMaybe<Scalars['Float']>;
  organisationIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UpdateLocationMutation = { __typename?: 'Mutation', updateLocation: { __typename?: 'Location', id: string, name: any } };

export type UpdateOrganisationMutationVariables = Exact<{
  mainAddressId: Scalars['ID'];
  updateOrganisationId: Scalars['ID'];
  type?: InputMaybe<OrganisationType>;
  adminEmail?: InputMaybe<Scalars['NonEmptyString']>;
  adminFirstName?: InputMaybe<Scalars['NonEmptyString']>;
  adminLastName?: InputMaybe<Scalars['NonEmptyString']>;
  adminPhone?: InputMaybe<Scalars['NonEmptyString']>;
  adminDataSharingConsent?: InputMaybe<Scalars['Boolean']>;
  adminShareMyScores?: InputMaybe<Scalars['Boolean']>;
  adminContributeToPracticeScore?: InputMaybe<Scalars['Boolean']>;
  adminVocationId?: InputMaybe<Scalars['ID']>;
  adminMemberVocationSpecialityId?: InputMaybe<Scalars['ID']>;
  displayName?: InputMaybe<Scalars['NonEmptyString']>;
  providerName?: InputMaybe<Scalars['NonEmptyString']>;
  odsCode?: InputMaybe<Scalars['String']>;
  cqcLocationId?: InputMaybe<Scalars['String']>;
  locationIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  subnetworkIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UpdateOrganisationMutation = { __typename?: 'Mutation', updateOrganisation: { __typename?: 'Organisation', id: string, displayName: string } };

export type CheckInGuestMutationVariables = Exact<{
  organisationUniqueId: Scalars['NonEmptyString'];
  locationUniqueId: Scalars['NonEmptyString'];
  firstName: Scalars['NonEmptyString'];
  lastName: Scalars['NonEmptyString'];
  company: Scalars['NonEmptyString'];
}>;


export type CheckInGuestMutation = { __typename?: 'Mutation', checkMyselfInGuest: { __typename?: 'Attendance', id: string, startTimestamp: any, status: AttendanceStatus } };

export type CheckOutGuestMutationVariables = Exact<{
  attendanceId: Scalars['ID'];
}>;


export type CheckOutGuestMutation = { __typename?: 'Mutation', checkMyselfOutGuest: { __typename?: 'Attendance', id: string, endTimestamp?: any | null, status: AttendanceStatus } };


export const GetUsersDocument = gql`
    query GetUsers {
  getUsers {
    id
    firstName
    lastName
    profilePicURL
    vocation {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetOrganisationDocument = gql`
    query getOrganisation($uniqueInviteCode: NonEmptyString!) {
  getOrganisationByUniqueInviteCode(uniqueInviteCode: $uniqueInviteCode) {
    displayName
  }
}
    `;

/**
 * __useGetOrganisationQuery__
 *
 * To run a query within a React component, call `useGetOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationQuery({
 *   variables: {
 *      uniqueInviteCode: // value for 'uniqueInviteCode'
 *   },
 * });
 */
export function useGetOrganisationQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationQuery, GetOrganisationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(GetOrganisationDocument, options);
}
export function useGetOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationQuery, GetOrganisationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(GetOrganisationDocument, options);
}
export type GetOrganisationQueryHookResult = ReturnType<typeof useGetOrganisationQuery>;
export type GetOrganisationLazyQueryHookResult = ReturnType<typeof useGetOrganisationLazyQuery>;
export type GetOrganisationQueryResult = Apollo.QueryResult<GetOrganisationQuery, GetOrganisationQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($getUserId: ID!) {
  getUser(id: $getUserId) {
    id
    firstName
    lastName
    phoneNumber
    profilePicURL
    vocation {
      id
      name
    }
    isEthoOwner
    dataSharingConsent
    members {
      id
      primaryRole
      secondaryRole
    }
    userAdministeredOrganisations {
      id
      displayName
      providerName
      odsCode
      type
      uniqueInviteCode
      locations {
        id
        displayName
        address {
          addressLine
          county
          postcode
          city
        }
      }
      mainAddress {
        addressLine
        county
        city
        postcode
      }
      subnetworks {
        name
        id
        odsCode
        description
        networks {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      getUserId: // value for 'getUserId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const VerifyLoginDocument = gql`
    query verifyLogin($otp: NonEmptyString!) {
  verifyLogin(otp: $otp) {
    accessToken
    refreshToken
  }
}
    `;

/**
 * __useVerifyLoginQuery__
 *
 * To run a query within a React component, call `useVerifyLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyLoginQuery({
 *   variables: {
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useVerifyLoginQuery(baseOptions: Apollo.QueryHookOptions<VerifyLoginQuery, VerifyLoginQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VerifyLoginQuery, VerifyLoginQueryVariables>(VerifyLoginDocument, options);
}
export function useVerifyLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyLoginQuery, VerifyLoginQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VerifyLoginQuery, VerifyLoginQueryVariables>(VerifyLoginDocument, options);
}
export type VerifyLoginQueryHookResult = ReturnType<typeof useVerifyLoginQuery>;
export type VerifyLoginLazyQueryHookResult = ReturnType<typeof useVerifyLoginLazyQuery>;
export type VerifyLoginQueryResult = Apollo.QueryResult<VerifyLoginQuery, VerifyLoginQueryVariables>;
export const GetMembersByOrganisationDocument = gql`
    query GetMembersByOrganisation($getMembersByOrganisationId: ID!) {
  getMembersByOrganisation(id: $getMembersByOrganisationId) {
    id
    user {
      id
      firstName
      lastName
      vocation {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetMembersByOrganisationQuery__
 *
 * To run a query within a React component, call `useGetMembersByOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersByOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersByOrganisationQuery({
 *   variables: {
 *      getMembersByOrganisationId: // value for 'getMembersByOrganisationId'
 *   },
 * });
 */
export function useGetMembersByOrganisationQuery(baseOptions: Apollo.QueryHookOptions<GetMembersByOrganisationQuery, GetMembersByOrganisationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMembersByOrganisationQuery, GetMembersByOrganisationQueryVariables>(GetMembersByOrganisationDocument, options);
}
export function useGetMembersByOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMembersByOrganisationQuery, GetMembersByOrganisationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetMembersByOrganisationQuery, GetMembersByOrganisationQueryVariables>(GetMembersByOrganisationDocument, options);
}
export type GetMembersByOrganisationQueryHookResult = ReturnType<typeof useGetMembersByOrganisationQuery>;
export type GetMembersByOrganisationLazyQueryHookResult = ReturnType<typeof useGetMembersByOrganisationLazyQuery>;
export type GetMembersByOrganisationQueryResult = Apollo.QueryResult<GetMembersByOrganisationQuery, GetMembersByOrganisationQueryVariables>;
export const GetAdminsListDocument = gql`
    query GetAdminsList($getAdminsListId: ID!) {
  getAdminsList(id: $getAdminsListId) {
    id
    user {
      id
      firstName
      lastName
      vocation {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetAdminsListQuery__
 *
 * To run a query within a React component, call `useGetAdminsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminsListQuery({
 *   variables: {
 *      getAdminsListId: // value for 'getAdminsListId'
 *   },
 * });
 */
export function useGetAdminsListQuery(baseOptions: Apollo.QueryHookOptions<GetAdminsListQuery, GetAdminsListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAdminsListQuery, GetAdminsListQueryVariables>(GetAdminsListDocument, options);
}
export function useGetAdminsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminsListQuery, GetAdminsListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAdminsListQuery, GetAdminsListQueryVariables>(GetAdminsListDocument, options);
}
export type GetAdminsListQueryHookResult = ReturnType<typeof useGetAdminsListQuery>;
export type GetAdminsListLazyQueryHookResult = ReturnType<typeof useGetAdminsListLazyQuery>;
export type GetAdminsListQueryResult = Apollo.QueryResult<GetAdminsListQuery, GetAdminsListQueryVariables>;
export const GetWbcListDocument = gql`
    query GetWBCList($getWbcListId: ID!) {
  getWBCList(id: $getWbcListId) {
    id
    user {
      id
      firstName
      lastName
      vocation {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetWbcListQuery__
 *
 * To run a query within a React component, call `useGetWbcListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbcListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbcListQuery({
 *   variables: {
 *      getWbcListId: // value for 'getWbcListId'
 *   },
 * });
 */
export function useGetWbcListQuery(baseOptions: Apollo.QueryHookOptions<GetWbcListQuery, GetWbcListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWbcListQuery, GetWbcListQueryVariables>(GetWbcListDocument, options);
}
export function useGetWbcListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWbcListQuery, GetWbcListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWbcListQuery, GetWbcListQueryVariables>(GetWbcListDocument, options);
}
export type GetWbcListQueryHookResult = ReturnType<typeof useGetWbcListQuery>;
export type GetWbcListLazyQueryHookResult = ReturnType<typeof useGetWbcListLazyQuery>;
export type GetWbcListQueryResult = Apollo.QueryResult<GetWbcListQuery, GetWbcListQueryVariables>;
export const GetAllOrganisationsAndLocationsDocument = gql`
    query getAllOrganisationsAndLocations {
  getOrganisations {
    id
    displayName
    providerName
    cqcLocationId
    odsCode
    type
    uniqueInviteCode
    primaryAdmin {
      user {
        firstName
        lastName
        phoneNumber
        email
        vocation {
          id
          name
          vocationSpecialities {
            id
            name
          }
        }
      }
      vocationSpeciality {
        id
        name
      }
    }
    mainAddress {
      id
      addressLine
      postcode
      city
      country
      county
      lat
      long
    }
    subnetworks {
      id
      name
      networks {
        id
        name
        subnetworks {
          id
          name
        }
      }
    }
    locations {
      id
      displayName
      address {
        addressLine
      }
    }
  }
  getLocations {
    id
    name
    displayName
    address {
      id
      addressLine
      postcode
      city
      country
      county
      lat
      long
    }
    cqcLocationId
    ethoUniqueId
    organisations {
      id
      displayName
    }
    locationType
  }
}
    `;

/**
 * __useGetAllOrganisationsAndLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllOrganisationsAndLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganisationsAndLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganisationsAndLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllOrganisationsAndLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOrganisationsAndLocationsQuery, GetAllOrganisationsAndLocationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllOrganisationsAndLocationsQuery, GetAllOrganisationsAndLocationsQueryVariables>(GetAllOrganisationsAndLocationsDocument, options);
}
export function useGetAllOrganisationsAndLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOrganisationsAndLocationsQuery, GetAllOrganisationsAndLocationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllOrganisationsAndLocationsQuery, GetAllOrganisationsAndLocationsQueryVariables>(GetAllOrganisationsAndLocationsDocument, options);
}
export type GetAllOrganisationsAndLocationsQueryHookResult = ReturnType<typeof useGetAllOrganisationsAndLocationsQuery>;
export type GetAllOrganisationsAndLocationsLazyQueryHookResult = ReturnType<typeof useGetAllOrganisationsAndLocationsLazyQuery>;
export type GetAllOrganisationsAndLocationsQueryResult = Apollo.QueryResult<GetAllOrganisationsAndLocationsQuery, GetAllOrganisationsAndLocationsQueryVariables>;
export const GetAllUsersDocument = gql`
    query getAllUsers {
  getUsers {
    id
    firstName
    lastName
    vocation {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
}
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
}
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const RevokeRoleDocument = gql`
    mutation revokeRole($memberId: ID!) {
  revokeRoles(id: $memberId) {
    id
  }
}
    `;
export type RevokeRoleMutationFn = Apollo.MutationFunction<RevokeRoleMutation, RevokeRoleMutationVariables>;

/**
 * __useRevokeRoleMutation__
 *
 * To run a mutation, you first call `useRevokeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeRoleMutation, { data, loading, error }] = useRevokeRoleMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useRevokeRoleMutation(baseOptions?: Apollo.MutationHookOptions<RevokeRoleMutation, RevokeRoleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RevokeRoleMutation, RevokeRoleMutationVariables>(RevokeRoleDocument, options);
}
export type RevokeRoleMutationHookResult = ReturnType<typeof useRevokeRoleMutation>;
export type RevokeRoleMutationResult = Apollo.MutationResult<RevokeRoleMutation>;
export type RevokeRoleMutationOptions = Apollo.BaseMutationOptions<RevokeRoleMutation, RevokeRoleMutationVariables>;
export const RemoveMemberDocument = gql`
    mutation removeMember($memberId: ID!) {
  deleteMember(id: $memberId) {
    id
  }
}
    `;
export type RemoveMemberMutationFn = Apollo.MutationFunction<RemoveMemberMutation, RemoveMemberMutationVariables>;

/**
 * __useRemoveMemberMutation__
 *
 * To run a mutation, you first call `useRemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberMutation, { data, loading, error }] = useRemoveMemberMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useRemoveMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMemberMutation, RemoveMemberMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveMemberMutation, RemoveMemberMutationVariables>(RemoveMemberDocument, options);
}
export type RemoveMemberMutationHookResult = ReturnType<typeof useRemoveMemberMutation>;
export type RemoveMemberMutationResult = Apollo.MutationResult<RemoveMemberMutation>;
export type RemoveMemberMutationOptions = Apollo.BaseMutationOptions<RemoveMemberMutation, RemoveMemberMutationVariables>;
export const RemoveUserDocument = gql`
    mutation removeUser($userId: ID!) {
  deleteUser(id: $userId) {
    id
  }
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
}
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const GetRefreshTokenDocument = gql`
    mutation getRefreshToken($refreshToken: NonEmptyString!) {
  getRefreshToken(refreshToken: $refreshToken) {
    accessToken
    refreshToken
  }
}
    `;
export type GetRefreshTokenMutationFn = Apollo.MutationFunction<GetRefreshTokenMutation, GetRefreshTokenMutationVariables>;

/**
 * __useGetRefreshTokenMutation__
 *
 * To run a mutation, you first call `useGetRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getRefreshTokenMutation, { data, loading, error }] = useGetRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useGetRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<GetRefreshTokenMutation, GetRefreshTokenMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GetRefreshTokenMutation, GetRefreshTokenMutationVariables>(GetRefreshTokenDocument, options);
}
export type GetRefreshTokenMutationHookResult = ReturnType<typeof useGetRefreshTokenMutation>;
export type GetRefreshTokenMutationResult = Apollo.MutationResult<GetRefreshTokenMutation>;
export type GetRefreshTokenMutationOptions = Apollo.BaseMutationOptions<GetRefreshTokenMutation, GetRefreshTokenMutationVariables>;
export const AttemptLoginDocument = gql`
    mutation attemptLogin($phoneNumber: NonEmptyString!, $userAgent: String) {
  attemptLogin(phoneNumber: $phoneNumber, userAgent: $userAgent) {
    message
  }
}
    `;
export type AttemptLoginMutationFn = Apollo.MutationFunction<AttemptLoginMutation, AttemptLoginMutationVariables>;

/**
 * __useAttemptLoginMutation__
 *
 * To run a mutation, you first call `useAttemptLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttemptLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attemptLoginMutation, { data, loading, error }] = useAttemptLoginMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      userAgent: // value for 'userAgent'
 *   },
 * });
 */
export function useAttemptLoginMutation(baseOptions?: Apollo.MutationHookOptions<AttemptLoginMutation, AttemptLoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AttemptLoginMutation, AttemptLoginMutationVariables>(AttemptLoginDocument, options);
}
export type AttemptLoginMutationHookResult = ReturnType<typeof useAttemptLoginMutation>;
export type AttemptLoginMutationResult = Apollo.MutationResult<AttemptLoginMutation>;
export type AttemptLoginMutationOptions = Apollo.BaseMutationOptions<AttemptLoginMutation, AttemptLoginMutationVariables>;
export const CreateOrganisationDocument = gql`
    mutation CreateOrganisation($type: OrganisationType!, $displayName: NonEmptyString!, $providerName: NonEmptyString!, $odsCode: String, $cqcLocationId: String, $adminFirstName: NonEmptyString!, $adminLastName: NonEmptyString!, $adminPhone: NonEmptyString!, $adminDataSharingConsent: Boolean!, $adminShareMyScores: Boolean!, $adminContributeToPracticeScore: Boolean!, $adminVocationId: ID!, $adminMemberVocationSpecialityId: ID!, $adminEmail: NonEmptyString!, $mainAddressId: ID!, $locationIds: [ID!], $subnetworkIds: [ID!]) {
  createOrganisation(
    type: $type
    displayName: $displayName
    providerName: $providerName
    odsCode: $odsCode
    cqcLocationId: $cqcLocationId
    adminFirstName: $adminFirstName
    adminLastName: $adminLastName
    adminPhone: $adminPhone
    adminDataSharingConsent: $adminDataSharingConsent
    adminShareMyScores: $adminShareMyScores
    adminContributeToPracticeScore: $adminContributeToPracticeScore
    adminVocationId: $adminVocationId
    adminMemberVocationSpecialityId: $adminMemberVocationSpecialityId
    adminEmail: $adminEmail
    mainAddressId: $mainAddressId
    locationIds: $locationIds
    subnetworkIds: $subnetworkIds
  ) {
    id
    type
    uniqueInviteCode
    displayName
    providerName
    odsCode
    cqcLocationId
    locations {
      id
    }
    subnetworks {
      id
    }
  }
}
    `;
export type CreateOrganisationMutationFn = Apollo.MutationFunction<CreateOrganisationMutation, CreateOrganisationMutationVariables>;

/**
 * __useCreateOrganisationMutation__
 *
 * To run a mutation, you first call `useCreateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganisationMutation, { data, loading, error }] = useCreateOrganisationMutation({
 *   variables: {
 *      type: // value for 'type'
 *      displayName: // value for 'displayName'
 *      providerName: // value for 'providerName'
 *      odsCode: // value for 'odsCode'
 *      cqcLocationId: // value for 'cqcLocationId'
 *      adminFirstName: // value for 'adminFirstName'
 *      adminLastName: // value for 'adminLastName'
 *      adminPhone: // value for 'adminPhone'
 *      adminDataSharingConsent: // value for 'adminDataSharingConsent'
 *      adminShareMyScores: // value for 'adminShareMyScores'
 *      adminContributeToPracticeScore: // value for 'adminContributeToPracticeScore'
 *      adminVocationId: // value for 'adminVocationId'
 *      adminMemberVocationSpecialityId: // value for 'adminMemberVocationSpecialityId'
 *      adminEmail: // value for 'adminEmail'
 *      mainAddressId: // value for 'mainAddressId'
 *      locationIds: // value for 'locationIds'
 *      subnetworkIds: // value for 'subnetworkIds'
 *   },
 * });
 */
export function useCreateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateOrganisationMutation, CreateOrganisationMutationVariables>(CreateOrganisationDocument, options);
}
export type CreateOrganisationMutationHookResult = ReturnType<typeof useCreateOrganisationMutation>;
export type CreateOrganisationMutationResult = Apollo.MutationResult<CreateOrganisationMutation>;
export type CreateOrganisationMutationOptions = Apollo.BaseMutationOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($name: NonEmptyString!, $displayName: NonEmptyString!, $addressLine: NonEmptyString!, $country: NonEmptyString!, $county: NonEmptyString!, $city: NonEmptyString!, $postcode: NonEmptyString!, $lat: Float!, $long: Float!, $locationType: LocationType!, $cqcLocationId: String, $organisationIds: [ID!]) {
  createLocation(
    name: $name
    displayName: $displayName
    addressLine: $addressLine
    country: $country
    county: $county
    city: $city
    postcode: $postcode
    lat: $lat
    long: $long
    locationType: $locationType
    cqcLocationId: $cqcLocationId
    organisationIds: $organisationIds
  ) {
    id
    name
    displayName
    organisations {
      id
    }
    ongoingAttendances {
      id
    }
    address {
      addressLine
      city
      postcode
      lat
      long
      county
    }
    cqcLocationId
    ethoUniqueId
    locationType
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      displayName: // value for 'displayName'
 *      addressLine: // value for 'addressLine'
 *      country: // value for 'country'
 *      county: // value for 'county'
 *      city: // value for 'city'
 *      postcode: // value for 'postcode'
 *      lat: // value for 'lat'
 *      long: // value for 'long'
 *      locationType: // value for 'locationType'
 *      cqcLocationId: // value for 'cqcLocationId'
 *      organisationIds: // value for 'organisationIds'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
}
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const GetNetworksDocument = gql`
    query GetNetworks {
  getNetworks {
    id
    name
    subnetworks {
      id
      name
      odsCode
      description
    }
  }
}
    `;

/**
 * __useGetNetworksQuery__
 *
 * To run a query within a React component, call `useGetNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetworksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNetworksQuery(baseOptions?: Apollo.QueryHookOptions<GetNetworksQuery, GetNetworksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetNetworksQuery, GetNetworksQueryVariables>(GetNetworksDocument, options);
}
export function useGetNetworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNetworksQuery, GetNetworksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetNetworksQuery, GetNetworksQueryVariables>(GetNetworksDocument, options);
}
export type GetNetworksQueryHookResult = ReturnType<typeof useGetNetworksQuery>;
export type GetNetworksLazyQueryHookResult = ReturnType<typeof useGetNetworksLazyQuery>;
export type GetNetworksQueryResult = Apollo.QueryResult<GetNetworksQuery, GetNetworksQueryVariables>;
export const GetSubnetworksDocument = gql`
    query GetSubnetworks {
  getSubnetworks {
    name
    id
    networks {
      id
      name
      subnetworks {
        id
        name
      }
    }
    organisations {
      id
    }
  }
}
    `;

/**
 * __useGetSubnetworksQuery__
 *
 * To run a query within a React component, call `useGetSubnetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubnetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubnetworksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubnetworksQuery(baseOptions?: Apollo.QueryHookOptions<GetSubnetworksQuery, GetSubnetworksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSubnetworksQuery, GetSubnetworksQueryVariables>(GetSubnetworksDocument, options);
}
export function useGetSubnetworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubnetworksQuery, GetSubnetworksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSubnetworksQuery, GetSubnetworksQueryVariables>(GetSubnetworksDocument, options);
}
export type GetSubnetworksQueryHookResult = ReturnType<typeof useGetSubnetworksQuery>;
export type GetSubnetworksLazyQueryHookResult = ReturnType<typeof useGetSubnetworksLazyQuery>;
export type GetSubnetworksQueryResult = Apollo.QueryResult<GetSubnetworksQuery, GetSubnetworksQueryVariables>;
export const GetSubnetworkDocument = gql`
    query GetSubnetwork($getSubnetworkId: ID!) {
  getSubnetwork(id: $getSubnetworkId) {
    id
    name
    networks {
      id
      name
    }
    organisations {
      id
    }
  }
}
    `;

/**
 * __useGetSubnetworkQuery__
 *
 * To run a query within a React component, call `useGetSubnetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubnetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubnetworkQuery({
 *   variables: {
 *      getSubnetworkId: // value for 'getSubnetworkId'
 *   },
 * });
 */
export function useGetSubnetworkQuery(baseOptions: Apollo.QueryHookOptions<GetSubnetworkQuery, GetSubnetworkQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSubnetworkQuery, GetSubnetworkQueryVariables>(GetSubnetworkDocument, options);
}
export function useGetSubnetworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubnetworkQuery, GetSubnetworkQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSubnetworkQuery, GetSubnetworkQueryVariables>(GetSubnetworkDocument, options);
}
export type GetSubnetworkQueryHookResult = ReturnType<typeof useGetSubnetworkQuery>;
export type GetSubnetworkLazyQueryHookResult = ReturnType<typeof useGetSubnetworkLazyQuery>;
export type GetSubnetworkQueryResult = Apollo.QueryResult<GetSubnetworkQuery, GetSubnetworkQueryVariables>;
export const CreateNetworkDocument = gql`
    mutation CreateNetwork($name: NonEmptyString!, $subnetworkIds: [ID!]) {
  createNetwork(name: $name, subnetworkIds: $subnetworkIds) {
    id
    name
    subnetworks {
      id
      odsCode
      description
    }
  }
}
    `;
export type CreateNetworkMutationFn = Apollo.MutationFunction<CreateNetworkMutation, CreateNetworkMutationVariables>;

/**
 * __useCreateNetworkMutation__
 *
 * To run a mutation, you first call `useCreateNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNetworkMutation, { data, loading, error }] = useCreateNetworkMutation({
 *   variables: {
 *      name: // value for 'name'
 *      subnetworkIds: // value for 'subnetworkIds'
 *   },
 * });
 */
export function useCreateNetworkMutation(baseOptions?: Apollo.MutationHookOptions<CreateNetworkMutation, CreateNetworkMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateNetworkMutation, CreateNetworkMutationVariables>(CreateNetworkDocument, options);
}
export type CreateNetworkMutationHookResult = ReturnType<typeof useCreateNetworkMutation>;
export type CreateNetworkMutationResult = Apollo.MutationResult<CreateNetworkMutation>;
export type CreateNetworkMutationOptions = Apollo.BaseMutationOptions<CreateNetworkMutation, CreateNetworkMutationVariables>;
export const CreateSubnetworkDocument = gql`
    mutation CreateSubnetwork($name: NonEmptyString!, $networkIds: [ID!]!, $description: NonEmptyString!, $odsCode: String, $organisationIds: [ID!]) {
  createSubnetwork(
    name: $name
    networkIds: $networkIds
    description: $description
    odsCode: $odsCode
    organisationIds: $organisationIds
  ) {
    id
    name
    description
    odsCode
    networks {
      id
      name
    }
    organisations {
      id
    }
  }
}
    `;
export type CreateSubnetworkMutationFn = Apollo.MutationFunction<CreateSubnetworkMutation, CreateSubnetworkMutationVariables>;

/**
 * __useCreateSubnetworkMutation__
 *
 * To run a mutation, you first call `useCreateSubnetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubnetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubnetworkMutation, { data, loading, error }] = useCreateSubnetworkMutation({
 *   variables: {
 *      name: // value for 'name'
 *      networkIds: // value for 'networkIds'
 *      description: // value for 'description'
 *      odsCode: // value for 'odsCode'
 *      organisationIds: // value for 'organisationIds'
 *   },
 * });
 */
export function useCreateSubnetworkMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubnetworkMutation, CreateSubnetworkMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSubnetworkMutation, CreateSubnetworkMutationVariables>(CreateSubnetworkDocument, options);
}
export type CreateSubnetworkMutationHookResult = ReturnType<typeof useCreateSubnetworkMutation>;
export type CreateSubnetworkMutationResult = Apollo.MutationResult<CreateSubnetworkMutation>;
export type CreateSubnetworkMutationOptions = Apollo.BaseMutationOptions<CreateSubnetworkMutation, CreateSubnetworkMutationVariables>;
export const GetUnapprovedMembersOfOrganisationDocument = gql`
    query GetUnapprovedMembersOfOrganisation($organisationId: ID!) {
  getUnapprovedMembersOfOrganisation(organisationId: $organisationId) {
    id
    approved
    user {
      id
      firstName
      lastName
      vocation {
        id
        name
      }
    }
    organisation {
      id
      members {
        id
        user {
          id
          firstName
          lastName
          vocation {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUnapprovedMembersOfOrganisationQuery__
 *
 * To run a query within a React component, call `useGetUnapprovedMembersOfOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnapprovedMembersOfOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnapprovedMembersOfOrganisationQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetUnapprovedMembersOfOrganisationQuery(baseOptions: Apollo.QueryHookOptions<GetUnapprovedMembersOfOrganisationQuery, GetUnapprovedMembersOfOrganisationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUnapprovedMembersOfOrganisationQuery, GetUnapprovedMembersOfOrganisationQueryVariables>(GetUnapprovedMembersOfOrganisationDocument, options);
}
export function useGetUnapprovedMembersOfOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnapprovedMembersOfOrganisationQuery, GetUnapprovedMembersOfOrganisationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUnapprovedMembersOfOrganisationQuery, GetUnapprovedMembersOfOrganisationQueryVariables>(GetUnapprovedMembersOfOrganisationDocument, options);
}
export type GetUnapprovedMembersOfOrganisationQueryHookResult = ReturnType<typeof useGetUnapprovedMembersOfOrganisationQuery>;
export type GetUnapprovedMembersOfOrganisationLazyQueryHookResult = ReturnType<typeof useGetUnapprovedMembersOfOrganisationLazyQuery>;
export type GetUnapprovedMembersOfOrganisationQueryResult = Apollo.QueryResult<GetUnapprovedMembersOfOrganisationQuery, GetUnapprovedMembersOfOrganisationQueryVariables>;
export const ApproveMemberDocument = gql`
    mutation ApproveMember($approveMemberId: ID!) {
  approveMember(id: $approveMemberId) {
    id
    approved
  }
}
    `;
export type ApproveMemberMutationFn = Apollo.MutationFunction<ApproveMemberMutation, ApproveMemberMutationVariables>;

/**
 * __useApproveMemberMutation__
 *
 * To run a mutation, you first call `useApproveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveMemberMutation, { data, loading, error }] = useApproveMemberMutation({
 *   variables: {
 *      approveMemberId: // value for 'approveMemberId'
 *   },
 * });
 */
export function useApproveMemberMutation(baseOptions?: Apollo.MutationHookOptions<ApproveMemberMutation, ApproveMemberMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ApproveMemberMutation, ApproveMemberMutationVariables>(ApproveMemberDocument, options);
}
export type ApproveMemberMutationHookResult = ReturnType<typeof useApproveMemberMutation>;
export type ApproveMemberMutationResult = Apollo.MutationResult<ApproveMemberMutation>;
export type ApproveMemberMutationOptions = Apollo.BaseMutationOptions<ApproveMemberMutation, ApproveMemberMutationVariables>;
export const DenyApprovalToMemberDocument = gql`
    mutation DenyApprovalToMember($denyApprovalToMemberId: ID!) {
  denyApprovalToMember(id: $denyApprovalToMemberId) {
    id
    approved
  }
}
    `;
export type DenyApprovalToMemberMutationFn = Apollo.MutationFunction<DenyApprovalToMemberMutation, DenyApprovalToMemberMutationVariables>;

/**
 * __useDenyApprovalToMemberMutation__
 *
 * To run a mutation, you first call `useDenyApprovalToMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDenyApprovalToMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [denyApprovalToMemberMutation, { data, loading, error }] = useDenyApprovalToMemberMutation({
 *   variables: {
 *      denyApprovalToMemberId: // value for 'denyApprovalToMemberId'
 *   },
 * });
 */
export function useDenyApprovalToMemberMutation(baseOptions?: Apollo.MutationHookOptions<DenyApprovalToMemberMutation, DenyApprovalToMemberMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DenyApprovalToMemberMutation, DenyApprovalToMemberMutationVariables>(DenyApprovalToMemberDocument, options);
}
export type DenyApprovalToMemberMutationHookResult = ReturnType<typeof useDenyApprovalToMemberMutation>;
export type DenyApprovalToMemberMutationResult = Apollo.MutationResult<DenyApprovalToMemberMutation>;
export type DenyApprovalToMemberMutationOptions = Apollo.BaseMutationOptions<DenyApprovalToMemberMutation, DenyApprovalToMemberMutationVariables>;
export const GenerateOrgAdminPromoteCodeDocument = gql`
    mutation GenerateOrgAdminPromoteCode($organisationToGenerateForId: ID!) {
  generateOrgAdminPromoteCode(
    organisationToGenerateForId: $organisationToGenerateForId
  ) {
    id
    code
  }
}
    `;
export type GenerateOrgAdminPromoteCodeMutationFn = Apollo.MutationFunction<GenerateOrgAdminPromoteCodeMutation, GenerateOrgAdminPromoteCodeMutationVariables>;

/**
 * __useGenerateOrgAdminPromoteCodeMutation__
 *
 * To run a mutation, you first call `useGenerateOrgAdminPromoteCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateOrgAdminPromoteCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateOrgAdminPromoteCodeMutation, { data, loading, error }] = useGenerateOrgAdminPromoteCodeMutation({
 *   variables: {
 *      organisationToGenerateForId: // value for 'organisationToGenerateForId'
 *   },
 * });
 */
export function useGenerateOrgAdminPromoteCodeMutation(baseOptions?: Apollo.MutationHookOptions<GenerateOrgAdminPromoteCodeMutation, GenerateOrgAdminPromoteCodeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GenerateOrgAdminPromoteCodeMutation, GenerateOrgAdminPromoteCodeMutationVariables>(GenerateOrgAdminPromoteCodeDocument, options);
}
export type GenerateOrgAdminPromoteCodeMutationHookResult = ReturnType<typeof useGenerateOrgAdminPromoteCodeMutation>;
export type GenerateOrgAdminPromoteCodeMutationResult = Apollo.MutationResult<GenerateOrgAdminPromoteCodeMutation>;
export type GenerateOrgAdminPromoteCodeMutationOptions = Apollo.BaseMutationOptions<GenerateOrgAdminPromoteCodeMutation, GenerateOrgAdminPromoteCodeMutationVariables>;
export const GenerateWbcPromoteCodeDocument = gql`
    mutation GenerateWbcPromoteCode($organisationToGenerateForId: ID!) {
  generateWbcPromoteCode(
    organisationToGenerateForId: $organisationToGenerateForId
  ) {
    id
    code
  }
}
    `;
export type GenerateWbcPromoteCodeMutationFn = Apollo.MutationFunction<GenerateWbcPromoteCodeMutation, GenerateWbcPromoteCodeMutationVariables>;

/**
 * __useGenerateWbcPromoteCodeMutation__
 *
 * To run a mutation, you first call `useGenerateWbcPromoteCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateWbcPromoteCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateWbcPromoteCodeMutation, { data, loading, error }] = useGenerateWbcPromoteCodeMutation({
 *   variables: {
 *      organisationToGenerateForId: // value for 'organisationToGenerateForId'
 *   },
 * });
 */
export function useGenerateWbcPromoteCodeMutation(baseOptions?: Apollo.MutationHookOptions<GenerateWbcPromoteCodeMutation, GenerateWbcPromoteCodeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GenerateWbcPromoteCodeMutation, GenerateWbcPromoteCodeMutationVariables>(GenerateWbcPromoteCodeDocument, options);
}
export type GenerateWbcPromoteCodeMutationHookResult = ReturnType<typeof useGenerateWbcPromoteCodeMutation>;
export type GenerateWbcPromoteCodeMutationResult = Apollo.MutationResult<GenerateWbcPromoteCodeMutation>;
export type GenerateWbcPromoteCodeMutationOptions = Apollo.BaseMutationOptions<GenerateWbcPromoteCodeMutation, GenerateWbcPromoteCodeMutationVariables>;
export const GetOrgAdminPromoteCodesInOrganisationDocument = gql`
    query GetOrgAdminPromoteCodesInOrganisation($getOrgAdminPromoteCodesInOrganisationId: ID!) {
  getOrgAdminPromoteCodesInOrganisation(
    id: $getOrgAdminPromoteCodesInOrganisationId
  ) {
    id
    code
  }
}
    `;

/**
 * __useGetOrgAdminPromoteCodesInOrganisationQuery__
 *
 * To run a query within a React component, call `useGetOrgAdminPromoteCodesInOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgAdminPromoteCodesInOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgAdminPromoteCodesInOrganisationQuery({
 *   variables: {
 *      getOrgAdminPromoteCodesInOrganisationId: // value for 'getOrgAdminPromoteCodesInOrganisationId'
 *   },
 * });
 */
export function useGetOrgAdminPromoteCodesInOrganisationQuery(baseOptions: Apollo.QueryHookOptions<GetOrgAdminPromoteCodesInOrganisationQuery, GetOrgAdminPromoteCodesInOrganisationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrgAdminPromoteCodesInOrganisationQuery, GetOrgAdminPromoteCodesInOrganisationQueryVariables>(GetOrgAdminPromoteCodesInOrganisationDocument, options);
}
export function useGetOrgAdminPromoteCodesInOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgAdminPromoteCodesInOrganisationQuery, GetOrgAdminPromoteCodesInOrganisationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrgAdminPromoteCodesInOrganisationQuery, GetOrgAdminPromoteCodesInOrganisationQueryVariables>(GetOrgAdminPromoteCodesInOrganisationDocument, options);
}
export type GetOrgAdminPromoteCodesInOrganisationQueryHookResult = ReturnType<typeof useGetOrgAdminPromoteCodesInOrganisationQuery>;
export type GetOrgAdminPromoteCodesInOrganisationLazyQueryHookResult = ReturnType<typeof useGetOrgAdminPromoteCodesInOrganisationLazyQuery>;
export type GetOrgAdminPromoteCodesInOrganisationQueryResult = Apollo.QueryResult<GetOrgAdminPromoteCodesInOrganisationQuery, GetOrgAdminPromoteCodesInOrganisationQueryVariables>;
export const GetWbcPromoteCodesInOrganisationDocument = gql`
    query GetWbcPromoteCodesInOrganisation($getWbcPromoteCodesInOrganisationId: ID!) {
  getWbcPromoteCodesInOrganisation(id: $getWbcPromoteCodesInOrganisationId) {
    id
    code
  }
}
    `;

/**
 * __useGetWbcPromoteCodesInOrganisationQuery__
 *
 * To run a query within a React component, call `useGetWbcPromoteCodesInOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbcPromoteCodesInOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbcPromoteCodesInOrganisationQuery({
 *   variables: {
 *      getWbcPromoteCodesInOrganisationId: // value for 'getWbcPromoteCodesInOrganisationId'
 *   },
 * });
 */
export function useGetWbcPromoteCodesInOrganisationQuery(baseOptions: Apollo.QueryHookOptions<GetWbcPromoteCodesInOrganisationQuery, GetWbcPromoteCodesInOrganisationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetWbcPromoteCodesInOrganisationQuery, GetWbcPromoteCodesInOrganisationQueryVariables>(GetWbcPromoteCodesInOrganisationDocument, options);
}
export function useGetWbcPromoteCodesInOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWbcPromoteCodesInOrganisationQuery, GetWbcPromoteCodesInOrganisationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetWbcPromoteCodesInOrganisationQuery, GetWbcPromoteCodesInOrganisationQueryVariables>(GetWbcPromoteCodesInOrganisationDocument, options);
}
export type GetWbcPromoteCodesInOrganisationQueryHookResult = ReturnType<typeof useGetWbcPromoteCodesInOrganisationQuery>;
export type GetWbcPromoteCodesInOrganisationLazyQueryHookResult = ReturnType<typeof useGetWbcPromoteCodesInOrganisationLazyQuery>;
export type GetWbcPromoteCodesInOrganisationQueryResult = Apollo.QueryResult<GetWbcPromoteCodesInOrganisationQuery, GetWbcPromoteCodesInOrganisationQueryVariables>;
export const GetVocationsDocument = gql`
    query GetVocations {
  getVocations {
    id
    name
    vocationSpecialities {
      name
      id
    }
    vocationGroup {
      id
      name
    }
  }
}
    `;

/**
 * __useGetVocationsQuery__
 *
 * To run a query within a React component, call `useGetVocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetVocationsQuery, GetVocationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetVocationsQuery, GetVocationsQueryVariables>(GetVocationsDocument, options);
}
export function useGetVocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVocationsQuery, GetVocationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetVocationsQuery, GetVocationsQueryVariables>(GetVocationsDocument, options);
}
export type GetVocationsQueryHookResult = ReturnType<typeof useGetVocationsQuery>;
export type GetVocationsLazyQueryHookResult = ReturnType<typeof useGetVocationsLazyQuery>;
export type GetVocationsQueryResult = Apollo.QueryResult<GetVocationsQuery, GetVocationsQueryVariables>;
export const GetLocationByUniqueIdDocument = gql`
    query GetLocationByUniqueId($ethoUniqueId: ID!) {
  getLocationByUniqueId(id: $ethoUniqueId) {
    id
    displayName
  }
}
    `;

/**
 * __useGetLocationByUniqueIdQuery__
 *
 * To run a query within a React component, call `useGetLocationByUniqueIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationByUniqueIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationByUniqueIdQuery({
 *   variables: {
 *      ethoUniqueId: // value for 'ethoUniqueId'
 *   },
 * });
 */
export function useGetLocationByUniqueIdQuery(baseOptions: Apollo.QueryHookOptions<GetLocationByUniqueIdQuery, GetLocationByUniqueIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLocationByUniqueIdQuery, GetLocationByUniqueIdQueryVariables>(GetLocationByUniqueIdDocument, options);
}
export function useGetLocationByUniqueIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationByUniqueIdQuery, GetLocationByUniqueIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLocationByUniqueIdQuery, GetLocationByUniqueIdQueryVariables>(GetLocationByUniqueIdDocument, options);
}
export type GetLocationByUniqueIdQueryHookResult = ReturnType<typeof useGetLocationByUniqueIdQuery>;
export type GetLocationByUniqueIdLazyQueryHookResult = ReturnType<typeof useGetLocationByUniqueIdLazyQuery>;
export type GetLocationByUniqueIdQueryResult = Apollo.QueryResult<GetLocationByUniqueIdQuery, GetLocationByUniqueIdQueryVariables>;
export const GetKpisDocument = gql`
    query GetKpis {
  getStickyReportData
}
    `;

/**
 * __useGetKpisQuery__
 *
 * To run a query within a React component, call `useGetKpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKpisQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKpisQuery(baseOptions?: Apollo.QueryHookOptions<GetKpisQuery, GetKpisQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetKpisQuery, GetKpisQueryVariables>(GetKpisDocument, options);
}
export function useGetKpisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKpisQuery, GetKpisQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetKpisQuery, GetKpisQueryVariables>(GetKpisDocument, options);
}
export type GetKpisQueryHookResult = ReturnType<typeof useGetKpisQuery>;
export type GetKpisLazyQueryHookResult = ReturnType<typeof useGetKpisLazyQuery>;
export type GetKpisQueryResult = Apollo.QueryResult<GetKpisQuery, GetKpisQueryVariables>;
export const GetEthoOwnerReportDataDocument = gql`
    query GetEthoOwnerReportData($reportType: NonEmptyString!, $id: ID!) {
  getEthoOwnerReportData(type: $reportType, id: $id)
}
    `;

/**
 * __useGetEthoOwnerReportDataQuery__
 *
 * To run a query within a React component, call `useGetEthoOwnerReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEthoOwnerReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEthoOwnerReportDataQuery({
 *   variables: {
 *      reportType: // value for 'reportType'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEthoOwnerReportDataQuery(baseOptions: Apollo.QueryHookOptions<GetEthoOwnerReportDataQuery, GetEthoOwnerReportDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEthoOwnerReportDataQuery, GetEthoOwnerReportDataQueryVariables>(GetEthoOwnerReportDataDocument, options);
}
export function useGetEthoOwnerReportDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEthoOwnerReportDataQuery, GetEthoOwnerReportDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetEthoOwnerReportDataQuery, GetEthoOwnerReportDataQueryVariables>(GetEthoOwnerReportDataDocument, options);
}
export type GetEthoOwnerReportDataQueryHookResult = ReturnType<typeof useGetEthoOwnerReportDataQuery>;
export type GetEthoOwnerReportDataLazyQueryHookResult = ReturnType<typeof useGetEthoOwnerReportDataLazyQuery>;
export type GetEthoOwnerReportDataQueryResult = Apollo.QueryResult<GetEthoOwnerReportDataQuery, GetEthoOwnerReportDataQueryVariables>;
export const GetOrgAdminReportsDocument = gql`
    query GetOrgAdminReports($organisationId: ID!) {
  getCurrentOrganisationTeamMoraleReportData(id: $organisationId) {
    prevPrevMonth
    prevMonth
    currentMonth
  }
  getCurrentTotalDaysWorkedReportData(id: $organisationId)
  getLocationUsageReportData(id: $organisationId)
  getDailyBreakdownReportData(id: $organisationId)
}
    `;
export const GetActiveTeamCheckInByRole = gql`
query GetOrganisations($organisationId: ID!, $vocationGroupId: ID!) {
  getActiveTeamCheckInForVocationGroupReportData(organisationId: $organisationId, vocationGroupId: $vocationGroupId)
}
`;

export const GetActiveTeamCheckInByLocation = gql`
query Query($organisationId: ID!, $locationId: ID!) {
  getActiveTeamCheckInForLocationReportData(organisationId: $organisationId, locationId: $locationId)
}
`

/**
 * __useGetOrgAdminReportsQuery__
 *
 * To run a query within a React component, call `useGetOrgAdminReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgAdminReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgAdminReportsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetOrgAdminReportsQuery(baseOptions: Apollo.QueryHookOptions<GetOrgAdminReportsQuery, GetOrgAdminReportsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrgAdminReportsQuery, GetOrgAdminReportsQueryVariables>(GetOrgAdminReportsDocument, options);
}
export function useGetOrgAdminReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgAdminReportsQuery, GetOrgAdminReportsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrgAdminReportsQuery, GetOrgAdminReportsQueryVariables>(GetOrgAdminReportsDocument, options);
}
export function useGetActiveTeamCheckInByRole(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgAdminReportsQuery, GetActiveTeamCheckInByRoleVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<any, GetActiveTeamCheckInByRoleVariables>(GetActiveTeamCheckInByRole, options);
}
export function useGetActiveTeamCheckInByLocation(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgAdminReportsQuery, GetActiveTeamCheckInByLocationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<any, GetActiveTeamCheckInByLocationVariables>(GetActiveTeamCheckInByLocation, options);
}
export type GetOrgAdminReportsQueryHookResult = ReturnType<typeof useGetOrgAdminReportsQuery>;
export type GetOrgAdminReportsLazyQueryHookResult = ReturnType<typeof useGetOrgAdminReportsLazyQuery>;
export type GetOrgAdminReportsQueryResult = Apollo.QueryResult<GetOrgAdminReportsQuery, GetOrgAdminReportsQueryVariables>;
export const DeleteOrganisationDocument = gql`
    mutation DeleteOrganisation($organisationId: ID!) {
  deleteOrganisation(id: $organisationId) {
    id
  }
}
    `;
export type DeleteOrganisationMutationFn = Apollo.MutationFunction<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>;

/**
 * __useDeleteOrganisationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganisationMutation, { data, loading, error }] = useDeleteOrganisationMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useDeleteOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>(DeleteOrganisationDocument, options);
}
export type DeleteOrganisationMutationHookResult = ReturnType<typeof useDeleteOrganisationMutation>;
export type DeleteOrganisationMutationResult = Apollo.MutationResult<DeleteOrganisationMutation>;
export type DeleteOrganisationMutationOptions = Apollo.BaseMutationOptions<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($locationId: ID!) {
  deleteLocation(id: $locationId) {
    id
  }
}
    `;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useDeleteLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
}
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($updateLocationId: ID!, $name: NonEmptyString, $displayName: NonEmptyString, $addressLine: NonEmptyString, $country: NonEmptyString, $county: NonEmptyString, $city: NonEmptyString, $postcode: NonEmptyString, $cqcLocationId: String, $lat: Float, $long: Float, $organisationIds: [ID!]) {
  updateLocation(
    id: $updateLocationId
    name: $name
    displayName: $displayName
    addressLine: $addressLine
    country: $country
    county: $county
    city: $city
    cqcLocationId: $cqcLocationId
    postcode: $postcode
    lat: $lat
    long: $long
    organisationIds: $organisationIds
  ) {
    id
    name
  }
}
    `;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      updateLocationId: // value for 'updateLocationId'
 *      name: // value for 'name'
 *      displayName: // value for 'displayName'
 *      addressLine: // value for 'addressLine'
 *      country: // value for 'country'
 *      county: // value for 'county'
 *      city: // value for 'city'
 *      postcode: // value for 'postcode'
 *      cqcLocationId: // value for 'cqcLocationId'
 *      lat: // value for 'lat'
 *      long: // value for 'long'
 *      organisationIds: // value for 'organisationIds'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
}
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const UpdateOrganisationDocument = gql`
    mutation UpdateOrganisation($mainAddressId: ID!, $updateOrganisationId: ID!, $type: OrganisationType, $adminEmail: NonEmptyString, $adminFirstName: NonEmptyString, $adminLastName: NonEmptyString, $adminPhone: NonEmptyString, $adminDataSharingConsent: Boolean, $adminShareMyScores: Boolean, $adminContributeToPracticeScore: Boolean, $adminVocationId: ID, $adminMemberVocationSpecialityId: ID, $displayName: NonEmptyString, $providerName: NonEmptyString, $odsCode: String, $cqcLocationId: String, $locationIds: [ID!], $subnetworkIds: [ID!]) {
  updateOrganisation(
    mainAddressId: $mainAddressId
    id: $updateOrganisationId
    type: $type
    adminEmail: $adminEmail
    adminFirstName: $adminFirstName
    adminLastName: $adminLastName
    adminPhone: $adminPhone
    adminDataSharingConsent: $adminDataSharingConsent
    adminShareMyScores: $adminShareMyScores
    adminContributeToPracticeScore: $adminContributeToPracticeScore
    adminVocationId: $adminVocationId
    adminMemberVocationSpecialityId: $adminMemberVocationSpecialityId
    displayName: $displayName
    providerName: $providerName
    odsCode: $odsCode
    cqcLocationId: $cqcLocationId
    locationIds: $locationIds
    subnetworkIds: $subnetworkIds
  ) {
    id
    displayName
  }
}
    `;
export type UpdateOrganisationMutationFn = Apollo.MutationFunction<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;

/**
 * __useUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationMutation, { data, loading, error }] = useUpdateOrganisationMutation({
 *   variables: {
 *      mainAddressId: // value for 'mainAddressId'
 *      updateOrganisationId: // value for 'updateOrganisationId'
 *      type: // value for 'type'
 *      adminEmail: // value for 'adminEmail'
 *      adminFirstName: // value for 'adminFirstName'
 *      adminLastName: // value for 'adminLastName'
 *      adminPhone: // value for 'adminPhone'
 *      adminDataSharingConsent: // value for 'adminDataSharingConsent'
 *      adminShareMyScores: // value for 'adminShareMyScores'
 *      adminContributeToPracticeScore: // value for 'adminContributeToPracticeScore'
 *      adminVocationId: // value for 'adminVocationId'
 *      adminMemberVocationSpecialityId: // value for 'adminMemberVocationSpecialityId'
 *      displayName: // value for 'displayName'
 *      providerName: // value for 'providerName'
 *      odsCode: // value for 'odsCode'
 *      cqcLocationId: // value for 'cqcLocationId'
 *      locationIds: // value for 'locationIds'
 *      subnetworkIds: // value for 'subnetworkIds'
 *   },
 * });
 */
export function useUpdateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>(UpdateOrganisationDocument, options);
}
export type UpdateOrganisationMutationHookResult = ReturnType<typeof useUpdateOrganisationMutation>;
export type UpdateOrganisationMutationResult = Apollo.MutationResult<UpdateOrganisationMutation>;
export type UpdateOrganisationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;
export const CheckInGuestDocument = gql`
    mutation CheckInGuest($organisationUniqueId: NonEmptyString!, $locationUniqueId: NonEmptyString!, $firstName: NonEmptyString!, $lastName: NonEmptyString!, $company: NonEmptyString!) {
  checkMyselfInGuest(
    organisationUniqueId: $organisationUniqueId
    locationUniqueId: $locationUniqueId
    firstName: $firstName
    lastName: $lastName
    company: $company
  ) {
    id
    startTimestamp
    status
  }
}
    `;
export type CheckInGuestMutationFn = Apollo.MutationFunction<CheckInGuestMutation, CheckInGuestMutationVariables>;

/**
 * __useCheckInGuestMutation__
 *
 * To run a mutation, you first call `useCheckInGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInGuestMutation, { data, loading, error }] = useCheckInGuestMutation({
 *   variables: {
 *      organisationUniqueId: // value for 'organisationUniqueId'
 *      locationUniqueId: // value for 'locationUniqueId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useCheckInGuestMutation(baseOptions?: Apollo.MutationHookOptions<CheckInGuestMutation, CheckInGuestMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CheckInGuestMutation, CheckInGuestMutationVariables>(CheckInGuestDocument, options);
}
export type CheckInGuestMutationHookResult = ReturnType<typeof useCheckInGuestMutation>;
export type CheckInGuestMutationResult = Apollo.MutationResult<CheckInGuestMutation>;
export type CheckInGuestMutationOptions = Apollo.BaseMutationOptions<CheckInGuestMutation, CheckInGuestMutationVariables>;
export const CheckOutGuestDocument = gql`
    mutation CheckOutGuest($attendanceId: ID!) {
  checkMyselfOutGuest(attendanceId: $attendanceId) {
    id
    endTimestamp
    status
  }
}
    `;
export type CheckOutGuestMutationFn = Apollo.MutationFunction<CheckOutGuestMutation, CheckOutGuestMutationVariables>;

/**
 * __useCheckOutGuestMutation__
 *
 * To run a mutation, you first call `useCheckOutGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOutGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOutGuestMutation, { data, loading, error }] = useCheckOutGuestMutation({
 *   variables: {
 *      attendanceId: // value for 'attendanceId'
 *   },
 * });
 */
export function useCheckOutGuestMutation(baseOptions?: Apollo.MutationHookOptions<CheckOutGuestMutation, CheckOutGuestMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CheckOutGuestMutation, CheckOutGuestMutationVariables>(CheckOutGuestDocument, options);
}
export type CheckOutGuestMutationHookResult = ReturnType<typeof useCheckOutGuestMutation>;
export type CheckOutGuestMutationResult = Apollo.MutationResult<CheckOutGuestMutation>;
export type CheckOutGuestMutationOptions = Apollo.BaseMutationOptions<CheckOutGuestMutation, CheckOutGuestMutationVariables>;