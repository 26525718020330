import React from "react"

const AddressIcon = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.25C15.3848 0.25 19.75 4.61522 19.75 10C19.75 14.1196 16.8554 18.6093 11.1402 23.5179C10.4844 24.0811 9.51558 24.0811 8.85717 23.5156L8.47942 23.1881C3.01712 18.4089 0.25 14.0278 0.25 10C0.25 4.61522 4.61522 0.25 10 0.25ZM10 6.25C7.92893 6.25 6.25 7.92893 6.25 10C6.25 12.0711 7.92893 13.75 10 13.75C12.0711 13.75 13.75 12.0711 13.75 10C13.75 7.92893 12.0711 6.25 10 6.25Z"
        fill="url(#paint0_linear_1435_928)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1435_928"
          x1="0.25"
          y1="12.2772"
          x2="19.75"
          y2="12.2772"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#43E695" />
          <stop offset="1" stopColor="#6094EA" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default AddressIcon
