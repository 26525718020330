import { ChartCard } from "../../../components"
import "./styles.scss"
import {
  CheckinsRole,
  CheckinsLocation,
  LocationUsage,
  DaysWorked,
  MoodScores,
  TeamMorale
} from "./Categories"
import { GetOrgAdminReportsQuery, useGetOrgAdminReportsQuery } from "../../../graphql/graphql"
import { AuthState } from "../../../context/auth/authProvider"


const Reports = () => {
  const { selectedOrganisation } = AuthState()

  const { data } = useGetOrgAdminReportsQuery({
    variables: {
      organisationId: selectedOrganisation.id
    }
  })

  return (
    <div className="reports-wrapper">
      <div className="report-title">Report for Organisation</div>
      <ChartCard title={"Team Morale"}>
        <TeamMorale data={data as GetOrgAdminReportsQuery} />
      </ChartCard>
      <ChartCard title={"Total days worked"}>
        <DaysWorked data={data as GetOrgAdminReportsQuery} />
      </ChartCard>
      <ChartCard title={"Location usage(Check-ins)"}>
        <LocationUsage data={data as GetOrgAdminReportsQuery} />
      </ChartCard>
      <div className="report-title">Daily Breakdown</div>
      <ChartCard title={"Mood scores"}>
        <MoodScores data={data as GetOrgAdminReportsQuery} />
      </ChartCard>
      <ChartCard title={"Active team check in - per Role"}>
        <CheckinsRole organisationId={selectedOrganisation.id} />
      </ChartCard>
      <ChartCard title={"Active team check in - per Location"}>
        <CheckinsLocation organisation={selectedOrganisation} />
      </ChartCard>
    </div>
  )
}

export default Reports
