import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  gql,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { Observable } from "@apollo/client"
import { isTokenExpired } from "../utils/jwtUtils"

export const REFRESH_TOKEN = process.env.REACT_APP_REFRESH_TOKEN || ""
export const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN || ""

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  
  return {
    headers: {
      ...headers,
      Authorization: token ? `${token}` : "",
    },
  }
})

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    if (graphQLErrors.some((error) => error.message === "Not Authorised!")) {
      return new Observable((observer: any) => {
        refreshToken()
          .then((res: any) => {
            if (res.data.getRefreshToken.accessToken) {
              localStorage.setItem(ACCESS_TOKEN, res.data.getRefreshToken.accessToken)
            }
            if (res.data.getRefreshToken.refreshToken) {
              localStorage.setItem(REFRESH_TOKEN, res.data.getRefreshToken.refreshToken)
            }

            operation.setContext(({ headers = {} }) => ({
              headers: {
                // Re-add old headers
                ...headers,
                // Switch out old access token for new one
                Authorization: `${res.data.getRefreshToken.accessToken}` || null,
              }
            }))
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            }

            // Retry last failed request
            forward(operation).subscribe(subscriber)
          })
          .catch((error: any) => {
            // No refresh or client token available, we force user to login
            observer.error(error)
          })
      }
      )
    } else if (graphQLErrors[0].path && graphQLErrors[0]?.path[0] === "getRefreshToken") {
      localStorage.removeItem(ACCESS_TOKEN)
      localStorage.removeItem(REFRESH_TOKEN)
    }
  }
})

const cache = new InMemoryCache({
  
})

const refreshToken = async () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN) || ""

  if (!refreshToken || isTokenExpired(refreshToken)) {
    return new Promise((res, rej) => rej("Refresh token is expired."))
  }

  const refreshTokenResponse = browserClient.mutate({
    mutation: gql`
      mutation getRefreshToken($refreshToken: NonEmptyString!) {
        getRefreshToken(refreshToken: $refreshToken) {
          refreshToken
          accessToken
        }
      }
    `,
    variables: {
      refreshToken: refreshToken,
    },
  })

  return refreshTokenResponse
}

export const browserClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache: cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    mutate: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  }
})
