import GradientButton from "../../Buttons/GradientButton/GradientButton"
import Card from "../../Card/Card"
import "./styles.scss"

export const ConfirmationCodeModal = ({
  code,
  closeModal
}: {
  code: string;
  closeModal: () => void;
}) => {
  return (
    <Card width="40vw">
      <div className="confirm-code-modal-main">
        Success! Generated code is:
        <span>
            {code}
            <img title="Copy to clipboard" src="./assets/copyIcon.svg" alt="" className="copy-icon" onClick={() => {
                        // Copy org id to clipboard
                        navigator.clipboard.writeText(code)
            }} />
        </span>
        <div className="button-container">
          <GradientButton
            styles={{ marginLeft: "5px" }}
            width="100%"
            onClick={closeModal}
          >
            Close
          </GradientButton>
        </div>
      </div>
    </Card>
  )
}
