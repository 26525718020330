import React from "react"
import type { FC } from "react"

const OrganizationIcon: FC<{
  width?: string;
  height?: string;
}> = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2 1.875L0.75 0.25H3.625L5.25 3.5H7.5L9.25 0.25H17.5L16 2.25V15.5H14V7H10V15.5H2V1.875ZM4 7H7.5V10.5H4V7Z" fill="currentColor" />
    </svg>


  )
}

export default OrganizationIcon
