import "./style.scss"

const Loader = () => {
  return (
    <div className='loader'>
      <img src="../../assets/loader.svg" width={50} height={50} alt="Loading..." loading="eager" />
    </div>
  )
}

export default Loader