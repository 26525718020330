import { FC, useMemo } from "react"
import { BarChart } from "../../../../../components"
import { GetOrgAdminReportsQuery } from "../../../../../graphql/graphql"

interface DaysWorkedProps {
  data: GetOrgAdminReportsQuery
}

const DaysWorked: FC<DaysWorkedProps> = ({ data }) => {
  const adjustedLabels = useMemo(() => {
    if (data?.getCurrentTotalDaysWorkedReportData)
      return Object.entries(data?.getCurrentTotalDaysWorkedReportData).map((pair) => pair[0])
    return []
  }, [data])

  const computedValues = useMemo(() => {
    if (data?.getCurrentTotalDaysWorkedReportData) {
      return [
        {
          label: "Last Month",
          data: Object.entries(data?.getCurrentTotalDaysWorkedReportData).map((pair: any) => {
            return pair[1].prevPrevMonth
          }),
          backgroundColor: "#D7D0D5"
        },
        {
          label: "Last Month",
          data: Object.entries(data?.getCurrentTotalDaysWorkedReportData).map((pair: any) => {
            return pair[1].prevMonth
          }),
          backgroundColor: "#C29CBA"
        },
        {
          label: "Current Month",
          data: Object.entries(data?.getCurrentTotalDaysWorkedReportData).map((pair: any) => {
            return pair[1].currentMonth
          }),
          backgroundColor: "#F030C1"
        }
      ]
    }
    return []
  }, [data])

  const workedDaysData = {
    labels: adjustedLabels,
    datasets: computedValues
  }

  return <BarChart data={workedDaysData} chartType="daysWorked" />
}

export default DaysWorked
