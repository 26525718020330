import React, { useEffect, useState } from "react"
import "./styles.scss"
import type { FC } from "react"
import { TabProps, TabsProps } from "./types"

export const Tab: FC<TabProps> = ({ title, onClick, active = false }) => {
  const onClickTab = (e: any) => {
    e.preventDefault(0)
    onClick(title)
  }

  return (
    <>
      <li className={`${active ? "active" : ""} tab-item`} onClick={onClickTab}>
        {title}
        <div className={active ? "active-underline" : ""}></div>
      </li>
    </>
  )
}

export const Tabs: FC<TabsProps> = ({ children, onClickCallback }) => {
  const [activeTab, setActiveTab] = useState(null)
  useEffect(() => {
    if (children) {
      setActiveTab(children[0].props.title)
    }

    if (children.length > 2 && Boolean(children[2])) {
      setActiveTab(children[2].props.title)
    }
  }, [children])

  const onClickTabItem = (tab: React.SetStateAction<null>) => {
    setTimeout(() => setActiveTab(tab), 50);
    onClickCallback()
  }

  return (
    <div className="tabs">
      <ul className="tab-list">
        {children?.map((tab: { props: { title: any } }, index: number) => {
          if (!tab || !tab.props) return null

          const { title } = tab.props

          return (
            <Tab
              key={index}
              title={title}
              onClick={onClickTabItem}
              active={title === activeTab ? true : false}
            />
          )
        })}
      </ul>

      <div className="tab-content">
        {children.map((tab: { props: { title: null; children: any } }) => {
          if (!tab || !tab.props) return null
          if (tab.props.title !== activeTab) return undefined

          return tab.props.children
        })}
      </div>
    </div>
  )
}

export default Tabs
