import "./styles.scss"
import { AuthState } from "../../../context/auth/authProvider"
import OrganisationAdminHome from "./OrganisationAdminHome"
import { ROLES } from "../../../utils/roles"
import EthoOwnerHome from "./EthoOwnerHome"
import { Loader } from "../../../components"

function Home() {
  const { user } = AuthState()

  return (
    <div>
      {user && user.role ? (
        user.role === ROLES.ORG_ADMIN ? (
          <OrganisationAdminHome user={user} />
        ) : (
          <EthoOwnerHome user={user} />
        )
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default Home
