import type { FC } from "react"
import { Card } from "../../index"
import { ChartCardProps } from "./types"
import "./styles.scss"

const ChartCard: FC<ChartCardProps> = ({ title, children }) => {
  return (
    <div className="chart-card-wrapper">
      <div className="card-title">{title}</div>
      <Card width="65vw">
        <div className="chart-group">{children}</div>
      </Card>
    </div>
  )
}

export default ChartCard
