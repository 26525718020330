import {
  Card,
  GradientButton,
  Field,
  SelectField,
  SwitchButton,
  InsertNetworkModal,
  CreateNetworkModal,
  CreateSubnetworkModal
} from "../../index"
import { useOrganisationForm } from "./hooks"
import { Multiselect } from "multiselect-react-dropdown"
import PhoneInput from "react-phone-input-2"
import "./styles.scss"
import { FC, useEffect, useState } from "react"
import { InsertedNetworkType, OrganisationFormProps } from "./types"
import { useModal } from "../../Modals/Modal"
import { OutlineButton } from "../../index"
import { ToastContainer, toast } from "react-toast"

type MODALS = "INSERT" | "CREATE_NETWORK" | "CREATE_SUBNETWORK"

export const organisationTypeOptions = [
  { label: "GP Practice", id: "GP_Practice" },
  { label: "Integrated Care Board", id: "Integrated_Care_Board" },
  { label: "Ltd Company", id: "Ltd_Company" },
  { label: "County Council", id: "County_Council" },
  { label: "National NHS body", id: "National_NHS_body" },
  { label: "Local Medical Committee", id: "Local_Medical_Committee" },
  { label: "Peer Support Group", id: "Peer_Support_Group" },
  { label: "Residential Care Provider", id: "Residential_Care_Provider" },
  { label: "Community Care Provider", id: "Community_Care_Provider" },
  { label: "NHS Foundation Trust", id: "NHS_Foundation_Trust" },
  { label: "Hospital Department", id: "Hospital_Department" }
]

const OrganisationForm: FC<OrganisationFormProps> = ({
  refetchOrganisations,
  isUpdateForm,
  updateOrgId,
  closeTab
}) => {
  const {
    simpleInputs,
    handleChange,
    locationOptions,
    submitForm,
    selectedLocations,
    handleSelectOrganisationType,
    handleVocation,
    handleVocationSpeciality,
    handleRemove,
    handleSelect,
    handleCheckbox,
    isSameAsProvider,
    phoneValue,
    setPhoneValue,
    addSelectedOption,
    setInsertedNetworks,
    networksData,
    insertedNetworks,
    subNetworksOptions,
    networksOptions,
    organisationOptions,
    vocationOptions,
    vocationSpecialityOptions,
    handleDeleteOrganisation,
    refetchNetwoks,
    refetchSubnetworks,
    handleSelectedAddress,
    selectedAddress,
    locationOptionsSingleChoice,
    selectedVocation,
    selectedType,
    selectedVocationSpeciality,
    multiselectRef
  } = useOrganisationForm(refetchOrganisations, toast, isUpdateForm, updateOrgId, closeTab)
  const [selectedModal, setSelectedModal] = useState<MODALS | "">("")

  const { Modal, close, open } = useModal()

  const handleOpenModal = (modal: MODALS) => {
    setSelectedModal(modal)
  }

  const handlePhoneInput = (phoneValue: string) => {
    let val = phoneValue;
    if (phoneValue[3] === "0") {
      val = phoneValue.replace("0", "")
    }

    if (phoneValue[0] !== "+") {
      val = `+${val}`
    }

    setPhoneValue(val);
  }

  const handleCloseModal = () => {
    setSelectedModal("")
    close()
  }

  useEffect(() => {
    if (selectedModal?.length) {
      open()
    }
  }, [selectedModal])

  return (
    <div className="organisations-card-wrapper">
      <form>
        <Card width="100%">
          <div>
            <div>
              <div className="form-category">
                <div className="grid">
                  <div style={{ marginBottom: "20px" }}>
                    <div className="category-title">General information</div>

                    <div className="inputs-wrapper">
                      <Field
                        name="providerName"
                        value={simpleInputs.providerName}
                        handleChange={handleChange}
                        label={"Provider name"}
                        type="text"
                      />
                    </div>

                    <div className="inputs-wrapper">
                      <Field
                        name="displayName"
                        value={simpleInputs.displayName}
                        handleChange={handleChange}
                        label={"Display name"}
                        type="text"
                      />
                    </div>

                    <div className="toggle-button-wrapper">
                      Same as provider name
                      <SwitchButton
                        isChecked={isSameAsProvider}
                        handleChange={handleCheckbox}
                      />
                    </div>

                    <Multiselect
                      ref={multiselectRef}
                      placeholder="Link location"
                      options={locationOptions}
                      selectedValues={selectedLocations}
                      onSelect={handleSelect}
                      onRemove={handleRemove}
                      displayValue="name"
                    />

                    <div className="type-button-wrapper">
                      <SelectField
                        name={"type"}
                        selectedValue={selectedType}
                        options={organisationTypeOptions}
                        addSelectedOption={handleSelectOrganisationType}
                        label={"Organisation type"}
                      />
                    </div>
                  </div>


                    <div style={{ marginBottom: "20px" }}>
                      <div className="category-title">
                        {!isUpdateForm ? "Primary admin information" : "Add another admin"}
                      </div>
                      <div className="inputs-wrapper">
                        <Field
                          name="adminFirstName"
                          value={simpleInputs.adminFirstName}
                          handleChange={handleChange}
                          label={"First name"}
                          type="text"
                        />
                      </div>
                      <div className="inputs-wrapper name">
                        <Field
                          name="adminLastName"
                          value={simpleInputs.adminLastName}
                          handleChange={handleChange}
                          label={"Last name"}
                          type="text"
                        />
                      </div>
                      <div className="inputs-wrapper phone">
                        <PhoneInput
                          inputClass="phone-input"
                          containerClass=""
                          country={"gb"}
                          specialLabel="Phone number"
                          onlyCountries={["gb"]}
                          showDropdown={false}
                          disableDropdown={true}
                          value={phoneValue}
                          onChange={handlePhoneInput}
                        />
                      </div>
                      <div className="inputs-wrapper">
                        <Field
                          name="adminEmail"
                          value={simpleInputs.adminEmail}
                          handleChange={handleChange}
                          label={"Email"}
                          type="email"
                        />
                      </div>
                      <div className="inputs-wrapper">
                        <SelectField
                          selectedValue={selectedVocation}
                          name={"vocation"}
                          options={vocationOptions}
                          addSelectedOption={handleVocation}
                          label={"Vocation"}
                        />
                      </div>
                      <div className="inputs-wrapper">
                        <SelectField
                          selectedValue={selectedVocationSpeciality}
                          name={"vocationSpeciality"}
                          options={vocationSpecialityOptions}
                          addSelectedOption={handleVocationSpeciality}
                          label={"Role"}
                        />
                      </div>
                    </div>

                  <div>
                    <div className="category-title">Location information</div>
                    <div className="type-button-wrapper">
                      <SelectField
                        name={"name"}
                        selectedValue={selectedAddress}
                        options={locationOptionsSingleChoice}
                        addSelectedOption={handleSelectedAddress}
                        label={"Main address location"}
                      />
                    </div>

                    <div className="category-title">Other information</div>
                    <div className="inputs-wrapper">
                      <Field
                        name="odsCode"
                        value={simpleInputs.odsCode}
                        handleChange={handleChange}
                        label={"ODS code"}
                        type="text"
                        required={false}
                      />
                    </div>
                    <div className="inputs-wrapper">
                      <Field
                        name="cqc"
                        value={simpleInputs.cqc}
                        handleChange={handleChange}
                        label={"CQC location ID"}
                        type="text"
                        required={false}
                      />
                    </div>
                    {isUpdateForm && <div className="inputs-wrapper">
                      <Field
                        name="uniqueIdentifier"
                        value={simpleInputs.uniqueIdentifier}
                        label={"Etho unique ID"}
                        type="text"
                      />
                    </div>}
                  </div>


                  <div>
                    <div className="network-buttons">
                      <div className="category-title network">Networks</div>
                      {insertedNetworks.map((network: InsertedNetworkType) => {
                        return (
                          <div
                            key={Math.random()}
                            className="network-input-wrapper"
                          >
                            <SelectField
                              name={network.name}
                              options={network.options}
                              addSelectedOptions={addSelectedOption}
                              selectedValue={network.selectedSubnetwork as string}
                              label={network.name}
                            />
                          </div>
                        )
                      })}

                      <div className="network-buttons">
                        <OutlineButton
                          width="100%"
                          onClick={() => handleOpenModal("CREATE_SUBNETWORK")}
                        >
                          Create network
                        </OutlineButton>
                        <OutlineButton
                          width="100%"
                          onClick={() => handleOpenModal("INSERT")}
                        >
                          Insert network type
                        </OutlineButton>
                        <OutlineButton
                          width="100%"
                          onClick={() => handleOpenModal("CREATE_NETWORK")}
                        >
                          Create network type
                        </OutlineButton>
                      </div>
                    </div>
                  </div>

                  {/* <div>
                    <div className="category-title">Location information</div>
                    <div className="inputs-wrapper">
                      <Field
                        name="addressLine1"
                        value={simpleInputs.addressLine1}
                        handleChange={handleChange}
                        label={"Address Line 1"}
                        type="text"
                      />
                    </div>
                    <div className="inputs-wrapper">
                      <Field
                        name="addressLine2"
                        value={simpleInputs.addressLine2}
                        handleChange={handleChange}
                        label={"Address Line 2"}
                        type="text"
                      />
                    </div>
                    <div className="inputs-wrapper">
                      <Field
                        name="postCode"
                        value={simpleInputs.postCode}
                        handleChange={handleChange}
                        label={"Post Code"}
                        type="text"
                      />
                    </div>

                    <div className="inputs-wrapper">
                      <Field
                        name="lat"
                        value={simpleInputs.lat}
                        handleChange={handleChange}
                        label={"Latitude"}
                        type="text"
                      />
                    </div>
                    <div className="inputs-wrapper">
                      <Field
                        name="long"
                        value={simpleInputs.long}
                        handleChange={handleChange}
                        label={"Longitude"}
                        type="text"
                      />
                    </div>
                    <div className="inputs-wrapper">
                      <Field
                        name="country"
                        value={simpleInputs.country}
                        handleChange={handleChange}
                        label={"Country"}
                        type="text"
                      />
                    </div>
                    <div className="inputs-wrapper">
                      <Field
                        name="county"
                        value={simpleInputs.county}
                        handleChange={handleChange}
                        label={"County"}
                        type="text"
                      />
                    </div>
                    <div className="inputs-wrapper">
                      <Field
                        name="city"
                        value={simpleInputs.city}
                        handleChange={handleChange}
                        label={"City/Town"}
                        type="text"
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="create-organisation">
            {!isUpdateForm && <GradientButton onClick={submitForm} type="submit" width="100%">
              Create organisation
            </GradientButton>}

            {isUpdateForm && <div className="update">
              <GradientButton onClick={submitForm} type="submit" width="49%">
                Update organisation
              </GradientButton>
              <OutlineButton onClick={handleDeleteOrganisation} width="49%" showIcon={false}>
                Delete organisation
              </OutlineButton>
            </div>}
          </div>
        </Card>
      </form>
      <Modal customClose={handleCloseModal}>
          {selectedModal === "CREATE_SUBNETWORK" && (
          <CreateSubnetworkModal
            close={handleCloseModal}
            refetchNetworks={refetchNetwoks}
            refetchSubNetworks={refetchSubnetworks}
            networkOptions={networksOptions}
            setInsertedNetworks={setInsertedNetworks}
            insertedNetworks={insertedNetworks as any}
            organisationOptions={organisationOptions}
          />
        )}
        {selectedModal === "INSERT" && (
          <InsertNetworkModal
            close={handleCloseModal}
            networks={networksData}
            setInsertedNetworks={setInsertedNetworks}
            insertedNetworks={insertedNetworks}
          />
        )}
        {selectedModal === "CREATE_NETWORK" && (
          <CreateNetworkModal
            close={handleCloseModal}
            subnetworks={subNetworksOptions}
            refetchNetworks={refetchNetwoks}
          />
        )}
      </Modal>
      <ToastContainer position="bottom-right"/>
    </div>
  )
}

export default OrganisationForm
