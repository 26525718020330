import { FC } from "react"
import "./styles.scss"
interface CheckboxProps {
  label: JSX.Element
  value: boolean
  onClick: () => void
}

const Checkbox: FC<CheckboxProps> = (props) => {
  return (
    <label className="checkbox__wrapper input--label">
      <input
        onClick={props.onClick}
        type="checkbox"
        defaultChecked={props.value}
      />
      {props.label}
    </label>
  )
}

export default Checkbox