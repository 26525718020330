import { Card, GenerateReportForm, Loader } from "../../../components"
import { useHomePage } from "./hooks"

const EthoOwnerHome = ({ user }: { user: any }) => {
  const { keyPerformanceIndicators } = useHomePage()

  return (
    <div className="home-page-wrapper small-padding no-align">
      {user ? (
        <>
          <div className="kpi-row">
            {keyPerformanceIndicators?.map((kpi, index) => (
              <Card
                key={index}
                width="12.125em"
                minHeight="3.5625em"
                isSmall
                isFlex
              >
                <div className="kpi-data">{kpi.data}</div>
                <div className="kpi-label">{kpi.label}</div>
              </Card>
            ))}
          </div>
          <GenerateReportForm />
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default EthoOwnerHome
