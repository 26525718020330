import { Card, Network } from "../../index"
import type { FC } from "react"
import { InsertNetworkModalProps } from "./types"
import "./styles.scss"

const InsertNetworkModal: FC<InsertNetworkModalProps> = ({
  networks,
  setInsertedNetworks,
  insertedNetworks,
  close
}) => {
  const handleNetworkSelect = (name: any, subnetworks: any, id: any) => {
    setInsertedNetworks((prevState: any) => {
      return [
        ...prevState,
        {
          id: id,
          name: name,
          selectedSubnetwork: null,
          options: subnetworks.map((subnetwork: any) => {
            return {
              key: subnetwork.id,
              label: subnetwork.name,
              value: subnetwork.id,
            }
          }),
        },
      ]
    })
    close()
  }

  return (
    <div>
      <Card width="45vw">
        <div className="insert-network">Insert network</div>
        <div
          className="networks-container"
          style={{ overflow: "scroll", height: "40vh" }}
        >
          {networks?.map((network: any, index: number) => {
            const { name, id, subnetworks } = network
            if (insertedNetworks.filter((insertedNetwork: { id: string; }) => insertedNetwork.id === id).length === 0)
              return (
                <Network
                  key={`${Math.floor(Math.random() * 10000)}`}
                  name={name}
                  onClick={() => handleNetworkSelect(name, subnetworks, id)}
                />
              )

            return null
          })}
        </div>
      </Card>
    </div>
  )
}

export default InsertNetworkModal
