import React from "react"
import type { FC } from "react"

const Logo: FC<{
  width?: string;
  height?: string;
}> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_189_2512)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.4669 28.8436L39.3944 28.4475H32.2289V30.1034C32.2289 34.721 35.6651 37.5669 41.2821 37.5669C43.9002 37.5669 45.2634 37.0534 45.5915 36.9134V31.0361C45.1002 31.1294 40.4523 33.0069 39.4669 28.8436Z"
          fill="#58595B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.2278 25.1982C25.2278 17.6385 19.6916 12.5476 12.7448 12.5476C5.74596 12.5476 -0.000244141 17.4329 -0.000244141 24.992C-0.000244141 32.5517 5.74596 37.5908 12.7448 37.5908C16.8705 37.5908 21.415 36.3057 23.8705 32.706L18.5945 29.1576C17.3939 30.7001 15.2512 31.4198 13.267 31.4198C11.0395 31.4198 8.57695 30.6016 7.53426 28.4109H7.53367L7.5325 28.408C7.42198 28.146 5.46174 23.6954 8.65356 20.4718C8.68806 20.4372 8.71204 20.413 8.71204 20.413C9.8635 19.2759 11.481 18.8211 12.7968 18.8211C14.8343 18.8211 17.55 20.0042 17.9155 22.9354H8.30326C7.8828 23.6954 7.65063 25.9213 8.26291 27.4091H25.1237C25.1758 26.7924 25.2278 25.8154 25.2278 25.1982Z"
          fill="#58595B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.3486 30.4615C83.648 30.1874 81.4755 28.1032 81.4755 24.9924C81.4755 21.9771 83.648 19.8549 86.3486 19.5751V12.5762C79.7317 12.8577 74.3206 17.659 74.3206 24.9924C74.3206 32.3258 79.7317 37.1748 86.3486 37.4593V30.4615Z"
          fill="#58595B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.4509 12.5706V19.5666C90.255 19.7923 92.4971 21.9301 92.4971 24.9925C92.4971 28.1523 90.255 30.2492 87.4509 30.4697V37.4652C94.1264 37.2354 99.6521 32.3703 99.6521 24.9925C99.6521 17.6142 94.1264 12.798 87.4509 12.5706Z"
          fill="#58595B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.3105 37.0772H55.5708V0.00012207H48.3105V37.0772Z"
          fill="#58595B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.4127 12.5475C60.6443 12.5475 58.1367 13.3127 56.6215 15.0232V21.0842C57.5794 19.9396 59.0489 19.49 60.4273 19.49C62.6215 19.49 64.9197 21.1355 64.9197 24.2722V37.0771H72.1799V24.0149C72.1799 16.7643 68.8372 12.5475 62.4127 12.5475Z"
          fill="#58595B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.3761 12.9341H32.1685H25.2281V19.9278H32.1685H39.3761H46.3393V12.9341H39.3761Z"
          fill="url(#paint0_linear_189_2512)"
        />
        <path
          opacity="0.8"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.3761 12.9341V4.79114H32.1685V12.9341V19.9278V27.3626H39.3761V19.9278V12.9341Z"
          fill="url(#paint1_linear_189_2512)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_189_2512"
          x1="25.2281"
          y1="16.0359"
          x2="44.3813"
          y2="22.5674"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F030C1" />
          <stop offset="1" stopColor="#6094EA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_189_2512"
          x1="35.4153"
          y1="4.79114"
          x2="41.8652"
          y2="25.5832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3FEE97" />
          <stop offset="1" stopColor="#31B3BA" />
        </linearGradient>
        <clipPath id="clip0_189_2512">
          <rect width="100" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

Logo.defaultProps = {
  width: "100",
  height: "38",
}

export default Logo
